export const apushexam3 = [
  {
    question: "What was the primary cause of the American Revolution?",
    options: [
      "Economic grievances like taxation without representation",
      "British denial of colonial self-governance",
      "The influence of Enlightenment ideas about natural rights",
      "All of the above",
    ],
  },
  {
    question: "Who delivered the famous 'I Have a Dream' speech during the Civil Rights Movement?",
    options: ["Martin Luther King Jr.", "Malcolm X", "Rosa Parks", "Nelson Mandela"],
  },
  {
    question: "What was the outcome of the Battle of Gettysburg during the Civil War?",
    options: ["Union victory", "Confederate victory", "Draw", "British victory"],
  },
  {
    question: "Which factor was most responsible for the severity of the Great Depression?",
    options: [
      "The collapse of the banking system",
      "Overproduction/underconsumption in the 1920s",
      "The stock market crash of 1929",
      "All of the above contributed significantly",
    ],
  },
  {
    question: "What was the primary goal of the first-wave feminist movement (late 19th/early 20th century)?",
    options: [
      "Equal pay for equal work",
      "Women's suffrage (voting rights)",
      "Access to higher education",
      "All of the above",
    ],
  },
  {
    question: "What was the main effect of the Louisiana Purchase in 1803?",
    options: [
      "The United States doubled in size",
      "French regained control of North America",
      "Increased tensions with Native American tribes",
      "All of the above",
    ],
  },
  {
    question: "What was the immediate purpose of the Emancipation Proclamation (1863)?",
    options: [
      "Free enslaved people in rebelling Confederate states",
      "Abolish slavery nationwide",
      "Encourage European support for the Confederacy",
      "Grant citizenship to African Americans",
    ],
  },
  {
    question: "What did the Civil Rights Act of 1964 specifically accomplish?",
    options: [
      "Banned segregation in public accommodations",
      "Prohibited employment discrimination",
      "Guaranteed voting rights",
      "A and B only",
    ],
  },
  {
    question: "What directly caused U.S. entry into World War II?",
    options: ["Pearl Harbor attack", "German invasion of Poland", "Sinking of Lusitania", "Zimmermann Telegram"],
  },
  {
    question: "What demographic change resulted from the post-WWII Baby Boom?",
    options: ["Dramatic population increase", "Decline in suburban growth", "Reduced need for schools", "All of the above"],
  },
  {
    question: "What caused the Watergate scandal (1972-1974)?",
    options: [
      "Nixon administration's illegal surveillance/cover-ups",
      "Foreign election interference",
      "Presidential health crisis",
      "Supreme Court corruption",
    ],
  },
  {
    question: "What did the Civil Rights Act of 1968 primarily address?",
    options: ["Housing discrimination (Fair Housing Act)", "School segregation", "Workplace equality", "Voter registration"],
  },
  {
    question: "What was a key goal of second-wave feminism (1960s-1980s)?",
    options: [
      "Equal pay/employment opportunities",
      "Reproductive rights",
      "Passage of the Equal Rights Amendment",
      "All of the above",
    ],
  },
  {
    question: "What resulted from the Cold War's end (1989-1991)?",
    options: [
      "Collapse of the Soviet Union",
      "U.S. emergence as sole superpower",
      "Reduction in nuclear arsenals",
      "All of the above",
    ],
  },
  {
    question: "What was a direct consequence of 9/11 (2001)?",
    options: [
      "War in Afghanistan",
      "Patriot Act surveillance programs",
      "Creation of Homeland Security",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of Hurricane Katrina (2005)?",
    options: [
      "Exposed federal disaster response failures",
      "Displaced 1 million+ Gulf Coast residents",
      "Caused $125 billion in damage",
      "All of the above",
    ],
  },
  {
    question: "What triggered the 2008 financial crisis?",
    options: ["Subprime mortgage collapse", "Automotive industry failure", "Dot-com bubble burst", "Eurozone crisis"],
  },
  {
    question: "Why was Barack Obama elected in 2008?",
    options: [
      "Opposition to Iraq War",
      "Response to economic crisis",
      "Groundbreaking racial symbolism",
      "All of the above",
    ],
  },
  {
    question: "What did the Affordable Care Act (2010) achieve?",
    options: [
      "Expanded health insurance coverage",
      "Mandated universal healthcare",
      "Eliminated private insurance",
      "None of the above",
    ],
  },
  {
    question: "What resulted from 2020's Black Lives Matter protests?",
    options: [
      "National reckoning on systemic racism",
      "Police reform legislation in some states",
      "Increased corporate diversity initiatives",
      "All of the above",
    ],
  },
  {
    question: "What was the Declaration of Independence's primary purpose?",
    options: [
      "Formally separate from Britain",
      "List grievances against Parliament",
      "Outline democratic governance principles",
      "All of the above",
    ],
  },
  {
    question: "What was the Civil War's fundamental cause?",
    options: ["Slavery's expansion into territories", "States' rights debates", "Economic differences", "All of the above"],
  },
  {
    question: "What was the Emancipation Proclamation's strategic purpose?",
    options: [
      "Undermine Confederate war effort",
      "Immediately free all enslaved people",
      "Persuade Britain to support the Union",
      "A and C only",
    ],
  },
  {
    question: "Why did the U.S. fight in the Spanish-American War (1898)?",
    options: ["Protect Cuban independence", "Expand overseas influence", "Avenge USS Maine sinking", "All of the above"],
  },
  {
    question: "How did WWII transform the U.S.?",
    options: [
      "Became global superpower",
      "Accelerated civil rights movement",
      "Created permanent military-industrial complex",
      "All of the above",
    ],
  },
  {
    question: "What was the New Deal's overarching purpose?",
    options: [
      "Relief, recovery, and reform",
      "Establish socialist economy",
      "Reduce federal government power",
      "Focus solely on job creation",
    ],
  },
  {
    question: "What sparked the Civil Rights Movement?",
    options: [
      "Brown v. Board decision (1954)",
      "Emmett Till's murder (1955)",
      "Montgomery Bus Boycott (1955)",
      "All of the above",
    ],
  },
  {
    question: "What was Watergate's most significant consequence?",
    options: [
      "Erosion of public trust in government",
      "Nixon's resignation",
      "Campaign finance reforms",
      "All of the above",
    ],
  },
  {
    question: "What ended the Cold War?",
    options: ["Soviet economic collapse", "Reagan's military buildup", "Gorbachev's reforms", "All of the above"],
  },
  {
    question: "How did 9/11 transform U.S. foreign policy?",
    options: [
      "Preemptive war doctrine (Bush Doctrine)",
      "Global War on Terror",
      "Increased homeland security spending",
      "All of the above",
    ],
  },
];
