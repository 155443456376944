import { Auth } from "aws-amplify";
import axios from "axios";
import { isUserLogined } from "../context/useAuth";
import { getUserJwtToken } from "../utils/helperFunctions";

const USER_SERVICE_URL = "https://apuser.service.crz97.com/";

const client = axios.create({ baseURL: USER_SERVICE_URL });

const API_PATH = {
  profile: "/profile",
};

async function getUserProfile({ callback }) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();

  const jwtToken = getUserJwtToken(user);
  client
    .get(`${API_PATH.profile}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: jwtToken,
      },
    })
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { userProfile = {} },
      } = res;
      callback({ userProfile });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

async function updateUserProfile({ displayName, userId, callback }) {
  if (!(await isUserLogined())) {
    alert("You need to login first!!");
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .put(
      `${API_PATH.profile}`,
      {
        displayName,
        userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { userProfile = {} },
        data,
      } = res;
      console.log(data);
      callback({ userProfile });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

export default {
  getUserProfile,
  updateUserProfile,
};
