export const apstats2 = [
  {
    question:
      "Which of the following is a type of graphical representation used in statistics to display the distribution of a set of continuous or numerical data?",
    options: ["Bar graph", "Pie chart", "Histogram", "Line graph"],
  },
  {
    question: "Which of the following measures of central tendency is most affected by outliers in the data set?",
    options: ["Mean", "Median", "Mode", "Range"],
  },
  {
    question: "Which of the following is a method for estimating population parameters based on a random sample?",
    options: ["Confidence Intervals", "Hypothesis Testing", "Regression Analysis", "ANOVA"],
  },
  {
    question: "Which of the following is an example of a discrete random variable?",
    options: ["Height", "Weight", "Number of children in a family", "Temperature"],
  },
  {
    question:
      "Which of the following tests is used to determine if there is a significant difference between the means of two independent samples?",
    options: ["Z-test", "T-test", "Chi-Square test", "ANOVA test"],
  },
  {
    question: "In a hypothesis test, the null hypothesis is typically stated as:",
    options: [
      "There is a difference between the population parameters being tested",
      "There is no difference between the population parameters being tested",
      "The population parameter being tested is equal to a specific value",
      "The population parameter being tested is greater than a specific value",
    ],
  },
  {
    question:
      "Which of the following is a type of probability distribution that describes the behavior of a countable number of independent, identically distributed random variables?",
    options: ["Binomial distribution", "Normal distribution", "Poisson distribution", "Exponential distribution"],
  },
  {
    question: "Which of the following is NOT a method for measuring the strength of the association between two variables?",
    options: [
      "Pearson's correlation coefficient",
      "Spearman's rank correlation coefficient",
      "Covariance",
      "Standard deviation",
    ],
  },
  {
    question: "A chi-square test is used to test:",
    options: [
      "The difference between two population means",
      "The difference between two population proportions",
      "The independence of two categorical variables",
      "The linearity of the relationship between two quantitative variables",
    ],
  },
  {
    question: "What is the central limit theorem?",
    options: [
      "The sampling distribution of the sample mean approximates a normal distribution as the sample size increases, regardless of the population distribution",
      "The probability distribution of a single random variable approaches a normal distribution",
      "The sum of two normal distributions is always a normal distribution",
      "All normal distributions have the same standard deviation",
    ],
  },
  {
    question:
      "Which of the following is a type of regression analysis used to model the relationship between a dependent variable and one or more independent variables?",
    options: ["Simple Linear Regression", "Multiple Linear Regression", "Logistic Regression", "Poisson Regression"],
  },
  {
    question: "A p-value is:",
    options: [
      "The population mean",
      "The sample mean",
      "The probability of observing a test statistic as extreme or more extreme than what was observed, assuming the null hypothesis is true",
      "The level of significance of a hypothesis test",
    ],
  },
  {
    question:
      "Which of the following is a type of probability distribution that describes the behavior of a continuous random variable?",
    options: ["Binomial distribution", "Normal distribution", "Poisson distribution", "Uniform distribution"],
  },
  {
    question: "What is Type I error in hypothesis testing?",
    options: [
      "Rejecting the null hypothesis when it is actually true",
      "Accepting the null hypothesis when it is actually false",
      "Rejecting the alternate hypothesis when it is actually true",
      "Accepting the alternate hypothesis when it is actually false",
    ],
  },
  {
    question: "What is the difference between a one-tailed test and a two-tailed test in hypothesis testing?",
    options: [
      "A one-tailed test evaluates an effect in one direction, while a two-tailed test evaluates effects in both directions",
      "A one-tailed test has a lower level of significance than a two-tailed test",
      "A one-tailed test only tests the population mean, while a two-tailed test tests the population proportion",
      "A one-tailed test is used for paired data, while a two-tailed test is used for independent data",
    ],
  },
  {
    question: "What is a confidence interval?",
    options: [
      "A range of values estimating a population parameter with a specified level of confidence",
      "A hypothesis test for differences between parameters",
      "A measure of association strength between variables",
      "A test for model goodness of fit",
    ],
  },
  {
    question: "Which of the following is a type of graph used to display the distribution of a set of continuous data?",
    options: ["Bar graph", "Line graph", "Histogram", "Scatterplot"],
  },
  {
    question: "What is the purpose of a control group in an experimental design?",
    options: [
      "To provide a baseline comparison for evaluating the treatment effect",
      "To receive a placebo instead of the actual treatment",
      "To randomly assign subjects to groups",
      "To eliminate confounding variables",
    ],
  },
  {
    question: "What is the difference between a population and a sample?",
    options: [
      "A population includes all individuals/objects of interest; a sample is a subset of the population",
      "A population is theoretical, while a sample is actual data",
      "A population is long-term data; a sample is short-term data",
      "A population is collected in controlled settings; a sample is naturalistic",
    ],
  },
  {
    question: "What is the difference between a Type I error and a Type II error in hypothesis testing?",
    options: [
      "Type I: Rejecting a true null hypothesis. Type II: Failing to reject a false null hypothesis.",
      "Type I: Accepting a false null hypothesis. Type II: Rejecting a true null hypothesis.",
      "Type I: Accepting a false alternate hypothesis. Type II: Rejecting a true alternate hypothesis.",
      "Type I: Rejecting a true alternate hypothesis. Type II: Accepting a false alternate hypothesis.",
    ],
  },
  {
    question: "What is correlation in statistics?",
    options: [
      "A measure of the strength and direction of association between two variables",
      "A causal relationship between variables",
      "A direct cause-and-effect relationship",
      "A method for predicting outcomes",
    ],
  },
  {
    question: "Which type of sampling technique ensures every population member has an equal chance of selection?",
    options: ["Cluster sampling", "Stratified sampling", "Systematic sampling", "Simple random sampling"],
  },
  {
    question: "What is a p-value in hypothesis testing?",
    options: [
      "The probability of observing the test statistic (or more extreme) if the null hypothesis is true",
      "The test statistic value",
      "The difference between observed and expected values",
      "The pre-determined significance level",
    ],
  },
  {
    question: "What is a residual in regression analysis?",
    options: [
      "The difference between the observed and predicted response values",
      "The predictor variable value",
      "The regression slope",
      "The y-intercept",
    ],
  },
  {
    question: "Which statistical experiment involves random assignment of subjects to treatments?",
    options: ["Observational study", "Quasi-experimental design", "Randomized controlled experiment", "Retrospective study"],
  },
  {
    question: "Which statistical method models the relationship between a response variable and multiple predictors?",
    options: ["Simple linear regression", "Multiple linear regression", "Logistic regression", "Poisson regression"],
  },
  {
    question: "What is the Central Limit Theorem?",
    options: [
      "The sample mean’s distribution approximates normality as sample size increases, regardless of the population distribution",
      "The sum of random variables follows a normal distribution",
      "The sample mean follows a Poisson distribution",
      "Population distributions are always normal",
    ],
  },
  {
    question: "Which of the following is not a type of statistical inference?",
    options: ["Point estimation", "Interval estimation", "Hypothesis testing", "Data visualization"],
  },
];
