export const apCalcAB1 = [
  {
    question: "What is the value of lim<sub>x→2</sub> (x² - 4)/(x - 2)?",
    options: ["0", "Undefined", "2", "4"],
  },
  {
    question: "If f(x) = 3x³ - 2x + 5, what is f'(x)?",
    options: ["9x² - 2", "6x² - 2", "3x² - 2", "9x² - 2x"],
  },
  {
    question: "Which of the following is the derivative of ln(2x)?",
    options: ["1/(2x)", "2/x", "1/x", "ln(2)/x"],
  },
  {
    question: "What is ∫(3x² + 4x - 1) dx?",
    options: ["x³ + 2x² - x + C", "6x + 4 + C", "x³ + 4x² - x + C", "3x³ + 4x² - x + C"],
  },
  {
    question:
      "A function f(x) is continuous on [1, 5] and differentiable on (1, 5). If f(1) = 3 and f(5) = 11, what does the Mean Value Theorem guarantee?",
    options: [
      "A critical point in (1, 5)",
      "A local maximum in (1, 5)",
      "A c in (1, 5) where f'(c) = 2",
      "A root in (1, 5)",
    ],
  },
  {
    question: "What is the derivative of sin(πx) with respect to x?",
    options: ["πcos(πx)", "cos(πx)", "-πcos(πx)", "πsin(πx)"],
  },
  {
    question: "If a particle’s position is given by s(t) = t³ - 4t² + 6, what is its acceleration at t = 2?",
    options: ["-8", "4", "2", "12"],
  },
  {
    question: "Which of the following represents the area between y = x² and y = x from x = 0 to x = 1?",
    options: ["∫₀¹ (x - x²) dx", "∫₀¹ (x² - x) dx", "∫₀¹ x² dx", "∫₀¹ (x² + x) dx"],
  },
  {
    question: "What is dy/dx for y = e<sup>2x</sup>?",
    options: ["2e<sup>2x</sup>", "e<sup>2x</sup>", "2e<sup>x</sup>", "e<sup>x</sup>/2"],
  },
  {
    question: "If f'(x) = 4x³ - 6x and f(1) = 3, what is f(x)?",
    options: ["x⁴ - 3x² + 5", "x⁴ - 3x² + 2", "12x² - 6 + C", "x⁴ - 6x² + 5"],
  },
  {
    question: "What is the solution to dy/dx = 2y with y(0) = 5?",
    options: ["y = 5e<sup>2x</sup>", "y = 2e<sup>5x</sup>", "y = 5e<sup>x</sup>", "y = 2x² + 5"],
  },
  {
    question: "What is the average value of f(x) = 3x² on [0, 2]?",
    options: ["4", "6", "8", "12"],
  },
  {
    question: "Which method would integrate ∫x√(x² + 1) dx?",
    options: ["Integration by parts", "Substitution u = x² + 1", "Partial fractions", "Trig substitution"],
  },
  {
    question: "If f(x) = x³ - 6x² + 9x, at which x does f(x) have a local minimum?",
    options: ["1", "2", "3", "0"],
  },
  {
    question: "What is lim<sub>x→∞</sub> (3x² + 2x)/(5x² - x)?",
    options: ["0", "3/5", "∞", "Undefined"],
  },
  {
    question:
      "A ladder 10 ft long slides down a wall. When the base is 6 ft from the wall, how fast is the top sliding down if the base moves at 2 ft/s?",
    options: ["-1.5 ft/s", "-3 ft/s", "-2.5 ft/s", "-4 ft/s"],
  },
  {
    question: "What is the derivative of f(x) = x²ln(x)?",
    options: ["2xln(x) + x", "xln(x) + x", "2xln(x)", "2xln(x) + x²"],
  },
  {
    question: "Which statement is true for f(x) = x⁴?",
    options: ["Concave up everywhere", "Has an inflection point at x = 0", "Decreasing for x < 0", "Undefined at x = 0"],
  },
  {
    question: "What is ∫<sub>0</sub><sup>π/2</sup> cos(x) dx?",
    options: ["0", "1", "-1", "π/2"],
  },
  {
    question: "If f''(x) = 6x - 4 and f'(1) = 2, f(1) = 3, what is f(x)?",
    options: ["x³ - 2x² + 3x - 1", "x³ - 2x² + 2x + 1", "3x² - 4x + 3", "x³ - 4x² + 5"],
  },
];
