import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { getTimeSinceDate } from "../utils/helperFunctions";
export default function DiscussionTable(props) {
  const { discussions = [] } = props;
  const navigate = useNavigate();
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>title</th>
          <th>Author</th>
          <th>createdDate</th>
        </tr>
      </thead>
      <tbody>
        {discussions.map((topic) => {
          const { title, createdDate, createdBy, topicId } = topic;
          const fromNow = getTimeSinceDate(new Date(createdDate));
          return (
            <tr
              key={createdBy + createdDate}
              onClick={() => {
                navigate(`${topicId}`);
              }}
            >
              <td>{title}</td>
              <td>{createdBy}</td>
              <td>{fromNow}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
