export const apcsexam1 = [
  {
    question:
      "1. Which of the following code segments is equivalent to the code below?",
    choices: {
      A: "x = 0;",
      B: "if (x > 1) x = 0;",
      C: "if (x > 3) x = 0;",
      D: "if (x >=1) x = 0;",
      E: "none of the above",
    },
    answer: "E",
    additionalDetails: {
      type: "java",
      value: `if (x >= 1) x = x * 3;
          if (x > 3) x = 0; `,
    },
  },
  {
    question: `2. Consider the following class definitions.
      What is printed when the following code is executed?
   Student s = new GradStudent();
   System.out.println(s.getInfo());`,
    choices: {
      A: "Pizza",
      B: "Taco",
      C: "Studying",
      D: "Eating",
      E: `Studying
        Eating`,
    },
    answer: "D",
    additionalDetails: {
      type: "java",
      value: `public class Student {
          public String getFood() {
              return "Pizza";
          }
          public String getInfo() {
              return "Studying";
          }
      }
      
      public class GradStudent extends Student {
          public String getFood() {
              return "Taco";
          }
          public String getInfo() {
              super.getInfo();
              return "Eating";
          }
      }`,
    },
  },
  {
    question:
      "3. Given the following code which of the answers best describes the conditions needed for temp to be true when it is returned?",
    choices: {
      A: "Whenever the first element in a is equal to val",
      B: "Whenever a contains any element which equals val. ",
      C: "Whenever more than 1 element in a is equal to val.",
      D: "Whenever exactly 1 element in a is equal to val. ",
      E: "Whenever the last element in a is equal to val.",
    },
    answer: "B",
    additionalDetails: {
      type: "java",
      value: `boolean temp = false;
        int count = 0;
        for (int testVal : a)
        {
         if (testVal == val)
         {
         temp = true;
         return temp;
         }
        }
        return temp;`,
    },
  },
  {
    question: `4. Consider the following code segment. What is printed as a result of executing the code segment?`,
    choices: {
      A: "[a, c, e, d, g]",
      B: "[c, e, d, b, g]",
      C: "[a, c, e, g]",
      D: "[a, b, e, d, g]",
      E: "[a, c, e, d, b, g]",
    },
    answer: "C",
    additionalDetails: {
      type: "java",
      value: `List<String> list = new ArrayList<String>();
        list.add("a");
        list.add("b");
        list.set(1,"c");
        list.add(2, "d");
        list.set(2, "e");
        list.add("g");
        System.out.println(list);`,
    },
  },
  {
    question: `5. Given the following class declarations: Which of the following will cause a compile time error?`,
    choices: {
      A: "Car myCar = new Car();",
      B: "Car myCar1 = new ElectricCar();",
      C: `ElectricCar myCar2 = new ElectricCar("Ford");`,
      D: `Car myCar3 = new Car("Ford");`,
      E: `Car myCar4 = new ElectricCar("Toyota");`,
    },
    answer: "A",
    additionalDetails: {
      type: "java",
      value: `public class Car {
          private String make;
          public Car(String theMake) {
              make = theMake;
          }
          public String getMake() {
              return make;
          }
      }
      public class ElectricCar extends Car {
          public ElectricCar() {
              super("Ford");
          }
          public ElectricCar(String theMake) {
              super(theMake);
          }
      }`,
    },
  },
  {
    question: `6. Given the following declarations. Which of the following is a correct call to test?`,
    choices: {
      A: `v.test(sporty,v);`,
      B: `sporty.test(c,c);`,
      C: `v.test(sporty,c);`,
      D: `sporty.test(sporty,v);`,
      E: `c.test(sporty,sporty);`,
    },
    answer: `E`,
    additionalDetails: {
      type: "java",
      value: `public class Vechicle {
          public void test(Car x, SportsCar y) {}
         }
          public class Car extends Vechicle {
         }
         public class SportsCar extends Car {
         }
         Also consider the following code that appears in a different class.
         Vechicle v = new Vechicle();
         Car c = new Car();
         SportsCar sporty = new SportsCar();`,
    },
  },
  {
    question: `7. When is the following Boolean expression true (a and b are integers)? (a < b) && !(b > a)`,
    choices: {
      A: `Always true`,
      B: `Never true`,
      C: `a = b`,
      D: `a < b`,
      E: `a > b`,
    },
    answer: `B`,
  },
  {
    question: `8. The following incomplete method is intended to sort the array a in ascending order.
      Which of the following could be used to replace /* missing code */ in the
  code above so that the method always sorts the array a in ascending order?`,
    choices: {
      A: `int j = i - 1; j >= 0; j--`,
      B: `int j = i + 1; j < a.length; j++`,
      C: `int j = i; j < a.length; j++`,
      D: `int j = i; j >= 0; j--`,
      E: `int j = i - 1; j > 0; j--`,
    },
    answer: `A`,
    additionalDetails: {
      type: "java",
      value: `public void sort() {
          int maxCompare = a.length - 1;
          int lIndex = 0;
          int temp = 0;
          for (int i = maxCompare; i > 0; i--) {
              lIndex = i;
              for ( /* missing code */) {
                  if (a[j] > a[lIndex]) {
                      lIndex = j;
                  }
              }
              temp = a[i];
              a[i] = a[lIndex];
              a[lIndex] = temp;
          }
      }`,
    },
  },
  {
    question: `9. Which of the following code will produce the following output?
      1
      22
      333
      4444`,
    choices: {
      A: `for (int i = 1; i < 5; i++) {
          for (int j = i; j > 0; j--) {
          System.out.print(i+1);
          }
          System.out.println();
          }`,
      B: `for (int i = 0; i < 5; i++) {
          for (int j = 0; j < i; j++) {
          System.out.print(i);
          }
          System.out.println();
          }`,
      C: `for (int i = 1; i <= 5; i++) {
          for (int j = i; j > 0; j--) {
          System.out.print(i);
          }
          System.out.println();
          }`,
      D: `for (int i = 1; i < 6; i++) {
          for (int j = 0; j < i; j++) {
          System.out.println(i);
          }
          }`,
      E: `for (int i = 0; i < 5; i++) {
          for (int j = 0; j < i; j++) {
          System.out.print(i+1);
          }
          System.out.println();
          }`,
    },
    answer: `B`,
  },
  {
    question: `10. Consider the following code segment. What is the minimum number of times that * will be printed?`,
    choices: {
      A: `0`,
      B: `1`,
      C: `2`,
      D: `n - 1`,
      E: `n - 2`,
    },
    additionalDetails: {
      type: "java",
      value: `int i = a random number such that 1 <= i <= n;
        for (int a = 2; a <= i; a++)
        for (int b = 1; b < i; b++)
        System.out.println("*");`,
    },
    answer: `A`,
  },
  {
    question: `11. Given the following class declarations.
      Assume that the following declaration is in a different class.
  Animal d = new Dog();
  Which of the following will compile without error?
  I. d.eat();
  II. d.growl();
  III. ((Dog) d).growl();`,
    choices: {
      A: `I only`,
      B: `II only`,
      C: `III only`,
      D: `I and III only`,
      E: `I, II, and III`,
    },
    additionalDetails: {
      type: "java",
      value: `public class Animal {
          // constructors not shown
          public void eat()
          { // code not shown
          }
         }
         public class Dog extends Animal {
          // constructors not shown
          public void growl()
          { // code not shown
          }
         }`,
    },
    answer: `D`,
  },
  {
    question: `12. Given the following method and what would the result be when m is executed?`,
    choices: {
      A: `Copies the values from the top half to the bottom half of the 2D array`,
      B: `Copies the values from the left halt to the right half of the 2D array`,
      C: `Copies the values from the bottom half to the top half of the 2D array`,
      D: `Copies the values from the right half to the left half of the 2D array`,
      E: `All values remain the same.`,
    },
    additionalDetails: {
      type: "java",
      value: `public void m(int[][]p) {
          int height = p.length;
          for (int row = 0; row < height / 2; row++) {
              for (int col = 0; col <p[0].length; col++) {
                  p[row][col] = p[height - row - 1][col];
              }
          }
      }`,
    },
    answer: `C`,
  },
  {
    question: `13. Consider the following code segment:
      What is the value of sum after the code is executed?`,
    choices: {
      A: `1`,
      B: `0`,
      C: `13`,
      D: `7`,
      E: `4`,
    },
    additionalDetails: {
      type: "java",
      value: `int p = 5;
        int q = 2;
        int sum = 0;
        while (p <= 8)
        {
        sum += p % q;
        p++;
        q++;
        }`,
    },
    answer: `D`,
  },
  {
    question: `14. What is the output from mystery(4321) when mystery is defined as follows:`,
    choices: {
      A: `12344321`,
      B: `1234`,
      C: `4321`,
      D: `43211234`,
      E: `32144123`,
    },
    additionalDetails: {
      type: "java",
      value: `//precondition: x >=0
        public static void mystery (int x) {
         System.out.print(x % 10);
         if ((x / 10) != 0) {
         mystery(x / 10);
         }
        } `,
    },
    answer: `B`,
  },
  {
    question: `15. Which of the following reasons for using an inheritance hierarchy are valid?
      I. Methods from a superclass can be used in a subclass without rewriting or
      copying code.
      II. Objects from subclasses can be passed as arguments to a method designed for
      the superclass
       III. Objects from subclasses can be stored in the same array
       IV. All of the above
       V. None of the above`,
    choices: {
      A: `I and II`,
      B: `I and III`,
      C: `IV`,
      D: `V`,
      E: `I only`,
    },
    answer: `C`,
  },
  {
    question: `16. Which of the following correctly shows the iterations of an ascending (from left to
          right) insertion sort on an array with the following elements: {7,3,8,5,2}?`,
    choices: {
      A: `{3,7,8,5,2}, {3,7,8,5,2}, {3,5,7,8,2}, {2,3,5,7,8}`,
      B: `{2,3,8,5,7}, {2,3,8,5,7}, {2,3,5,8,7}, {2,3,5,7,8}`,
      C: `{3,7,8,5,2}, {3,5,7,8,2}, {2,3,5,7,8}`,
      D: `{2,3,8,5,7}, {2,3,5,8,7}, {2,3,5,7,8}`,
      E: `{2,7,3,8,5}, {2,3,7,8,5}, {2,3,5,7,8}`,
    },
    answer: `A`,
  },
  {
    question: `17. Which of the following would be the correct result from the following expression?
      12310 - 128 + 1012 + D16`,
    choices: {
      A: `130`,
      B: `133`,
      C: `131`,
      D: `132`,
      E: `136`,
    },
    answer: `C`,
  },
  {
    question: `18. Consider the following code segment:
      Pick the answer below that best describes all the cases when this method will return
  true.`,
    choices: {
      A: `s contains two or more of the same characters`,
      B: `s contains two or more of the same characters in a row`,
      C: `s starts with two or more of the same characters`,
      D: `s ends with two or more of the same characters`,
      E: `s contains only two characters`,
    },
    additionalDetails: {
      type: "java",
      value: `public static boolean check(String s)
        {
         return s.length() >= 2 &&
         (s.substring(0,1).equals(s.substring(1,2)) ||
         check(s.substring(1)));
        }`,
    },
    answer: `B`,
  },
  {
    question: `19. Consider the following code segment. What is printed as a result of executing the code segment?`,
    choices: {
      A: `1 3 5 7 9 11 13 15 17 19`,
      B: `0 2 4 6 8 10 12 14 16 18`,
      C: `2 4 6 8 10 12 14 16 18 20`,
      D: `3 6 9 12 15 18`,
      E: `0 2 4 6 8 10 13 14 16 18 20`,
    },
    additionalDetails: {
      type: "java",
      value: `for (int k = 0; k < 20; k = k + 1)
        {
         if (k % 2 == 1)
         System.out.print((k + 1) + " ");
        }`,
    },
    answer: `C`,
  },
  {
    question: `20. Consider the following partial class definitions.
      Which of the following constructors are valid for C2?
  I. public C2 () { }
  II. public C2 (int quan) {super (quan); }
  III. public C2 (String label) { super(label); }`,
    choices: {
      A: `All three are valid`,
      B: `II only`,
      C: `III only`,
      D: `II and III`,
      E: `None are valid`,
    },
    additionalDetails: {
      type: "java",
      value: `public class C1 {
          private int num;
          private String name;
          public C1(int theNum) {
          num = theNum;
          }
         
          public C1(String theName) {
          name = theName;
          }
          // other methods not shown
         }
         public class C2 extends C1 {
          // methods not shown
         }`,
    },
    answer: `D`,
  },
  {
    question: `21. Which of the following statements about interfaces is (are) true?
      I. One interface can inherit from another
      II. All methods declared in an interface are abstract methods (can’t have a
      method body).
      III. All methods declared in an interface are public methods.`,
    choices: {
      A: `II only`,
      B: `III only`,
      C: `I and II only`,
      D: `I, II, and III`,
      E: `I only`,
    },
    answer: `D`,
  },
  {
    question: `22. Consider the following code segment. 
      What are the values of s and b after the following has executed?
   int[] s = {3,4};
   int b = 4;
   test(s,b);`,
    choices: {
      A: `s={3, 8}; b=4;`,
      B: `s={3, 4}; b=4;`,
      C: `s={6, 4}; b=4;`,
      D: `s={3, 8}; b=8;`,
      E: `s={6, 8}; b=8;`,
    },
    additionalDetails: {
      type: "java",
      value: `public static void test(int[] a, int y)
        {
        if (a.length > 1)
        a[1] = a[1] * 2;
        y = y * 2;
        }`,
    },
    answer: `A`,
  },
  {
    question: `23. Consider the following code segment. What is printed as a result of executing this code segment?`,
    choices: {
      A: `012345`,
      B: `0112233445`,
      C: `001122334455`,
      D: `012123234345`,
      E: `You will get an IndexOutOfBoundsException`,
    },
    additionalDetails: {
      type: "java",
      value: `String str = "012345";
        for (int i = 0; i < str.length() - 1; i++) {
        System.out.print(str.substring(i, i+2));
        }`,
    },
    answer: `B`,
  },
  {
    question: `24. A two-dimensional array is used to represent a matrix. The declaration is below:
      If matrix is initialized to be: {{-1, -2, 3},{4, -5, 6}}. What will the values in matrix be
  after changeMatrix(matrix) is called?`,
    choices: {
      A: `{{4, -5, 6},{-1, -2, 3}}`,
      B: `{{4, 5, 6},{1, 2, 3}}`,
      C: `{{1, 2, 3},{4, 5, 6}}`,
      D: `{{-1, -2, 3},{4, -5, 6}}`,
      E: `{{1, -2, 3},{4, 5, 6}}`,
    },
    additionalDetails: {
      type: "java",
      value: `private int[][] matrix = new int[2][3];
        public static void changeMatrix(int[][] matrix) {
        for (int y = 0; y < matrix.length; y++)
         for (int x = 0; x < matrix[y].length; x++)
         if(y==x)
         matrix[y][x] = Math.abs(matrix[y][x]);
        }`,
    },
    answer: `E`,
  },
  {
    question: `25. Given the following partial class definitions: Which declaration will result in a compiler error?`,
    additionalDetails: {
      type: "java",
      value: `public class Book implements Comparable
        { // code for class
        }
        public class Dictionary extends Book
        { // code for class
        }`,
    },
    choices: {
      A: `Book b = new Book();`,
      B: `Dictionary d = new Book();`,
      C: `Comparable c = new Book();`,
      D: `Book b2 = new Dictionary ();`,
      E: `Comparable c2 = new Dictionary();`,
    },
    answer: `B`,
  },
  {
    question: `26. Consider the following code segment. How many times will a ‘*’ be printed?`,
    additionalDetails: {
      type: "java",
      value: `for(int i = 0; i <= 3; i++)
        {
         for(int j = 1; j <= 5; j+=2)
         {
         System.out.println("*");
         }
        }`,
    },
    choices: {
      A: `3`,
      B: `6`,
      C: `9`,
      D: `12`,
      E: `15`,
    },
    answer: `D`,
  },
  {
    question: `27. What is printed when the following main method is executed?`,
    additionalDetails: {
      type: "java",
      value: `public class Searcher
        {
         private int[] arr = {1,3,5,8,9};
        
         public int mystery(int low, int high, int num) {
         int mid = (low + high) / 2;
         if (low > high) {
         return -1; }
         else if (arr[mid] < num) {
         return mystery(mid + 1, high, num); }
         else if (arr[mid] > num) {
         return mystery(low, mid - 1, num); }
         else return mid;
         }
        
         public static void main(String[] args)
         {
         Searcher s = new Searcher();
         System.out.println(s.mystery(0,4,8));
         }
        }`,
    },
    choices: {
      A: `-1`,
      B: `0`,
      C: `1`,
      D: `2`,
      E: `3`,
    },
    answer: `E`,
  },
  {
    question: `28. What are the values of a and b after the for loop finishes?`,
    additionalDetails: {
      type: "java",
      value: `int a = 10;
        int b = 3;
        int t = 0;
        for (int i = 1; i < 4; i++)
        {
        t = a;
        a = i + b;
        b = t - i;
        }`,
    },
    choices: {
      A: `a = 5 and b = -2`,
      B: `a = 6 and b = 7`,
      C: `a = 6 and b = 3`,
      D: `a = 12 and b = 1`,
      E: `a = 5 and b = 8`,
    },
    answer: `E`,
  },
  {
    question: `29. Consider the following method. What value is returned from a call of mystery(5)?`,
    additionalDetails: {
      type: "java",
      value: `public static int mystery(int n)
        {
         if (n == 0)
         return 1;
         else
         return 3 * mystery (n - 1);
        }`,
    },
    choices: {
      A: `243`,
      B: `0`,
      C: `3`,
      D: `81`,
      E: `27`,
    },
    answer: `A`,
  },
  {
    question: `30. Given the following class declarations. Assume that Parent p = new Child(); appears in a client program. What is the result of the call p.m1()?`,
    additionalDetails: {
      type: "java",
      value: `public class Parent {
          public void m1() {
              System.out.print("pm1");
              m2();
          }
          public void m2() {
              System.out.print("pm2");
          }
      }
      
      public class Child extends Parent {
          public void m1() {
              super.m1();
              System.out.print("cm1");
          }
          public void m2() {
              super.m2();
              System.out.print("cm2");
          }
      }`,
    },
    choices: {
      A: `pm1pm2cm1cm2`,
      B: `pm1pm2`,
      C: `pm1pm2cm2cm1`,
      D: `pm1cm1`,
      E: `pm1`,
    },
    answer: `C`,
  },
  {
    question: `31. Which of the following correctly shows the iterations of an ascending (from left to right) selection sort on an array with the following elements: {6,3,8,5,1}?`,
    choices: {
      A: `{3,6,8,5,1}, {3,5,6,8,1}, {1,3,5,6,8}`,
      B: `{1,3,8,5,6}, {1,3,8,5,6}, {1,3,5,8,6}, {1,3,5,6,8}`,
      C: `{3,6,8,5,1}, {3,6,8,5,1}, {3,5,6,8,1}, {1,3,5,6,8}`,
      D: `{1,3,8,5,6}, {1,3,5,8,6}, {1,3,5,6,8}`,
      E: `{1,6,3,8,5}, {1,3,6,8,5}, {1,3,5,6,8}`,
    },
    answer: `B`,
  },
  {
    question: `32. Given the following method. What would test return if a = {0,2,3,4} and v = 2?`,
    additionalDetails: {
      type: "java",
      value: `public static int test(int[] a, int v)
        {
         for (int i = 0; i < a.length; i++)
         {
         if (a[i] == v)
         return i;
         else return -1;
         }
        }`,
    },
    choices: {
      A: `0`,
      B: `1`,
      C: `2`,
      D: `-1`,
      E: `The code will not compile`,
    },
    answer: `E`,
  },
  {
    question: `33. Given the following code: 
      Which of the following would return true?
          I. s1.equals(s3)
          II. s1 == s4
          III.s1.equals(s4)`,
    additionalDetails: {
      type: "java",
      value: `String s1 = new String("bye");
        String s2 = new String("bye now");
        String s3 = s2.substring(0,3);
        String s4 = new String("bye");`,
    },
    choices: {
      A: `I and III only `,
      B: `II and III only`,
      C: `I only`,
      D: `II only`,
      E: `III only`,
    },
    answer: `A`,
  },
  {
    question: `34. What is the output from the following code?`,
    additionalDetails: {
      type: "java",
      value: `String s = "Computer Science is fun!";
        String s1 = s.substring(0,8);
        String s2 = s1.substring(1);
        String s3 = s2.substring(1,3);
        System.out.println(s3);`,
    },
    choices: {
      A: `mput`,
      B: `mpu`,
      C: `mp`,
      D: `omp`,
      E: `om`,
    },
    answer: `C`,
  },
  {
    question: `35. Given the following code: What will it return when called with mystery("aacabab")?`,
    additionalDetails: {
      type: "java",
      value: `public static int mystery(String str)
        {
        if (str.length() == 0) return 1;
         else
         {
         if (str.substring(0,1).equals("a")) return 1 +
         mystery(str.substring(1));
         else return mystery(str.substring(1));
         }
        }`,
    },
    choices: {
      A: `1`,
      B: `2`,
      C: `3`,
      D: `4`,
      E: `5`,
    },
    answer: `E`,
  },
  {
    question: `36. If you have a parent class Animal that has a method speak() which returns "Awk"
      and you have children classes that do the following:
      Cat has a speak method that returns "Meow"
      Bird has a speak method that returns "Tweet"
      Dog has a speak method that returns "Woof"
      Pig doesn’t have a speak method
      Cow has a speak method that returns "Moo"
      What is the output from looping through this array of animals and asking each to speak()?`,
    additionalDetails: {
      type: "java",
      value: `Animal[] a = { new Cat(), new Cow(), new Dog(), new Pig(), new Bird() }`,
    },
    choices: {
      A: `Awk Awk Awk Awk Awk`,
      B: `This will have runtime errors`,
      C: `Meow Moo Woof Oink Awk`,
      D: `Meow Moo Woof Awk Awk`,
      E: `Meow Moo Woof Awk Tweet`,
    },
    answer: `E`,
  },
  {
    question: `37. Which of the following is (are) true?
      I. Insertion sort takes longer when the array is sorted in ascending order and you
      want it sorted in descending order.
      II. Mergesort uses recursion.
      III. Selection sort takes less time to execute if the array is already sorted in the
      correct order.`,
    choices: {
      A: `I only`,
      B: `II only`,
      C: `III only`,
      D: `I and II only`,
      E: `I, II, and III`,
    },
    answer: `D`,
  },
  {
    question: `38. Consider the following method.
      Which of the following code segments would return the same values as outOfRange?
      I. if (value < 0)
      {
      if (value > 100)
      return true;
      else
      return false;
      }
      else
      return false;
      II. if (value < 0)
      return true;
      else if (value > 100)
      return true;
      else
      return false;
      III. if (value >= 0)
      return false;
      else if (value <= 100)
      return false;
      else
      return true;`,
    additionalDetails: {
      type: "java",
      value: `public static boolean outOfRange(int value){
          if (value < 0 || value > 100)
          return true;
          else
          return false;
         }`,
    },
    choices: {
      A: `I only`,
      B: `II only`,
      C: `III only`,
      D: `I and III`,
      E: `II and III`,
    },
    answer: `B`,
  },
  {
    question: `39. Given the following values for a 2D array m and the following code
      1 1 1 1
      1 2 3 4
      2 2 2 2
      2 4 6 8
      What is the value of sum after this code executes? `,
    additionalDetails: {
      type: "java",
      value: `int sum = 0;
          for (int k = 0; k < m.length; k++) {
          sum = sum + m[m.length-1-k][1];
          }`,
    },
    choices: {
      A: `6`,
      B: `9`,
      C: `10`,
      D: `4`,
      E: `20`,
    },
    answer: `B`,
  },
  {
    question: `40. Consider the following method. Which of the following methods will print the same result as the method above no matter what values are passed for num1 and num2?
      I.
      public static void method1(int num1, int num2) {
      int result=99;
      if (num1 == num2) {result = 0;}
      else {
       if(num1 > num2) {result = 1;}
       else {result = -1;}
      }
      System.out.println(result);
      }
      II.
      public static void method2(int num1, int num2) {
      int result = 99;
      if (num1 == num2) {result = 0;}
      if (num1 >= num2) {result = 1;}
      else {result = -1;}
      System.out.println(result);
      }
      III.
      public static void method3(int num1, int num2) {
      int result = 99 ;
      if (num1 == num2) {result = 0;}
      if (num1 > num2) {result = 1;}
      if (num1 < num2) {result = -1;}
      System.out.println(result);
      }`,
    additionalDetails: {
      type: "java",
      value: `public static void sample(int num1, int num2) {
          int result = 99;
          if (num1 == num2) {result = 0;}
          else if (num1 > num2){result = 1;}
          else {result = -1;}
          System.out.println(result);
          }`,
    },
    choices: {
      A: `I and III`,
      B: `I only`,
      C: `II only`,
      D: `II and III`,
      E: `I, II, and III`,
    },
    answer: `A`,
  },
];
