export const apushexam1 = [
  {
    question: "What was the main reason for the American Revolution?",
    options: [
      "Economic grievances against British trade policies",
      "Anger over increased taxes",
      "Frustration with British political corruption",
      "A combination of all of the above",
    ],
  },
  {
    question: "Who delivered the famous 'I Have a Dream' speech during the Civil Rights Movement?",
    options: ["Martin Luther King Jr.", "Malcolm X", "Rosa Parks", "Nelson Mandela"],
  },
  {
    question: "What was the outcome of the Battle of Gettysburg during the Civil War?",
    options: ["Union victory", "Confederate victory", "Draw", "British victory"],
  },
  {
    question: "What was the main cause of the Great Depression?",
    options: ["Stock market crash of 1929", "Decreased consumer spending", "Banking failures", "All of the above"],
  },
  {
    question: "What was the main goal of the women's suffrage movement of the late 19th and early 20th centuries?",
    options: ["Voting rights for women", "Equal pay for equal work", "Equal educational opportunities", "All of the above"],
  },
  {
    question: "What was the main effect of the Louisiana Purchase in 1803?",
    options: [
      "The United States acquired a large amount of land in the western frontier",
      "The French regained control of Louisiana",
      "The British acquired control of Louisiana",
      "The Spanish acquired control of Louisiana",
    ],
  },
  {
    question: "What was the main purpose of the Emancipation Proclamation issued by President Lincoln in 1863?",
    options: [
      "To abolish slavery in the Confederate states",
      "To abolish slavery in the Union states",
      "To abolish slavery in the British colonies",
      "To abolish slavery in all of the territories acquired through the Louisiana Purchase",
    ],
  },
  {
    question: "What was the main outcome of the Civil Rights Act of 1964?",
    options: [
      "Ended segregation in public places and schools",
      "Banned discrimination in employment",
      "Established voting rights protections",
      "Ended segregation in public places and banned discrimination in employment",
    ],
  },
  {
    question: "What was the main reason for American involvement in World War II?",
    options: [
      "The bombing of Pearl Harbor by the Japanese",
      "The invasion of Poland by the Germans",
      "The spread of communism",
      "The oil embargo by the Germans",
    ],
  },
  {
    question: "What was the main impact of the Baby Boom following World War II?",
    options: [
      "A surge in population growth",
      "A decrease in birth rates",
      "A decline in the economy",
      "An increase in unemployment rates",
    ],
  },
  {
    question: "What was the main cause of the Watergate scandal in the 1970s?",
    options: [
      "Illegal activities by members of President Nixon's administration",
      "A foreign power hacking into the White House's computer systems",
      "Economic mismanagement by President Nixon's administration",
      "Environmental disasters caused by President Nixon's policies",
    ],
  },
  {
    question: "What was the main effect of the Civil Rights Act of 1968?",
    options: [
      "It banned discrimination in housing",
      "It banned discrimination in voting",
      "It banned discrimination in employment",
      "All of the above",
    ],
  },
  {
    question: "What was the main goal of the women's rights movement in the late 20th century?",
    options: ["Equal pay for equal work", "Voting rights for women", "Equal educational opportunities", "All of the above"],
  },
  {
    question: "What was the most immediate and significant impact of the end of the Cold War?",
    options: [
      "The collapse of the Soviet Union",
      "The rise of China as a global power",
      "The expansion of NATO",
      "All of the above",
    ],
  },
  {
    question: "What was the most immediate response of the United States to the September 11th terrorist attacks in 2001?",
    options: [
      "The U.S. invasion of Afghanistan",
      "The implementation of the USA PATRIOT Act",
      "The invasion of Iraq",
      "None of the above",
    ],
  },
  {
    question: "What was the main impact of Hurricane Katrina in 2005?",
    options: [
      "Massive damage to infrastructure in New Orleans",
      "A significant loss of life in the Gulf Coast region",
      "A major housing crisis for residents affected by the storm",
      "All of the above",
    ],
  },
  {
    question: "What was the main reason for the 2008 financial crisis?",
    options: [
      "Subprime mortgage lending practices",
      "A decrease in consumer spending",
      "An increase in interest rates",
      "A decrease in the stock market",
    ],
  },
  {
    question: "What was a major factor contributing to the election of Barack Obama as President in 2008?",
    options: [
      "His charismatic leadership and message of change",
      "His economic policies aimed at recovery",
      "His opposition to the Iraq War",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the Affordable Care Act (Obamacare) passed in 2010?",
    options: [
      "An expansion of healthcare coverage to millions of Americans",
      "A decrease in healthcare costs",
      "An increase in the number of doctor's visits",
      "All of the above",
    ],
  },
  {
    question: "What was the main effect of the Black Lives Matter protests in 2020?",
    options: [
      "A nationwide conversation about racial injustice and police brutality",
      "Significant changes in policing practices in cities across the country",
      "An increase in support for the Black Lives Matter movement",
      "All of the above",
    ],
  },
  {
    question: "What was the primary objective of the American colonists during the American Revolution?",
    options: [
      "To gain independence from Great Britain",
      "To secure more rights as British citizens",
      "To establish a new form of government",
      "All of the above",
    ],
  },
  {
    question: "What was the main cause of the Civil War?",
    options: [
      "The issue of slavery",
      "Differences between the northern and southern economies",
      "States' rights",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the Emancipation Proclamation in 1863?",
    options: [
      "It freed slaves in Confederate-held territory",
      "It abolished slavery throughout the United States",
      "It improved the lives of African Americans immediately",
      "All of the above",
    ],
  },
  {
    question: "What was a primary cause of the Spanish–American War in 1898?",
    options: [
      "The sinking of the USS Maine in Havana Harbor",
      "The desire to support Cuban independence",
      "Imperial ambitions of the United States",
      "A combination of these factors",
    ],
  },
  {
    question: "What was the main impact of World War II on the United States?",
    options: [
      "An economic boom due to increased government spending",
      "A significant increase in the number of women in the workforce",
      "The rise of the United States as a global superpower",
      "All of the above",
    ],
  },
  {
    question: "What was the main goal of the New Deal?",
    options: [
      "To create jobs and stimulate the economy during the Great Depression",
      "To increase the size and power of the federal government",
      "To promote social welfare and protect the rights of workers",
      "All of the above",
    ],
  },
  {
    question: "What was the main cause of the Civil Rights Movement in the 1950s and 1960s?",
    options: [
      "The desire of African Americans to end segregation and achieve equal rights",
      "The efforts of civil rights leaders such as Martin Luther King Jr.",
      "The growing social and political tensions in the United States",
      "All of the above",
    ],
  },
  {
    question: "What was the main outcome of the Watergate Scandal in the 1970s?",
    options: [
      "The resignation of President Richard Nixon",
      "The conviction of several top White House officials",
      "The increased public distrust of the government",
      "All of the above",
    ],
  },
  {
    question: "What was the main factor that led to the end of the Cold War?",
    options: [
      "The economic collapse of the Soviet Union",
      "The efforts of President Ronald Reagan to promote democracy and capitalism",
      "The rise of democratic movements in Eastern Europe",
      "All of the above",
    ],
  },
];
