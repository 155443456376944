import { generateRandomString } from "@aws-amplify/core";
import { useContext, useState } from "react";
import { Button, Pagination } from "react-bootstrap";
import { Question } from "../widgets/Question";
import Badge from "react-bootstrap/Badge";
import { Link, useNavigate, useParams } from "react-router-dom";
import mockExamService from "../services/mockExamService";
import AppContext from "../context/app-context";
import MultipleChoiceQuestion from "../widgets/multipleChoiceQuestion";
import { PRACTICE_EXAM_CONTENTS } from "../page/homePage";
const PAGE_SIZE = 5;

const examMap = [];

PRACTICE_EXAM_CONTENTS.forEach((content) => {
  const { exams } = content;
  exams.forEach((e) => {
    const { path, exam: list } = e;
    examMap[path] = list;
  });
});
export default function ViewExamHistory() {
  const navigate = useNavigate();
  const { examId } = useParams("examId");
  const [exams, setExams] = useState(examMap[examId]);
  const { userProfile, setLoading, examHistory } = useContext(AppContext);

  var pageNeeded = exams.length / PAGE_SIZE;
  const isAPCSExam = examId.includes("apcs");

  const [currentPage, setCurrentPage] = useState(1);
  if (exams.length % PAGE_SIZE > 0) {
    pageNeeded += 1;
  }

  const pageItems = [];
  for (var number = 1; number <= pageNeeded; number++) {
    const startP = PAGE_SIZE * (number - 1);
    const unFinishCount = exams
      .slice(startP, startP + PAGE_SIZE)
      .filter((q) => q.selectedKey === undefined).length;
    pageItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={(e) => {
          setCurrentPage(
            Number(
              e.target.innerHTML.substring(0, e.target.innerHTML.indexOf("<"))
            )
          );
          window.scrollTo(0, 0);
        }}
      >
        {number}
        <div style={{ position: "absolute", top: "-10px", right: "-5px" }}>
          {" "}
          {unFinishCount !== 0 && (
            <Badge bg="danger" text="dark">
              {unFinishCount}
            </Badge>
          )}
        </div>
      </Pagination.Item>
    );
  }
  const fromQues = PAGE_SIZE * (currentPage - 1);
  const toQues = fromQues + PAGE_SIZE;
  const {
    correctAnswers = [],
    selectedAnswers = [],
    correctCount,
    correctRate,
  } = examHistory[examId] || {};
  // if (
  //   exams !== undefined &&
  //   examHistory[examId] !== undefined &&
  //   exams.length !== selectedAnswers.length
  // ) {
  //   navigate("/");
  // }
  return (
    <div>
      {examHistory[examId] !== null && (
        <div>
          {correctCount ? (
            <h3>{`You get ${correctCount} question correct. your score is ${
              correctRate * 100
            }/100`}</h3>
          ) : (
            <h3>
              <Link to={`/practice-Exam/${examId}`}>Take this test now</Link>
            </h3>
          )}
        </div>
      )}
      {isAPCSExam &&
        exams.slice(fromQues, toQues).map((ques, index) => {
          const selectedIndex = fromQues + index;
          return (
            <div key={generateRandomString()}>
              <Question
                {...ques}
                correctAnswer={
                  correctAnswers ? correctAnswers[selectedIndex] : undefined
                }
                selectedKey={
                  selectedAnswers ? selectedAnswers[selectedIndex] : undefined
                }
              />
            </div>
          );
        })}
      {!isAPCSExam &&
        exams.slice(fromQues, toQues).map((ques, index) => {
          const selectedIndex = fromQues + index;
          return (
            <div key={generateRandomString()}>
              <MultipleChoiceQuestion
                {...ques}
                correctAnswer={
                  correctAnswers ? correctAnswers[selectedIndex] : undefined
                }
                selectedKey={
                  selectedAnswers ? selectedAnswers[selectedIndex] : undefined
                }
              />
            </div>
          );
        })}
      <Pagination>{pageItems}</Pagination>
      <div
        style={{
          textAlign: "right",
          margin: "10px 0",
        }}
      ></div>
    </div>
  );
}
