import { useContext, useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import CreateTopicModal from "../components/createTopicModal";
import { TOPIC_CATEGORY } from "../constants";
import topicService from "../services/topicService";
import DiscussionTable from "../widgets/discussionTable";
import Nav from "react-bootstrap/Nav";
import AppContext from "../context/app-context";

export default function DiscussionPage() {
  const [createNewTopic, createNewTopicSet] = useState(false);
  const [category, categorySet] = useState(TOPIC_CATEGORY[0]);
  const { userProfile, setLoading } = useContext(AppContext);
  const [refresh, setRefresh] = useState(0);

  const loadCategory = (cat) => {
    topicService.getDiscussionByCategory({
      category: cat,
      callback: ({ discussionTopics }) => {
        setDiscussions(discussionTopics);
      },
    });
  };

  const [discussions, setDiscussions] = useState([]);
  useEffect(() => {
    loadCategory(category);
  }, [refresh]);
  return (
    <div>
      <Nav variant="tabs" defaultActiveKey={category}>
        {TOPIC_CATEGORY.map((cat) => {
          return (
            <Nav.Item
              key={cat}
              onClick={(e) => {
                if (cat === "CreatedByMe") {
                  if (userProfile === null) {
                    setDiscussions([]);
                    return;
                  }
                  const { userId } = userProfile;
                  topicService.getDiscussionByUserId({
                    userId,
                    callback: ({ discussionTopics }) => {
                      setDiscussions(discussionTopics);
                    },
                  });
                } else {
                  categorySet(cat);
                  loadCategory(cat);
                }
              }}
            >
              <Nav.Link eventKey={cat}>{cat}</Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
      <div
        style={{
          textAlign: "right",
          margin: "10px 0",
        }}
      >
        <Button onClick={() => createNewTopicSet(true)}>
          Create new discussion
        </Button>
      </div>
      <CreateTopicModal
        showModal={createNewTopic}
        onClose={() => {
          createNewTopicSet(false);
          setRefresh(refresh + 1);
        }}
      />
      <div>
        <div className="discussionList">
          <DiscussionTable discussions={discussions} />
        </div>
      </div>
    </div>
  );
}
