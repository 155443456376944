export const apchem1 = [
  {
    question:
      "What is the name of the type of reaction in which two or more reactants combine to form a single product?",
    options: [
      "Decomposition reaction",
      "Combination reaction",
      "Displacement reaction",
      "Redox reaction",
    ],
  },
  {
    question:
      "What is the state of matter for a substance with a low temperature and low pressure?",
    options: ["Solid", "Gas", "Liquid", "Plasma"],
  },
  {
    question: "What type of bond is formed by the sharing of electrons?",
    options: [
      "Ionic bond",
      "Covalent bond",
      "Hydrogen bond",
      "Van der Waals bond",
    ],
  },
  {
    question:
      "What is the mole ratio of reactants in a balanced chemical equation?",
    options: ["Not equal", "Equal", "Greater than", "Less than"],
  },
  {
    question:
      "What is the pressure inside a closed container filled with gas, if the volume is increased at constant temperature?",
    options: [
      "Decreased",
      "Increased",
      "Remains the same",
      "Cannot be determined",
    ],
  },
  {
    question:
      "What is the mole ratio of sulfuric acid to nitric acid in the reaction between sulfuric acid and nitric acid?",
    options: ["1:1", "2:1", "1:2", "3:1"],
  },
  {
    question:
      "What is the equation for the reaction between magnesium metal and hydrochloric acid?",
    options: [
      "Mg + HCl -> MgCl2 + H2",
      "Mg + 2HCl -> MgCl2 + H2",
      "2Mg + HCl -> 2MgCl + H2",
      "Mg + HCl -> MgCl + H2",
    ],
  },
  {
    question:
      "What is the maximum number of electrons that can occupy the third energy level?",
    options: ["2", "8", "18", "32"],
  },
  {
    question:
      "What type of reaction is represented by the following equation: 2H2 + O2 -> 2H2O?",
    options: [
      "Combination reaction",
      "Decomposition reaction",
      "Displacement reaction",
      "Redox reaction",
    ],
  },
  {
    question:
      "What is the coefficient of the balanced chemical equation for the reaction between sulfur and oxygen?",
    options: ["1", "2", "4", "8"],
  },
  {
    question:
      "What is the name of the reaction between a metal and an acid to produce a salt and hydrogen gas?",
    options: [
      "Displacement reaction",
      "Combination reaction",
      "Decomposition reaction",
      "Redox reaction",
    ],
  },
  {
    question:
      "What is the equation for the reaction between potassium metal and water?",
    options: [
      "K + H2O -> KOH + H2",
      "K + 2H2O -> KOH + H2",
      "2K + H2O -> 2KOH + H2",
      "K + H2O -> K2O + H2",
    ],
  },
  {
    question: "What is the name of the compound with the chemical formula HCl?",
    options: [
      "Hydrogen chloride",
      "Hydrogen bromide",
      "Hydrogen iodide",
      "Hydrogen sulfide",
    ],
  },
  {
    question: "What is the chemical formula for nitric acid?",
    options: ["NO", "NO2", "HNO2", "HNO3"],
  },
  {
    question: "What type of bond forms between two non-metals?",
    options: ["Ionic bond", "Covalent bond", "Metallic bond", "Hydrogen bond"],
  },
  {
    question:
      "What is the equation for the reaction between sodium chloride and silver nitrate?",
    options: [
      "NaCl + AgNO3 -> NaNO3 + AgCl",
      "2NaCl + AgNO3 -> Na2NO3 + 2AgCl",
      "NaCl + AgNO3 -> NaClO3 + Ag",
      "NaCl + 2AgNO3 -> NaNO3 + 2AgCl",
    ],
  },
  {
    question: "Which gas is not a greenhouse gas?",
    options: ["Carbon dioxide", "Methane", "Nitrogen", "Oxygen"],
  },
  {
    question: "What type of reaction occurs during photosynthesis?",
    options: [
      "Endothermic reaction",
      "Exothermic reaction",
      "Redox reaction",
      "Acid-base reaction",
    ],
  },
  {
    question: "Which type of matter has a definite shape and volume?",
    options: ["Solid", "Liquid", "Gas", "Plasma"],
  },
  {
    question:
      "What is the balanced chemical equation for the reaction between sodium hydroxide and hydrochloric acid?",
    options: [
      "NaOH + HCl -> NaCl + H2O",
      "NaCl + H2O -> NaOH + HCl",
      "NaCl + HCl -> NaOH + H2O",
      "NaOH + NaCl -> HCl + H2O",
    ],
  },
  {
    question: "What is the function of the cathode in an electrochemical cell?",
    options: [
      "To reduce ions",
      "To oxidize ions",
      "To store energy",
      "To transfer electrons",
    ],
  },
  {
    question: "What type of reaction occurs in a fuel cell?",
    options: [
      "Endothermic reaction",
      "Exothermic reaction",
      "Redox reaction",
      "Acid-base reaction",
    ],
  },
  {
    question: "What is the mole fraction of a solute in a solution?",
    options: [
      "The number of moles of solute divided by the number of moles of solvent",
      "The number of moles of solute divided by the total number of moles in the solution",
      "The ratio of the pressure of the solute to the pressure of the solvent in the solution",
      "The ratio of the volume of the solute to the volume of the solvent in the solution",
    ],
  },
  {
    question:
      "What is the name of the type of bond that forms between a metal and a non-metal?",
    options: ["Ionic bond", "Covalent bond", "Hydrogen bond", "Metallic bond"],
  },
];
