export const ApCSBooleanExp1 = [
  {
    question: "What is the value of the following expression? `false && true || true`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "What is the result of the following expression? `true && !false || false`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question:
      "What is the output of the following code? \n `boolean a = false; \n boolean b = true; \n System.out.println(a || !b && !(a && b));`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "What is the value of the following expression? `!(true && false) && (true || false && false)`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "What is the value of the following expression? `(5 > 3 && 2 < 1) || (true || false && !true)`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question:
      "What is the output of the following code? \n `boolean a = true; \n boolean b = false; \n System.out.println((a || b) && !(a && b));`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "What is the value of the following expression? `false || true && false`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "Which of the following expressions is equivalent to `!(a || b) && (!a && !b)`?",
    options: ["`a && b`", "`!(a && b)`", "`!a || b`", "`!a && b || a && !b`", "`!a && !b`"],
  },
  {
    question: "What is the value of the boolean expression `(true || false) && !(false && !true)`?",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "What is the value of the following expression? \n `3 < 5 && 4 < 2 || !(2 > 1)`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question:
      "What is the output of the following code? \n `boolean a = false; \n boolean b = true; \n System.out.println(!(a && !b) && (a || b) && (a && b || !a && !b));`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "Which of the following expressions evaluates to `false`?",
    options: ["`1 + 2 == 3`", "`!(1 > 2)`", "`(true && false) || true`", "`(5 >= 5) && (3 < 2)`"],
  },
  {
    question: "What is the value of the boolean expression `!(true || false) && (true && false)`?",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "Which of the following expressions is equivalent to `!(a && b) && (a || b)`?",
    options: ["`a || b`", "`a && b || !a && !b`", "`!(a || b)`", "`!a && b || a && !b`"],
  },
  {
    question: "What is the result of the following expression? `!(true && !false)`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "Which of the following expressions is equivalent to `!(a || b) && (a && b)`?",
    options: ["`a || b`", "`a && b || !a && !b`", "`!(a && b)`", "`!a || b`", "`false`"],
  },
  {
    question: "What is the value of the following expression? `true || false && true`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "What is the value of the boolean expression `(true || false) && (false || true)`?",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "Which of the following expressions is equivalent to `a && (!b || c) && (!b || !c)`?",
    options: ["`a && b && c`", "`a && b || a && c`", "`a && (b || c)`", "`(a || b) && (a || c)`", "`a && !b`"],
  },
  {
    question: "What is the value of the boolean expression `true && !false || false && true`?",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "What is the value of the expression `!(3 > 5)`?",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "Which of the following statements about boolean expressions is true?",
    options: [
      "A boolean expression always evaluates to true.",
      "A boolean expression always evaluates to false.",
      "A boolean expression can evaluate to either true or false.",
      "A boolean expression can evaluate to null.",
    ],
  },
  {
    question:
      "What is the output of the following code? \n `boolean a = true; \n boolean b = false; \n System.out.println(a || b && !(a && b));`",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "What is the value of the boolean expression `!(!true || false && !true) && (false || !false)`?",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "What is the value of the boolean expression `true || false && true`?",
    options: ["true", "false", "null", "Syntax error"],
  },
  {
    question: "Which of the following expressions is equivalent to `(a && b) || (!a && !b)`?",
    options: ["`a || b`", "`a && !b || !a && b`", "`!(a || b)`", "`!a && b || a && !b`", "`a == b`"],
  },
  {
    question: "What is the value of the following expression? `!(1 < 2) && (3 > 4 || 2 < 1)`",
    options: ["true", "false", "null", "Syntax error"],
  },
];
