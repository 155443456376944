export const apusgov1 = [
  {
    question:
      "What is the main function of the Supreme Court in the US political system?",
    options: [
      "To make laws",
      "To enforce laws",
      "To interpret the Constitution and serve as the final court of appeal",
      "To regulate the economy",
    ],
  },
  {
    question:
      "What is the main difference between the House of Representatives and the Senate in the US political system?",
    options: [
      "The House of Representatives is more powerful",
      "The Senate is more powerful",
      "The House of Representatives has more members, and the Senate has longer terms",
      "The Senate has more members, and the House of Representatives has longer terms",
    ],
  },
  {
    question:
      "What is the significance of the 22nd Amendment to the Constitution?",
    options: [
      "It limited the number of terms a president can serve",
      "It ensured that minority voters would not be disadvantaged in the voting process",
      "It established the principle of equal protection under the law",
      "It established the principle of the separation of powers",
    ],
  },
  {
    question:
      "What is the main function of political parties in the US political system?",
    options: [
      "To choose the nominee of a political party for the general election",
      "To regulate the economy",
      "To advocate for specific policy positions on behalf of their members",
      "To create and enforce laws",
    ],
  },
  {
    question:
      "What is the main purpose of the Federal Reserve System in the US political system?",
    options: [
      "To regulate the money supply and control inflation",
      "To make laws",
      "To enforce laws",
      "To interpret the Constitution and serve as the final court of appeal",
    ],
  },
  {
    question:
      "What is the main difference between a federal system and a unitary system of government?",
    options: [
      "In a federal system, power is divided between the national and state governments, while in a unitary system, power is held by the national government",
      "In a federal system, power is held by the national government, while in a unitary system, power is divided between the national and state governments",
      "In a federal system, power is divided between the national and local governments, while in a unitary system, power is held by the national government",
      "In a federal system, power is held by the state governments, while in a unitary system, power is divided between the national and local governments",
    ],
  },
  {
    question:
      "What is the main function of interest groups in the US political system?",
    options: [
      "To choose the nominee of a political party for the general election",
      "To advocate for specific policy positions on behalf of their members",
      "To create and enforce laws",
      "To regulate the economy",
    ],
  },
  {
    question:
      "What is the main function of the Executive Branch in the US political system?",
    options: [
      "To make laws",
      "To enforce laws",
      "To interpret the Constitution and serve as the final court of appeal",
      "To carry out the laws and administer the government",
    ],
  },
  {
    question:
      "What is the main function of the Legislative Branch in the US political system?",
    options: [
      "To carry out the laws and administer the government",
      "To make laws",
      "To enforce laws",
      "To interpret the Constitution and serve as the final court of appeal",
    ],
  },
  {
    question:
      "What is the main purpose of the 22nd Amendment to the US Constitution?",
    options: [
      "To limit the power of the federal government",
      "To limit the number of terms a President can serve",
      "To provide for the direct election of Senators",
      "To protect the rights of citizens and limit the power of government",
    ],
  },
  {
    question:
      "What is the main difference between the federal and state court systems in the US?",
    options: [
      "Federal courts hear cases involving the Constitution or federal law, while state courts hear cases involving state law",
      "State courts hear cases involving the Constitution or federal law, while federal courts hear cases involving state law",
      "Federal courts have jurisdiction over all cases in the US, while state courts only hear cases within their state",
      "State courts have jurisdiction over all cases in the US, while federal courts only hear cases within their state",
    ],
  },
  {
    question:
      "What is the main difference between a parliamentary system and a presidential system of government?",
    options: [
      "In a parliamentary system, the head of government is elected by the legislature, while in a presidential system, the head of government is elected by the people",
      "In a parliamentary system, the head of government is elected by the people, while in a presidential system, the head of government is elected by the legislature",
      "In a parliamentary system, the head of state and head of government are separate positions, while in a presidential system, they are combined in the same person",
      "In a parliamentary system, the head of state and head of government are combined in the same person, while in a presidential system, they are separate positions",
    ],
  },
  {
    question:
      "What is the main function of the Federal Election Commission in the US political system?",
    options: [
      "To oversee the administration of elections at the federal level",
      "To regulate the economy",
      "To make laws",
      "To enforce laws",
    ],
  },
  {
    question:
      "What is the main difference between the House of Representatives and the Senate in the US Congress?",
    options: [
      "The House of Representatives has more members than the Senate, while the Senate has equal representation for each state",
      "The Senate has more members than the House of Representatives, while the House of Representatives has equal representation for each state",
      "The House of Representatives has equal representation for each state, while the Senate has representation based on population",
      "The Senate has equal representation for each state, while the House of Representatives has representation based on population",
    ],
  },
  {
    question: "What is the main function of the US Attorney General?",
    options: [
      "To serve as the chief legal advisor to the President and the executive branch",
      "To enforce laws and prosecute criminals at the federal level",
      "To interpret the Constitution and serve as the final court of appeal",
      "To make laws",
    ],
  },
  {
    question:
      "What is the main purpose of the Bill of Rights in the US Constitution?",
    options: [
      "To limit the power of the federal government and protect the rights of citizens",
      "To limit the number of terms a President can serve",
      "To provide for the direct election of Senators",
      "To establish the Supreme Court and the federal court system",
    ],
  },
  {
    question:
      "What is the main function of the Federal Reserve System in the US economy?",
    options: [
      "To regulate the supply of money and control inflation",
      "To make laws",
      "To enforce laws",
      "To interpret the Constitution and serve as the final court of appeal",
    ],
  },
  {
    question:
      "What is the main difference between the US presidential system and a parliamentary system?",
    options: [
      "The President is the head of state and head of government in a presidential system, while in a parliamentary system the head of state and head of government are separate",
      "The head of state and head of government are separate in a presidential system, while in a parliamentary system the head of state and head of government are combined",
      "In a presidential system, the head of government is elected directly by the people, while in a parliamentary system the head of government is appointed by the head of state",
      "In a presidential system, the head of government is appointed by the head of state, while in a parliamentary system the head of government is elected directly by the people",
    ],
  },
  {
    question:
      "What is the main role of interest groups in the US political system?",
    options: [
      "To enforce laws and prosecute criminals at the federal level",
      "To serve as the chief legal advisor to the President and the executive branch",
      "To represent a particular set of political beliefs and ideas and influence government policy",
      "To make laws",
    ],
  },
];
