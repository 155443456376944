import axios from "axios";
import Auth from "@aws-amplify/auth";
import { getUserJwtToken } from "../utils/helperFunctions";
import { isUserLogined } from "../context/useAuth";

const TOPIC_SVC_URL = "https://aptopic.service.crz97.com/";

const client = axios.create({ baseURL: TOPIC_SVC_URL });

const API_PATH = {
  topic: "topic",
  topics: "topics",
};

async function getDiscussionByCategory({ callback, category }) {
  client
    .get(`${API_PATH.topics}?category=${category}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((res) => {
      const {
        data: { discussionTopics },
      } = res;
      callback({ discussionTopics });
    });
}

async function getDiscussionByUserId({ callback, userId }) {
  if (!(await isUserLogined())) {
    // callback({ discussionTopics: [] });
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `${API_PATH.topics}?userId=${userId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const {
        data: { discussionTopics },
      } = res;
      console.log(discussionTopics);
      callback({ discussionTopics });
    });
}

async function createComment({ topicId, comment, parentId, createdBy, callback }) {
  if (!(await isUserLogined())) {
    alert("You need to login first!!");
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `${API_PATH.topic}/${topicId}`,
      { comment, parentId, createdBy },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const {
        data: { comment },
      } = res;
      callback({ comment });
    });
}

async function getTopicDetails({ callback, topicId }) {
  // const user = await Auth.currentAuthenticatedUser();
  // const jwtToken = getUserJwtToken(user);
  client
    .get(`${API_PATH.topic}/${topicId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((res) => {
      const {
        data: { topic, comments },
      } = res;
      console.log({ topic, comments });
      callback({ topic, comments });
    });
}

async function createDiscusionTopic({ callback, title, description, category, files = [], createdBy, filesUploadCallback }) {
  // const file = attachments[0];
  if (!(await isUserLogined())) {
    alert("You need to login first!!");
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  console.log("Creating item for " + title);
  const attachments = [];
  for (const file of files) {
    const { name, type, size } = file;
    //  {
    //   bucket: "movie-collections-prod-us-east-2",
    //   key: "2f314d06-204b-7c88-4d1e-767859a85646/" + name,
    // };
    const { preSignedURL, bucket, key } = await getPresignedUrl({
      jwtToken,
      fileName: name,
      fileType: type,
    });
    const res = await axios.put(preSignedURL, file, {
      headers: { "Content-Type": file.type },
      onUploadProgress: (progressEvent) => {
        const uploadPercentage = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
        filesUploadCallback({ id: name, value: uploadPercentage });
      },
    });
    // console.log(res);
    attachments.push({ bucket, key, name, type, size });
  }

  client
    .post(
      `topic`,
      {
        title,
        attachments,
        description,
        createdBy,
        category,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const {
        data: { topic },
      } = res;
      callback({ topic });
    });
}

async function getPresignedUrl({ jwtToken, fileName, fileType }) {
  // const user = await Auth.currentAuthenticatedUser();
  // const jwtToken = getUserJwtToken(user);
  return await client
    .get(`getPresignedUrl?fileName=${fileName}&fileType=${fileType}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: jwtToken,
      },
    })
    .then((res) => {
      const {
        data: { preSignedURL, bucket, key },
      } = res;
      return { preSignedURL, bucket, key };
      // const {
      //   data: { collections },
      // } = res;
      // console.log(collections);
      // callback(collections);
    });
}

export default {
  createDiscusionTopic,
  getDiscussionByCategory,
  getDiscussionByUserId,
  getTopicDetails,
  createComment,
};
