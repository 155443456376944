export const TOPIC_CATEGORY = [
  "AP CS",
  "AP US History",
  "AP US Gov",
  "AP Chemistry",
  "AP Environmental Science",
  "AP Stats",
  "Others",
  "Issue Report",
  "CreatedByMe",
  "",
];
