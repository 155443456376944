export const apusgov2 = [
  {
    question:
      "What is the primary function of political parties in the United States?",
    options: [
      "To create and pass laws",
      "To enforce laws",
      "To provide a means for citizens to participate in the political process",
      "To regulate the economy",
    ],
  },
  {
    question:
      "What is the main purpose of the First Amendment to the US Constitution?",
    options: [
      "To protect the rights of states",
      "To establish a national religion",
      "To protect individual rights, such as freedom of speech and religion",
      "To limit the power of the federal government",
    ],
  },
  {
    question:
      "What is the difference between a primary election and a general election in the United States?",
    options: [
      "A primary election is held only in presidential election years, while a general election is held every two years",
      "A primary election is held to determine the nominees of political parties, while a general election is held to determine the winner of the election",
      "A primary election is held only in even-numbered years, while a general election is held only in odd-numbered years",
      "A primary election is held only in small states, while a general election is held only in large states",
    ],
  },
  {
    question:
      "What is the significance of the 22nd Amendment to the US Constitution?",
    options: [
      "It establishes the powers of the Supreme Court",
      "It limits the number of terms a president can serve",
      "It establishes the power of the Federal Reserve",
      "It establishes the powers of the Department of Education",
    ],
  },
  {
    question: "What is the role of interest groups in the US political system?",
    options: [
      "To create and enforce laws",
      "To influence government policies and decisions",
      "To regulate the economy",
      "To provide services to citizens",
    ],
  },
  {
    question:
      "What is the function of the Electoral College in the US presidential election process?",
    options: [
      "To select the president directly",
      "To provide a margin of victory for the candidate who wins the popular vote",
      "To provide a mechanism for electing the president through a representative process",
      "To ensure that smaller states have a greater influence in the election",
    ],
  },
  {
    question:
      "What is the significance of the Bill of Rights in the US political system?",
    options: [
      "It establishes the powers of the Supreme Court",
      "It limits the powers of the federal government",
      "It establishes the powers of the state governments",
      "It establishes the powers of the president",
    ],
  },
  {
    question:
      "What is the difference between a closed primary election and an open primary election in the United States?",
    options: [
      "A closed primary election is only open to registered members of a political party, while an open primary election is open to all eligible voters",
      "A closed primary election is held only in small states, while an open primary election is held only in large states",
      "A closed primary election is held in odd-numbered years, while an open primary election is held in even-numbered years",
      "A closed primary election is held to determine the winner of the election, while an open primary election is held to determine the nominees of political parties",
    ],
  },
  {
    question:
      "What is the significance of the 14th Amendment to the US Constitution?",
    options: [
      "It establishes the powers of the Federal Reserve",
      "It limits the number of terms a president can serve",
      "It establishes the principle of equal protection under the law",
      "It establishes the powers of the Department of Education",
    ],
  },
  {
    question: "What is the role of the media in the US political system?",
    options: [
      "To create and enforce laws",
      "To regulate the economy",
      "To provide information and analysis to citizens",
      "To provide services to citizens",
    ],
  },
  {
    question:
      "What is the function of the Supreme Court in the US political system?",
    options: [
      "To enforce laws",
      "To make laws",
      "To interpret laws",
      "To veto laws",
    ],
  },
  {
    question:
      "What is the difference between a presidential system of government and a parliamentary system of government?",
    options: [
      "A presidential system has a separation of powers between the executive and legislative branches, while a parliamentary system does not",
      "A presidential system has a strong executive branch, while a parliamentary system has a weak executive branch",
      "A presidential system has a parliamentary form of government, while a parliamentary system has a presidential form of government",
      "A presidential system has a bicameral legislature, while a parliamentary system has a unicameral legislature",
    ],
  },
  {
    question:
      "What is the significance of the 19th Amendment to the US Constitution?",
    options: [
      "It established the principle of equal protection under the law",
      "It established the principle of the separation of powers",
      "It established the principle of judicial review",
      "It gave women the right to vote",
    ],
  },
  {
    question:
      "What is the main function of the Federal Reserve System (the Fed)?",
    options: [
      "To enforce laws",
      "To make laws",
      "To regulate the money supply and control inflation",
      "To veto laws",
    ],
  },
  {
    question: "What is the significance of the Voting Rights Act of 1965?",
    options: [
      "It established the principle of the separation of powers",
      "It limited the number of terms a president can serve",
      "It established the principle of judicial review",
      "It ensured that minority voters would not be disadvantaged in the voting process",
    ],
  },
  {
    question:
      "What is the main purpose of the primary elections in the US political system?",
    options: [
      "To choose the nominee of a political party for the general election",
      "To regulate the economy",
      "To advocate for specific policy positions on behalf of their members",
      "To create and enforce laws",
    ],
  },
  {
    question:
      "What is the significance of the Great Compromise at the Constitutional Convention of 1787?",
    options: [
      "It established the principle of equal protection under the law",
      "It resolved the disagreement between large and small states over representation in the new government",
      "It established the principle of judicial review",
      "It gave women the right to vote",
    ],
  },
  {
    question:
      "What is the main function of the Electoral College in the US political system?",
    options: [
      "To enforce laws",
      "To elect the President of the United States",
      "To regulate the money supply and control inflation",
      "To veto laws",
    ],
  },
];
