// db.js
import Dexie from "dexie";

// Create database
const MyCarDB = new Dexie("MyExamNotes");

// Define version and stores
MyCarDB.version(1).stores({
  notesItem: "&id, noteContents, examId, examIdIndex, createdDate, lastUpdatedDate",
});

// CRUD operations for car stats

// Add or update car item
async function putNotesItem(item) {
  const lastUpdatedDate = new Date().toISOString();
  console.log("putting putNotesItem item", item);
  return MyCarDB.notesItem.put({ ...item, lastUpdatedDate });
}
// Delete car item by ID
export async function deleteNotesItem(id) {
  return MyCarDB.notesItem.delete(id);
}

// Get all events for a specific car
export async function getNotesByExamId(id) {
  return MyCarDB.notesItem.where("examIdIndex").equals(id).toArray();
}

export default {
  putNotesItem,
  deleteNotesItem,
  getNotesByExamId,
};
