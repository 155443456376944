export const apes2 = [
  {
    question:
      "Which of the following is not a factor contributing to desertification?",
    options: [
      "Overgrazing",
      "Deforestation",
      "Urbanization",
      "Increased precipitation",
    ],
  },
  {
    question: "What is the main greenhouse gas responsible for global warming?",
    options: [
      "Carbon dioxide",
      "Methane",
      "Nitrous oxide",
      "Chlorofluorocarbons",
    ],
  },
  {
    question:
      "Which of the following best describes the process of photosynthesis?",
    options: [
      "The process by which plants absorb light and convert it into energy",
      "The process by which plants release oxygen into the atmosphere",
      "The process by which plants absorb carbon dioxide from the air",
      "All of the above",
    ],
  },
  {
    question: "What is the primary cause of ocean acidification?",
    options: [
      "Increased volcanic activity",
      "Increased deforestation",
      "Increased atmospheric carbon dioxide",
      "Increased ocean temperature",
    ],
  },
  {
    question: "What is the primary source of water pollution in urban areas?",
    options: [
      "Agricultural runoff",
      "Industrial discharge",
      "Sewage treatment plants",
      "Leaking underground storage tanks",
    ],
  },
  {
    question: "What is the most common type of renewable energy?",
    options: [
      "Hydroelectric power",
      "Wind power",
      "Solar power",
      "Geothermal energy",
    ],
  },
  {
    question: "What is the main source of air pollution in urban areas?",
    options: [
      "Agricultural activities",
      "Traffic emissions",
      "Industrial emissions",
      "Forest fires",
    ],
  },
  {
    question: "What is the primary cause of soil erosion?",
    options: [
      "Overgrazing",
      "Deforestation",
      "Urbanization",
      "Natural weather events",
    ],
  },
  {
    question: "What is the primary cause of species extinction?",
    options: [
      "Pollution",
      "Habitat destruction",
      "Overhunting",
      "Climate change",
    ],
  },
  {
    question:
      "What is the main difference between renewable and non-renewable energy sources?",
    options: [
      "Renewable energy sources are finite, while non-renewable sources are unlimited",
      "Renewable energy sources are cleaner, while non-renewable sources are dirtier",
      "Renewable energy sources can be replenished, while non-renewable sources cannot",
      "Renewable energy sources are cheaper, while non-renewable sources are more expensive",
    ],
  },
  {
    question:
      "What is the most effective way to reduce carbon emissions from transportation?",
    options: [
      "Improving public transportation",
      "Encouraging the use of electric vehicles",
      "Building more highways",
      "Encouraging carpooling",
    ],
  },
  {
    question:
      "What is the main impact of dam construction on rivers and their ecosystems?",
    options: [
      "Increase in water quality",
      "Increase in biodiversity",
      "Decrease in water flow and loss of habitats",
      "Increase in fish populations",
    ],
  },
  {
    question:
      "What is the main factor that determines the carrying capacity of an ecosystem?",
    options: [
      "The number of species in the ecosystem",
      "The amount of resources available in the ecosystem",
      "The number of organisms that the ecosystem can support",
      "The size of the ecosystem",
    ],
  },
  {
    question: "What is the primary benefit of using compost in agriculture?",
    options: [
      "It helps to reduce the use of chemical fertilizers",
      "It increases the soil's water retention capabilities",
      "It reduces soil erosion",
      "All of the above",
    ],
  },
  {
    question: "What is the primary cause of water scarcity in arid regions?",
    options: [
      "Lack of access to clean drinking water",
      "Overconsumption of water resources",
      "Lack of precipitation",
      "Degradation of water sources",
    ],
  },
  {
    question: "What is the main cause of ocean acidification?",
    options: [
      "Increased temperature",
      "Increased carbon dioxide in the atmosphere",
      "Increased nutrient runoff",
      "Increased sea level",
    ],
  },
  {
    question: "What is the primary impact of deforestation on the environment?",
    options: [
      "Decreased carbon dioxide levels",
      "Increased soil fertility",
      "Increased biodiversity",
      "Increased soil erosion and loss of habitats",
    ],
  },
  {
    question: "What is the main reason for the decline in bee populations?",
    options: [
      "Increased use of pesticides",
      "Loss of habitat",
      "Climate change",
      "Disease outbreaks",
    ],
  },
  {
    question: "What is the primary cause of desertification?",
    options: [
      "Lack of rainfall",
      "Overgrazing",
      "Deforestation",
      "Urbanization",
    ],
  },
  {
    question: "What is the main impact of climate change on oceans?",
    options: [
      "Increased ocean acidification",
      "Decreased ocean temperatures",
      "Increased ocean biodiversity",
      "Decreased ocean salinity",
    ],
  },
  {
    question:
      "What is the main reason for the decline in amphibian populations?",
    options: [
      "Climate change",
      "Habitat loss",
      "Introduction of non-native species",
      "All of the above",
    ],
  },
  {
    question: "What is the primary impact of wetlands on the environment?",
    options: [
      "Decreased water quality",
      "Increased carbon sequestration",
      "Increased soil erosion",
      "Decreased biodiversity",
    ],
  },
  {
    question: "What is the main cause of soil degradation?",
    options: [
      "Overgrazing",
      "Deforestation",
      "Urbanization",
      "All of the above",
    ],
  },
  {
    question:
      "What is the main impact of climate change on terrestrial ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Increased productivity",
      "Decreased carbon sequestration",
    ],
  },
  {
    question: "What is the primary impact of air pollution on human health?",
    options: [
      "Increased risk of respiratory diseases",
      "Increased risk of cardiovascular diseases",
      "Decreased risk of cancer",
      "Improved overall health",
    ],
  },
  {
    question:
      "What is the primary impact of water pollution on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased water quality",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question: "What is the main cause of soil pollution?",
    options: [
      "Natural processes",
      "Agricultural practices",
      "Industrial activities",
      "All of the above",
    ],
  },
  {
    question:
      "What is the main impact of climate change on coastal ecosystems?",
    options: [
      "Increased range and abundance of species",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question: "What is the primary impact of light pollution on wildlife?",
    options: [
      "Increased range and abundance of species",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question:
      "What is the main impact of nitrogen pollution on freshwater ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased water quality",
      "Improved ecosystem health",
      "Increased eutrophication",
    ],
  },
];
