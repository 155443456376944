import { useContext } from "react";
import { Alert } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Link, useNavigate } from "react-router-dom";

import AppContext from "../context/app-context";
import { apCalcAB1 } from "../examLibrary/apcalab/calab1";
import { apchem1 } from "../examLibrary/apchem/apchem1";
import { apchem2 } from "../examLibrary/apchem/apchem2";
import { ApCSBooleanExp1 } from "../examLibrary/apcs/ApCSBooleanExpQues";
import { apcsexam1 } from "../examLibrary/apcs/apcsexam1";
import { apcsexam2 } from "../examLibrary/apcs/apcsexam2";
import { apes1 } from "../examLibrary/apes/apes1";
import { apes2 } from "../examLibrary/apes/apes2";
import { APHumanGeo1, APHumanGeo2, APHumanGeo3, APHumanGeo4 } from "../examLibrary/APHumanGeoQues";
import { ApPhysics1and21, ApPhysics1and22 } from "../examLibrary/ApPhysics1and2Ques";
import { apPsycho1, apPsycho2, apPsycho3 } from "../examLibrary/apPsycho/apPsychoQues";
import { apstats1 } from "../examLibrary/apstats/apstats1";
import { apstats2 } from "../examLibrary/apstats/apstats2";
import { apusgov1 } from "../examLibrary/apusgov/apusgov1";
import { apusgov2 } from "../examLibrary/apusgov/apusgov2";
import { apushexam1 } from "../examLibrary/apush/apushexam1";
import { apushexam2 } from "../examLibrary/apush/apushexam2";
import { apushexam3 } from "../examLibrary/apush/apushexam3";
import { apushexam4 } from "../examLibrary/apush/apushexam4";

const examPath = "/practice-Exam/";
export const PRACTICE_EXAM_CONTENTS = [
  {
    title: "AP Computer Science",
    exams: [
      {
        label: "Exam 1",
        path: "apcsexam1",
        exam: apcsexam1,
      },
      {
        label: "Exam 2",
        path: "apcsexam2",
        exam: apcsexam2,
      },
      {
        label: "Boolean Expression",
        path: "ApCSBooleanExp1",
        exam: ApCSBooleanExp1,
      },
    ],
  },
  {
    title: "AP US History",
    exams: [apushexam1, apushexam2, apushexam3, apushexam4].map((test, index) => ({
      label: "Exam " + (index + 1),
      path: "apushexam" + (index + 1),
      exam: test,
    })),
  },
  {
    title: "AP Calculus AB",
    exams: [apCalcAB1].map((test, index) => ({
      label: "Exam " + (index + 1),
      path: "apCalcAB" + (index + 1),
      exam: test,
    })),
  },
  {
    title: "AP US Gov and Politics",
    exams: [
      {
        label: "Exam 1",
        path: "apusgov1",
        exam: apusgov1,
      },
      {
        label: "Exam 2",
        path: "apusgov2",
        exam: apusgov2,
      },
    ],
  },
  {
    title: "AP Chemistry",
    exams: [
      {
        label: "Exam 1",
        path: "apchem1",
        exam: apchem1,
      },
      {
        label: "Exam 2",
        path: "apchem2",
        exam: apchem2,
      },
    ],
  },
  {
    title: "AP Environmental Science",
    exams: [
      {
        label: "Exam 1",
        path: "apes1",
        exam: apes1,
      },
      {
        label: "Exam 2",
        path: "apes2",
        exam: apes2,
      },
    ],
  },
  {
    title: "AP Statistics",
    exams: [
      {
        label: "Exam 1",
        path: "apstats1",
        exam: apstats1,
      },
      {
        label: "Exam 2",
        path: "apstats2",
        exam: apstats2,
      },
    ],
  },
  {
    title: "AP Physics 1&2: Algebra-Based",
    exams: [
      {
        label: "Exam 1",
        path: "ApPhysics1and21",
        exam: ApPhysics1and21,
      },
      {
        label: "Exam 2",
        path: "ApPhysics1and22",
        exam: ApPhysics1and22,
      },
    ],
  },
  {
    title: "AP Human Geography",
    exams: [
      {
        label: "Exam 1",
        path: "APHumanGeo1",
        exam: APHumanGeo1,
      },
      {
        label: "Exam 2",
        path: "APHumanGeo2",
        exam: APHumanGeo2,
      },
      {
        label: "Exam 3",
        path: "APHumanGeo3",
        exam: APHumanGeo3,
      },
      {
        label: "Exam 4",
        path: "APHumanGeo4",
        exam: APHumanGeo4,
      },
    ],
  },
  {
    title: "AP Psychology",
    exams: [
      {
        label: "Exam 1",
        path: "apPsychology1",
        exam: apPsycho1,
      },
      {
        label: "Exam 2",
        path: "apPsychology2",
        exam: apPsycho2,
      },
      {
        label: "Exam 3",
        path: "apPsychology3",
        exam: apPsycho3,
      },
    ],
  },
];

export default function HomePage(props) {
  const { userProfile, setLoading, examHistory } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <Alert key={"primary"} variant={"primary"}>
          Announcement: We are planning to start taking AP CS free response question and give feedback on the answer, feel
          free to check us back later.
        </Alert>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {PRACTICE_EXAM_CONTENTS.map((content) => {
          const { title, exams } = content;
          return (
            <div key={title} style={{ margin: "10px" }}>
              <Card style={{ width: "18rem" }}>
                <Card.Body>
                  <Card.Title>{title}</Card.Title>

                  {exams.map((exam) => {
                    const { label, path } = exam;

                    return (
                      <div
                        key={path}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Link key={path} to={examPath + path}>
                            {" "}
                            {label}
                          </Link>
                        </div>
                        {Object.keys(examHistory).includes(path) && (
                          <div
                            onClick={() => {
                              navigate(`/practice-exam-history/${path}`, {
                                result: examHistory[path],
                              });
                            }}
                          >
                            <Link>History</Link>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Card.Body>
              </Card>
            </div>
          );
        })}
      </div>
      <div>
        <Alert key={"info"} variant={"info"}>
          Note: If you login first, then when you submit a mock exam, we can save your submit history for you to go back to
          it again. Please create an account with your email address. Please contact us at contact@free-exam.com for any
          questions or concerns.
        </Alert>
      </div>
    </div>
  );
}
