export const apcsexam2 = [
  {
    question: `What is the output of the following code snippet?
    int num = 5;
    int sum = 0;
    for (int i = 0; i <= num; i++) {
    sum += i;
    }
    System.out.println(sum);`,
    choices: {
      A: `0`,
      B: `5`,
      C: `10`,
      D: `15`,
      E: ``,
    },
    answer: `C`,
  },
  {
    question: `Which of the following data structures would be best suited to store a collection of unique values in no particular order?`,
    choices: {
      A: `Array`,
      B: `Linked List`,
      C: `Hash Set`,
      D: `Tree Set`,
      E: ``,
    },
    answer: `C`,
  },
  {
    question: `What is the time complexity of the bubble sort algorithm in the worst case scenario?`,
    choices: {
      A: `O(n)`,
      B: `O(n log n)`,
      C: `O(n^2)`,
      D: `O(2^n)`,
      E: ``,
    },
    answer: `C`,
  },
  {
    question: `Which of the following is not an Object Oriented Programming concept?`,
    choices: {
      A: `Polymorphism`,
      B: `Inheritance`,
      C: `Encapsulation`,
      D: `Recursion`,
      E: ``,
    },
    answer: `D`,
  },
  {
    question: `What is the purpose of the continue statement in a loop?`,
    choices: {
      A: `To skip the current iteration of the loop and move to the next iteration`,
      B: `To exit the loop`,
      C: `To restart the loop from the beginning`,
      D: `To return to the top of the loop and repeat it indefinitely`,
      E: ``,
    },
    answer: `A`,
  },
  {
    question: `What is the output of the following code snippet?
    int[] arr = {1, 2, 3, 4, 5};
    for (int i = 0; i < arr.length; i++) {
      System.out.print(arr[i] + " ");
    }`,
    choices: {
      A: `1 2 3 4 5`,
      B: `5 4 3 2 1`,
      C: `0 1 2 3 4`,
      D: `5`,
      E: ``,
    },
    answer: `A`,
  },
  {
    question: `What is the purpose of the break statement in a loop?`,
    choices: {
      A: `To skip the current iteration of the loop and move to the next iteration`,
      B: `To exit the loop`,
      C: `To restart the loop from the beginning`,
      D: `To return to the top of the loop and repeat it indefinitely`,
      E: ``,
    },
    answer: `B`,
  },
  {
    question: `What is the difference between a stack and a queue?`,
    choices: {
      A: `A stack operates in a LIFO manner while a queue operates in a FIFO manner`,
      B: `A stack stores unique elements while a queue stores duplicate elements`,
      C: `A stack can only be used with primitive data types while a queue can be used with any data type`,
      D: `A stack allows for random access while a queue only allows for sequential access`,
      E: ``,
    },
    answer: `A`,
  },
  {
    question: `Which of the following is not a benefit of using object-oriented programming techniques?`,
    choices: {
      A: `Reusability of code`,
      B: `Improved maintainability`,
      C: `Improved security`,
      D: `Increased memory usage`,
      E: ``,
    },
    answer: `D`,
  },
  {
    question: `What is the output of the following code snippet?
    int x = 5;
    int y = 10;
    x = x + y;
    y = x - y;
    x = x - y;
    System.out.println("x: " + x + " y: " + y);
`,
    choices: {
      A: `x: 10 y: 5`,
      B: `x: 5 y: 10`,
      C: `x: 15 y: 5`,
      D: `x: 5 y: 15`,
      E: ``,
    },
    answer: `B`,
  },
  {
    question: `What is the time complexity of the binary search algorithm in the average case scenario?`,
    choices: {
      A: `O(n)`,
      B: `O(n log n)`,
      C: `O(n^2)`,
      D: `O(2^n)`,
      E: ``,
    },
    answer: `B`,
  },
  {
    question: `Which of the following is a difference between a dynamic array and a linked list?`,
    choices: {
      A: `A dynamic array has a fixed size while a linked list can grow and shrink dynamically`,
      B: `A dynamic array is faster for random access while a linked list is faster for sequential access`,
      C: `A dynamic array uses more memory than a linked list`,
      D: `A dynamic array has more overhead for inserting and deleting elements than a linked list`,
      E: ``,
    },
    answer: `D`,
  },
  {
    question: `Which of the following is not a primitive data type in Java?`,
    choices: {
      A: `int`,
      B: `float`,
      C: `boolean`,
      D: `String`,
      E: ``,
    },
    answer: `D`,
  },
  {
    question: `What is the output of the following code snippet?
    int num = 5;
    int factorial = 1;
    for (int i = 1; i <= num; i++) {
      factorial *= i;
    }
    System.out.println(factorial);
    `,
    choices: {
      A: `1`,
      B: `5`,
      C: `10`,
      D: `120`,
      E: ``,
    },
    answer: `D`,
  },
  {
    question: `What is the time complexity of insertion sort algorithm in the best-case scenario?`,
    choices: {
      A: `O(n)`,
      B: `O(n log n)`,
      C: `O(n^2)`,
      D: `O(2^n)`,
      E: ``,
    },
    answer: `A`,
  },
  {
    question: `What is the purpose of the "continue" statement in a loop?`,
    choices: {
      A: `To skip the current iteration of the loop and move to the next iteration`,
      B: `To exit the loop`,
      C: `To restart the loop from the beginning`,
      D: `To return to the top of the loop and repeat it indefinitely`,
      E: ``,
    },
    answer: `A`,
  },
  {
    question: `What is the difference between an abstract class and an interface in Java?`,
    choices: {
      A: `An abstract class can have instance variables and instance methods while an interface can only have constants and abstract methods`,
      B: `An abstract class can have instance variables, instance methods, and concrete methods while an interface can only have constants and abstract methods`,
      C: `An abstract class can only have abstract methods while an interface can have instance variables and instance methods`,
      D: `An abstract class can have instance variables and instance methods while an interface can only have constants and static methods`,
      E: ``,
    },
    answer: `A`,
  },
  {
    question: `What is the output of the following code snippet?
    String str = "Hello World!";
    System.out.println(str.substring(6, 11));
    `,
    choices: {
      A: `Hello`,
      B: `World`,
      C: `World!`,
      D: `!orld`,
      E: ``,
    },
    answer: `B`,
  },
  {
    question: `What is the time complexity of the quick sort algorithm in the average case scenario?`,
    choices: {
      A: `O(n)`,
      B: `O(n log n)`,
      C: `O(n^2)`,
      D: `O(2^n)`,
      E: ``,
    },
    answer: `B`,
  },
  {
    question: `What is an algorithm in computer science?`,
    choices: {
      A: `A set of instructions for solving a specific task`,
      B: `A program written in a high-level language`,
      C: `A computer hardware component`,
      D: `A database management system`,
      E: ``,
    },
    answer: `A`,
  },
  {
    question: `Which of the following is a difference between an array and a linked list?`,
    choices: {
      A: `An array has a constant time for accessing elements by index while a linked list has a linear time`,
      B: `An array has a linear time for inserting and deleting elements while a linked list has a constant time`,
      C: `An array uses less memory than a linked list`,
      D: `An array has a linear time for accessing elements by index while a linked list has a constant time`,
      E: ``,
    },
    answer: `A`,
  },
  {
    question: `What is polymorphism in object-oriented programming?`,
    choices: {
      A: `A mechanism that allows objects to share attributes and behavior`,
      B: `A mechanism that allows objects to share attributes but not behavior`,
      C: `A mechanism that allows objects to share behavior but not attributes`,
      D: `A mechanism that allows objects of different classes to be treated as objects of a common superclass`,
      E: ``,
    },
    answer: `D`,
  },
  {
    question: "What is a binary tree in computer science?,",
    choices: {
      A: "A tree where each node has at most two children",
      B: "A tree where each node has exactly two children",
      C: "A tree where each node has at least two children",
      D: "A tree where each node has at most three children",
    },
    answer: "A",
  },
  {
    question: `What is the output of the following code snippet?
    String s1 = "hello";
    String s2 = new String("hello");
    System.out.println(s1 == s2);
  `,
    choices: {
      A: "true",
      B: "false",
      C: "Compile error",
      D: "Run time error",
    },
    answer: "B",
  },
  {
    question: "What is inheritance in object-oriented programming?,",
    choices: {
      A: "A mechanism for creating new classes that are built upon existing classes",
      B: "A mechanism for creating new objects based on existing objects",
      C: "A mechanism for creating new functions based on existing functions",
      D: "A mechanism for creating new variables based on existing variables",
    },
    answer: "A",
  },
  {
    question: "What is the purpose of an abstract class in Java?,",
    choices: {
      A: "To provide a common interface for subclasses",
      B: "To provide a base implementation for subclasses",
      C: "To enforce the implementation of certain methods in subclasses",
      D: "To prevent the creation of instances of the class",
    },
    answer: "A",
  },
  {
    question: `What is the result of the following code snippet?
    int x = 4;
    int y = (x > 4) ? x++ : ++x;
    System.out.println(y);
  `,
    choices: {
      A: "4",
      B: "5",
      C: "6",
      D: "Compile error",
    },
    answer: "B",
  },
];
