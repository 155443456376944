import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppContext from "./context/app-context";
import Header from "./components/header";
import "./css/App.css";
import HomePage from "./page/homePage";
import { PacmanLoader } from "react-spinners";
import { ToastContainer } from "react-toastify";
import PracticeExam from "./components/practiceExam";
import DiscussionPage from "./page/discussionPage";
import TopicDetail from "./page/topicDetail";
import Login from "./components/Login";
import AuthenticateWrapper from "./components/authenticateWrapper";
import userProfileService from "./services/userProfileService";
import ProfilePage from "./page/profilePage";
import ViewExamHistory from "./components/viewExamHistory";
import mockExamService from "./services/mockExamService";
import { isUserLogined } from "./context/useAuth";
import Redirect from "./components/redirect";

const routesList = [
  {
    path: "/",
    element: <HomePage />, // <AuthenticateWrapper components={<TicTacToe />} />,
  },
  {
    path: "/login",
    element: <AuthenticateWrapper components={<Login />} />,
  },
  {
    path: "/profile",
    element: <AuthenticateWrapper components={<ProfilePage />} />,
  },
  {
    path: "/practice-Exam/:examId",
    element: <PracticeExam />,
  },
  {
    path: "/practice-exam-history/:examId",
    element: <ViewExamHistory />,
  },
  {
    path: "/discussion",
    element: <DiscussionPage />,
  },
  {
    path: "/discussion/:topicId",
    element: <TopicDetail />,
  },
];
function App() {
  const [loading, setLoading] = useState(false);
  const [appRefresh, setAppRefresh] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const [examHistory, setExamHistory] = useState({});
  useEffect(() => {
    userProfileService.getUserProfile({
      callback: ({ userProfile: profile }) => {
        setUserProfile(profile);
      },
    });
  }, [appRefresh]);
  useEffect(() => {
    if (userProfile) {
      const { userId } = userProfile;
      console.log("getting exam history for " + userId);
      mockExamService.getExamHistory({
        userId,
        callback: ({ records = [] }) => {
          const his = {};
          records.forEach((item) => {
            const { examKey, result } = item;
            his[examKey] = result;
          });
          setExamHistory(his);
        },
      });
    } else {
      setExamHistory({});
    }
  }, [userProfile]);
  if (window.location.href.includes("practiceapexam")) {
    return <Redirect />;
  }
  return (
    <div className="App">
      <BrowserRouter>
        <AppContext.Provider
          value={{
            loading,
            setLoading,
            userProfile,
            examHistory,
            setAppRefresh,
          }}
        >
          <>
            {loading && (
              <div className="fadeMe">
                <PacmanLoader
                  color="#36d7b7"
                  loading={loading}
                  className={"spinner"}
                />
              </div>
            )}
            <Header />
            <div className="container">
              {/* <Spinner loading={loading} />{" "} */}
              <Routes>
                {routesList.map((item) => {
                  return (
                    <Route
                      key={item.path}
                      path={item.path}
                      element={item.element}
                    />
                  );
                })}
              </Routes>
            </div>
          </>
        </AppContext.Provider>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
