export const apes1 = [
  {
    question:
      "What is the main impact of plastic pollution on marine ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question: "What is the main impact of overfishing on marine ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question: "What is the primary impact of soil erosion on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased soil fertility",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question:
      "What is the main impact of climate change on freshwater ecosystems?",
    options: [
      "Increased range and abundance of species",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question: "What is the main cause of ozone depletion?",
    options: [
      "Natural processes",
      "Human activities",
      "Industrial activities",
      "All of the above",
    ],
  },
  {
    question: "What is the main impact of acid rain on ecosystems?",
    options: [
      "Increased range and abundance of species",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question:
      "What is the primary impact of the release of toxic chemicals into the environment?",
    options: [
      "Increased range and abundance of species",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question: "What is the main impact of oil spills on marine ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question: "What is the main impact of nitrogen pollution on ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question:
      "What is the main impact of the destruction of wetlands on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased carbon sequestration",
      "Improved ecosystem health",
      "Decreased water quality",
    ],
  },
  {
    question:
      "What is the main impact of the destruction of coral reefs on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question:
      "What is the primary impact of the destruction of mangrove forests on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased carbon sequestration",
      "Improved ecosystem health",
      "Decreased water quality",
    ],
  },
  {
    question:
      "What is the main impact of the destruction of temperate forests on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased carbon sequestration",
      "Improved ecosystem health",
      "Increased water retention",
    ],
  },
  {
    question:
      "What is the main impact of the destruction of tropical rainforests on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased carbon sequestration",
      "Improved ecosystem health",
      "Increased water retention",
    ],
  },
  {
    question: "What is the main impact of deforestation on the climate?",
    options: [
      "Cooling of the planet",
      "Warming of the planet",
      "Stabilization of the climate",
      "None of the above",
    ],
  },
  {
    question:
      "What is the primary impact of the burning of fossil fuels on the climate?",
    options: [
      "Cooling of the planet",
      "Warming of the planet",
      "Stabilization of the climate",
      "None of the above",
    ],
  },
  {
    question:
      "What is the primary impact of the emission of greenhouse gases on the climate?",
    options: [
      "Cooling of the planet",
      "Warming of the planet",
      "Stabilization of the climate",
      "None of the above",
    ],
  },
  {
    question:
      "What is the main impact of ocean acidification on marine ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question: "What is the primary cause of global warming?",
    options: [
      "Increased solar activity",
      "Decreased volcanic activity",
      "Increased greenhouse gas emissions",
      "Decreased ocean currents",
    ],
  },
  {
    question: "What is the main impact of desertification on ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question: "What is the main impact of soil erosion on ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question:
      "What is the main impact of the destruction of peatlands on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased carbon sequestration",
      "Improved ecosystem health",
      "Decreased water quality",
    ],
  },
  {
    question:
      "What is the main impact of the use of fertilizers and pesticides in agriculture on the environment?",
    options: [
      "Improved water quality",
      "Decreased water quality",
      "Improved soil health",
      "Decreased soil health",
    ],
  },
  {
    question:
      "What is the main impact of the introduction of non-native species into an ecosystem?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of native species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question:
      "What is the main impact of the destruction of mangroves on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased carbon sequestration",
      "Improved ecosystem health",
      "Decreased water retention",
    ],
  },
  {
    question: "What is the main impact of deforestation on the environment?",
    options: [
      "Increased biodiversity",
      "Decreased carbon sequestration",
      "Improved ecosystem health",
      "Increased water retention",
    ],
  },
  {
    question: "What is the main impact of urbanization on ecosystems?",
    options: [
      "Increased biodiversity",
      "Decreased range and abundance of species",
      "Improved ecosystem health",
      "Increased carbon sequestration",
    ],
  },
  {
    question:
      "What is the main impact of the emission of particulate matter on the environment?",
    options: [
      "Improved air quality",
      "Decreased air quality",
      "Improved soil health",
      "Decreased soil health",
    ],
  },
  {
    question:
      "What is the main impact of the use of fossil fuels on the environment?",
    options: [
      "Improved water quality",
      "Decreased water quality",
      "Improved air quality",
      "Decreased air quality",
    ],
  },
  {
    question:
      "What is the main impact of the emission of sulfur dioxide on the environment?",
    options: [
      "Improved air quality",
      "Decreased air quality",
      "Improved water quality",
      "Decreased water quality",
    ],
  },
];
