export const apushexam2 = [
  {
    question: "What was the main cause of the American Revolution?",
    options: [
      "The desire of American colonists to gain independence from Britain",
      "The passage of taxes and laws by Britain that the colonists felt were unjust",
      "The influence of Enlightenment ideas about democracy and liberty",
      "All of the above",
    ],
  },
  {
    question: "What was the main reason for the Louisiana Purchase in 1803?",
    options: [
      "The desire of the United States to acquire new territory",
      "The need to secure control of the Mississippi River",
      "The opportunity to purchase a vast amount of land from France at a low cost",
      "All of the above",
    ],
  },
  {
    question: "What was the main outcome of the Women's Suffrage Movement in the late 19th and early 20th centuries?",
    options: [
      "The passage of the 19th Amendment, granting women the right to vote",
      "The growth of the women's rights movement and the broader fight for equality",
      "Increased public attention to issues affecting women",
      "All of the above",
    ],
  },
  {
    question: "What was the main factor that led to the end of the Korean War in 1953?",
    options: [
      "The signing of an armistice agreement between North Korea, South Korea, and the United States",
      "A costly military stalemate that made continued fighting unsustainable",
      "International pressure and diplomatic efforts for a peaceful settlement",
      "All of the above",
    ],
  },
  {
    question: "What was the main effect of the New Deal programs of the 1930s?",
    options: [
      "Increased government spending and intervention in the economy",
      "An expansion of the federal government’s role in American society",
      "Initiatives designed to provide relief, recovery, and reform during the Great Depression",
      "All of the above",
    ],
  },
  {
    question: "What was the main effect of the Civil Rights Act of 1964?",
    options: [
      "The end of legal segregation in public accommodations and employment",
      "The establishment of legal protections against discrimination based on race, color, religion, sex, or national origin",
      "A significant step toward advancing civil rights and paving the way for further reforms",
      "All of the above",
    ],
  },
  {
    question: "What was the main factor that led to the United States' involvement in World War II?",
    options: [
      "The Japanese attack on Pearl Harbor in 1941",
      "The rise of militaristic regimes in Germany, Italy, and Japan",
      "Expansionist policies by these regimes that threatened global stability",
      "All of the above",
    ],
  },
  {
    question: "What was the main goal of the Reagan Administration's foreign policy during the 1980s?",
    options: [
      "Promoting democracy and capitalism globally",
      "Increasing military spending and developing new weapons systems",
      "Challenging Soviet influence around the world",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the Gulf War in 1991?",
    options: [
      "The liberation of Kuwait from Iraqi occupation",
      "The restoration of Kuwait’s government",
      "The demonstration of American military power through a coalition victory",
      "All of the above",
    ],
  },
  {
    question: "What was the main effect of the Mexican–American War (1846–1848)?",
    options: [
      "The acquisition of vast territories including present-day California, Nevada, Utah, Arizona, New Mexico, and parts of other states",
      "The expansion of American territory to the Pacific Ocean",
      "The incorporation of a significant Spanish-speaking population into the United States",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the Civil War (1861–1865)?",
    options: [
      "The preservation of the United States as a unified nation",
      "The abolition of slavery",
      "The strengthening of federal authority over the states",
      "All of the above",
    ],
  },
  {
    question: "What was the main cause of World War I?",
    options: [
      "Imperialism and competition for colonies",
      "Nationalism and the desire for a unified nation-state",
      "Militarism and the glorification of military power",
      "All of the above",
    ],
  },
  {
    question: "What was the main result of the Roaring Twenties?",
    options: [
      "A period of economic prosperity and cultural change",
      "The growth of consumerism and mass production",
      "The rise of new social and cultural movements, including advances in the arts and early civil rights",
      "All of the above",
    ],
  },
  {
    question: "What was the main result of World War II?",
    options: [
      "The defeat of the Axis powers",
      "The emergence of the United States as a superpower and the beginning of the Cold War",
      "The establishment of international organizations like the United Nations",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the Civil Rights Movement of the 1950s and 1960s?",
    options: [
      "The end of segregation and the expansion of voting rights and other civil liberties",
      "The rise of the Black Power movement",
      "The establishment of legal protections through landmark legislation",
      "All of the above",
    ],
  },
  {
    question: "What was the main goal of the American Revolution?",
    options: [
      "To gain independence from British rule",
      "To establish a government based on democratic principles",
      "To create a system that protected individual rights",
      "To spread democratic values internationally",
    ],
  },
  {
    question: "What was the main cause of the American Civil War?",
    options: [
      "Slavery and the debate over its expansion into new territories",
      "Economic and cultural differences between the North and South",
      "Conflicts over states’ rights and constitutional interpretations",
      "A combination of these factors, with slavery as the primary issue",
    ],
  },
  {
    question: "What was the main impact of the Industrial Revolution in the United States?",
    options: [
      "The growth of urbanization and immigration",
      "The expansion of transportation and communication networks",
      "Increased industrial production and economic growth",
      "All of the above",
    ],
  },
  {
    question: "What was the main result of World War I for the United States?",
    options: [
      "The involvement in international affairs and the onset of a new foreign policy era",
      "The growth of American industry and the economic boom of the 1920s",
      "The rejection of the Treaty of Versailles and the League of Nations",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the Civil Rights Movement on American society?",
    options: [
      "The end of segregation and the expansion of civil liberties",
      "The rise of movements advocating for Black empowerment",
      "The strengthening of legal foundations for civil rights through legislation",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the New Deal on American society?",
    options: [
      "The creation of federal programs and agencies to combat the economic crisis",
      "The expansion of the federal government’s role in daily life",
      "Improvements in working conditions and labor rights",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the Cold War on American society?",
    options: [
      "The growth of the military–industrial complex",
      "An expansion of federal power and spending",
      "Increased public concern over communism and national security",
      "All of the above",
    ],
  },
  {
    question: "What was the main result of the Civil War for African Americans?",
    options: [
      "The abolition of slavery and the granting of citizenship and equal rights",
      "The rise of Black political and economic participation during Reconstruction",
      "The adoption of the 13th, 14th, and 15th Amendments",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the American Revolution on the world?",
    options: [
      "The spread of revolutionary ideals that influenced other independence movements",
      "The establishment of a model government based on liberty and democracy",
      "Inspiration for future revolutions and reforms worldwide",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of Westward Expansion on American society?",
    options: [
      "The growth of American industry and economic development",
      "The forced displacement and decline of Native American populations",
      "The expansion of territory and the fulfillment of manifest destiny",
      "All of the above",
    ],
  },
  {
    question: "What was the main goal of the American colonial movement?",
    options: [
      "To seek greater economic opportunity and freedom from British rule",
      "To spread Christianity and European cultural values",
      "To establish new territories for settlement and trade",
      "All of the above",
    ],
  },
  {
    question: "What was the main impact of the American Revolution on American society?",
    options: [
      "The creation of a government based on democratic principles and individual rights",
      "The emergence of the United States as an independent nation and growing power",
      "The expansion of commerce and territorial growth",
      "All of the above",
    ],
  },
];
