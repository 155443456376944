import { generateRandomString } from "@aws-amplify/core";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import { useEffect, useState } from "react";
import ExamNotesMetadataDB from "../storage/ExamNotesMetadataDB";

export default function MultipleChoiceQuestion(props) {
  const { question = "", options = [], answer, selectedIndex, examId, selectedKey, correctAnswer, onChange } = props;

  const [noteItem, setNoteItem] = useState();
  const [note, noteChange] = useState("");

  const loadNotes = async () => {
    const items = await ExamNotesMetadataDB.getNotesByExamId(`${examId}_${selectedIndex}`);
    if (items && items.length > 0) {
      setNoteItem(items[0]);
      noteChange(items[0].noteContents);
    }
  };
  useEffect(() => {
    loadNotes();
  }, []);
  return (
    <Form style={{ margin: "30px 0" }}>
      <Form.Group className="mb-3" controlId="question">
        <Form.Control as="textarea" rows={question.split("\n").length} value={question} disabled={true} />
      </Form.Group>
      <div style={{ marginLeft: "20px" }}>
        {!correctAnswer ? undefined : correctAnswer !== selectedKey ? (
          <Alert variant={"danger"}>You got this question wrong.</Alert>
        ) : (
          <Alert variant={"success"}>You got this question right.</Alert>
        )}
        <div style={{ display: "flex", flexWrap: "nowrap", gap: "16px" }}>
          <div style={{ flex: "0 1 auto" }}>
            {options.map((choice, index) => {
              return (
                <div
                  onClick={() => {
                    onChange && onChange(choice);
                  }}
                >
                  <Form.Check
                    key={generateRandomString()}
                    type={"radio"}
                    label={`(${String.fromCharCode(65 + index)}) ${choice}`}
                    checked={choice === selectedKey}
                    disabled={correctAnswer}
                    onChange={(e) => {
                      //   console.log(key);
                      onChange && onChange(choice);
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div style={{ flex: "1 1 auto" }}>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter your notes here."
              style={{ width: "100%" }}
              value={note}
              onChange={(e) => {
                const noteContents = e.target.value;
                noteChange(noteContents);
              }}
              onBlur={() => {
                if (note !== noteItem?.noteContents) {
                  let newNoteItem = {};

                  if (!noteItem) {
                    newNoteItem = {
                      id: generateRandomString(),
                      noteContents: note,
                      examId,
                      examIdIndex: `${examId}_${selectedIndex}`,
                    };
                    setNoteItem(newNoteItem);
                  } else {
                    newNoteItem = { ...noteItem, noteContents: note };
                  }

                  ExamNotesMetadataDB.putNotesItem(newNoteItem);
                }
              }}
            />
          </div>
        </div>
      </div>
    </Form>
  );
}
