import React, { useState, useEffect } from "react";

const countdownStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh", // Center vertically on the screen
  fontSize: "24px", // Adjust the font size as needed
};

const RedirectWithCountdown = () => {
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      clearInterval(countdownInterval);
      // Redirect to the desired URL
      window.location.href = "https://ap.crz97.com/"; // Replace with your desired URL
    }, 3000); // 3 seconds

    return () => {
      clearInterval(countdownInterval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div style={countdownStyle}>
      <h3>Pratices AP exam has moved to https://ap.crz97.com/</h3>
      <p>Redirecting in {countdown}...</p>
      {/* You can also display a message or additional content here */}
    </div>
  );
};

export default RedirectWithCountdown;
