import { Auth } from "aws-amplify";
import axios from "axios";
import { isUserLogined } from "../context/useAuth";
import { getUserJwtToken } from "../utils/helperFunctions";

const MOCK_EXAM_SERVICE_URL = "https://apmockexam.service.crz97.com/";

const client = axios.create({ baseURL: MOCK_EXAM_SERVICE_URL });

const API_PATH = {
  exam: "/exam",
};

async function submitMockExam({ selectedAnswers, examKey, userId, callback }) {
  var user = null;
  try {
    user = await Auth.currentAuthenticatedUser();
  } catch (e) {
    console.log("User didnot login");
    user = null;
  }
  console.log(`submit exam for ${userId} + examKey is ${examKey}`);
  client
    .post(
      API_PATH.exam,
      {
        selectedAnswers,
        userId,
        examKey,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: user !== null ? getUserJwtToken(user) : undefined,
        },
      }
    )
    .then((res) => {
      const {
        data: { correctAnswers, correctCount, correctRate },
      } = res;
      console.log({ correctAnswers, correctCount, correctRate });
      callback({ correctAnswers, correctCount, correctRate });
    });
}

async function getExamHistory({ userId, callback }) {
  if (!(await isUserLogined())) {
    callback({ records: [] });
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  // console.log(`submit exam for ${userId} + examKey is ${examKey}`);
  console.log(user);
  client
    .get(`${API_PATH.exam}/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: user !== null ? getUserJwtToken(user) : undefined,
      },
    })
    .then((res) => {
      const {
        data: { records },
      } = res;
      // console.log(records);
      // callback({ correctAnswers, correctCount, correctRate });
      callback({ records });
    });
}

export default {
  submitMockExam,
  getExamHistory,
};
