export const apstats1 = [
  {
    question: "What is the goal of estimation in statistics?",
    options: [
      "To make inferences about a population based on a sample of data",
      "To make predictions about future observations",
      "To determine the accuracy of a statistical model",
      "To identify the most important predictor variables in a regression model",
    ],
  },
  {
    question: "What is a null hypothesis in hypothesis testing?",
    options: [
      "A statement about a population parameter that is assumed to be true until proven otherwise",
      "A statement about a population parameter that is assumed to be false until proven otherwise",
      "A statement about the relationship between two variables",
      "A statement about the difference between two population means",
    ],
  },
  {
    question: "Which type of chart is used to display the distribution of a set of continuous data?",
    options: ["Bar chart", "Pie chart", "Histogram", "Scatter plot"],
  },
  {
    question:
      "Which statistical method is used to model the relationship between a binary response variable and one or more predictor variables?",
    options: ["Simple linear regression", "Multiple linear regression", "Logistic regression", "Poisson regression"],
  },
  {
    question: "What is the difference between a sample and a population in statistics?",
    options: [
      "A sample is a subset of a population",
      "A population is a subset of a sample",
      "A sample and a population are the same thing",
      "A sample is used to make inferences about a population, while a population is used to make predictions about future observations",
    ],
  },
  {
    question: "What is the difference between a parametric test and a non-parametric test?",
    options: [
      "Parametric tests assume a specific distribution for the population, while non-parametric tests do not make any assumptions about the population distribution",
      "Non-parametric tests assume a specific distribution for the population, while parametric tests do not make any assumptions about the population distribution",
      "Parametric tests are only used for small sample sizes, while non-parametric tests can be used for any sample size",
      "Non-parametric tests are only used for small sample sizes, while parametric tests can be used for any sample size",
    ],
  },
  {
    question: "What is a type I error in hypothesis testing?",
    options: [
      "Rejecting the null hypothesis when it is actually true",
      "Failing to reject the null hypothesis when it is actually false",
      "Rejecting the alternative hypothesis when it is actually true",
      "Failing to reject the alternative hypothesis when it is actually false",
    ],
  },
  {
    question: "What is the difference between a random variable and a population parameter?",
    options: [
      "A random variable is a theoretical concept representing numerical outcomes, while a population parameter is a fixed value describing the population",
      "A population parameter is a value calculated from a sample, while a random variable is a value calculated from the entire population",
      "A random variable and a population parameter are the same thing",
      "A random variable is a value calculated from a sample, while a population parameter is a value calculated from the entire population",
    ],
  },
  {
    question: "What is the difference between a type I error and a type II error in hypothesis testing?",
    options: [
      "A type I error is rejecting the null hypothesis when it is actually true, while a type II error is failing to reject the null hypothesis when it is actually false",
      "A type II error is rejecting the null hypothesis when it is actually true, while a type I error is failing to reject the null hypothesis when it is actually false",
      "A type I error and a type II error are the same thing",
      "A type I error is failing to reject the alternative hypothesis when it is actually true, while a type II error is rejecting the alternative hypothesis when it is actually false",
    ],
  },
  {
    question: "Which statistical method is used to compare the means of two or more groups?",
    options: ["Simple linear regression", "Multiple linear regression", "ANOVA", "Chi-squared test"],
  },
  {
    question: "What is a confidence interval in statistics?",
    options: [
      "A range of values that is likely to contain the true population parameter with a certain level of confidence",
      "A range of values that is guaranteed to contain the true population parameter with a certain level of confidence",
      "The range of values that the sample mean is likely to fall within",
      "The range of values that the sample mean is guaranteed to fall within",
    ],
  },
  {
    question: "What is the purpose of the p-value in hypothesis testing?",
    options: [
      "To determine the likelihood of observing a sample statistic as extreme as the one obtained, assuming the null hypothesis is true",
      "To determine the likelihood of observing a sample statistic as extreme as the one obtained, assuming the alternative hypothesis is true",
      "To determine the likelihood of observing a sample statistic if either hypothesis is true",
      "To determine which hypothesis is more likely to be true",
    ],
  },
  {
    question: "What is the difference between correlation and causality?",
    options: [
      "Correlation refers to a relationship between two variables, while causality implies that a change in one variable directly causes a change in the other",
      "Causality refers to a relationship between two variables, while correlation refers to a direct cause-and-effect relationship",
      "Correlation and causality are the same thing",
      "Causality refers to a relationship in which two variables are both related to a third variable",
    ],
  },
  {
    question: "What is the central limit theorem in statistics?",
    options: [
      "The theorem that states that the sampling distribution of the sample mean approaches a normal distribution as the sample size increases, regardless of the population distribution",
      "The theorem that states that the distribution of a single random variable approaches a normal distribution as the sample size increases",
      "The theorem that states that the distribution of the difference between two random variables approaches a normal distribution",
      "The theorem that states that the distribution of a random variable is always normal",
    ],
  },
  {
    question: "What is the purpose of simple linear regression?",
    options: [
      "To model the linear relationship between a dependent variable and one independent variable",
      "To model the linear relationship between a dependent variable and multiple independent variables",
      "To model the nonlinear relationship between variables",
      "To compare the means of two groups",
    ],
  },
  {
    question: "What is a type II error in hypothesis testing?",
    options: [
      "Rejecting the null hypothesis when it is actually true",
      "Failing to reject the null hypothesis when it is actually false",
      "Rejecting the alternative hypothesis when it is actually true",
      "Failing to reject the alternative hypothesis when it is actually false",
    ],
  },
  {
    question: "What is the difference between parametric and nonparametric tests?",
    options: [
      "Parametric tests assume the data follows a specific distribution (e.g., normality), while nonparametric tests do not",
      "Nonparametric tests assume the data follows a specific distribution, while parametric tests do not",
      "Parametric and nonparametric tests are the same thing",
      "Parametric tests are only used for categorical data",
    ],
  },
  {
    question: "What is the purpose of the chi-square test for independence?",
    options: [
      "To determine if there is an association between two categorical variables",
      "To determine if there is a linear relationship between two continuous variables",
      "To compare the means of two populations",
      "To test the normality of a dataset",
    ],
  },
  {
    question: "What is the null hypothesis in hypothesis testing?",
    options: [
      "The hypothesis that there is no effect or no difference, assumed true until evidence suggests otherwise",
      "The hypothesis that there is an effect or difference",
      "The hypothesis that is always rejected",
      "The hypothesis that depends on the sample data",
    ],
  },
  {
    question: "What is the difference between a one-tailed and two-tailed test?",
    options: [
      "A one-tailed test examines an effect in one direction, while a two-tailed test examines effects in both directions",
      "A two-tailed test examines an effect in one direction, while a one-tailed test examines effects in both directions",
      "They are identical in purpose and interpretation",
      "A one-tailed test is used for categorical data, while a two-tailed test is used for numerical data",
    ],
  },
  {
    question: "What is regression analysis used for in statistics?",
    options: [
      "To model and analyze relationships between variables",
      "To test for differences between group means",
      "To summarize categorical data",
      "To calculate probabilities of events",
    ],
  },
  {
    question: "What is the purpose of a confidence interval in statistics?",
    options: [
      "To estimate a population parameter with a range of plausible values",
      "To determine the exact value of a population parameter",
      "To test the significance of a hypothesis",
      "To describe the variability in a sample",
    ],
  },
  {
    question: "What is Bayes' Theorem in statistics?",
    options: [
      "A formula to update the probability of a hypothesis based on prior knowledge and new evidence",
      "A test to compare variances between groups",
      "A method for calculating the mean of a population",
      "A type of confidence interval",
    ],
  },
  {
    question: "What is the difference between a parametric and nonparametric test?",
    options: [
      "Parametric tests require assumptions about the population distribution, while nonparametric tests do not",
      "Nonparametric tests require assumptions about the population distribution, while parametric tests do not",
      "Parametric tests are only used for small samples",
      "Nonparametric tests are always more accurate",
    ],
  },
  {
    question: "What is the difference between a Type I and Type II error in hypothesis testing?",
    options: [
      "Type I error: Rejecting a true null hypothesis. Type II error: Failing to reject a false null hypothesis.",
      "Type I error: Failing to reject a false null hypothesis. Type II error: Rejecting a true null hypothesis.",
      "Type I and Type II errors are identical",
      "Type I error: Incorrectly accepting the alternative. Type II error: Incorrectly rejecting the null.",
    ],
  },
];
