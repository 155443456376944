export const apPsycho1 = [
  {
    question:
      "Which of the following best describes the nature vs. nurture debate?",
    options: [
      "Whether personality is determined by biology or environment.",
      "Whether behavior is shaped by genetics or experience.",
      "Whether intelligence is innate or learned.",
      "All of the above.",
    ],
  },
  {
    question: "What is the definition of classical conditioning?",
    options: [
      "Learning through rewards and punishments.",
      "Learning by observing others.",
      "Learning by forming associations between stimuli.",
      "Learning by organizing information into categories.",
    ],
  },
  {
    question: "What is the primary function of the amygdala?",
    options: [
      "Processing visual information.",
      "Regulating emotions.",
      "Storing long-term memories.",
      "Controlling movement.",
    ],
  },
  {
    question: "Which of the following is an example of a cognitive bias?",
    options: [
      "Confirmation bias.",
      "Hindsight bias.",
      "Self-serving bias.",
      "All of the above.",
    ],
  },
  {
    question:
      "What is the difference between a correlational study and an experiment?",
    options: [
      "Correlational studies measure the cause-and-effect relationship between variables, while experiments do not.",
      "Experiments manipulate an independent variable, while correlational studies do not.",
      "Correlational studies are conducted in a laboratory, while experiments are conducted in the real world.",
      "Experiments rely on observational data, while correlational studies rely on self-reported data.",
    ],
  },
  {
    question: "What is the definition of the id according to Freudian theory?",
    options: [
      "The part of the personality that mediates between the demands of reality, the superego, and the id.",
      "The part of the personality that operates on the pleasure principle and seeks immediate gratification.",
      "The part of the personality that represents the internalized ideals and values of society.",
      "The part of the personality that contains repressed memories and unconscious thoughts.",
    ],
  },
  {
    question:
      "What is the primary difference between a cross-sectional study and a longitudinal study?",
    options: [
      "Cross-sectional studies follow the same group of participants over a long period of time, while longitudinal studies involve comparing different groups of participants at different points in time.",
      "Cross-sectional studies involve comparing different groups of participants at one point in time, while longitudinal studies follow the same group of participants over a long period of time.",
      "Cross-sectional studies are conducted in a laboratory, while longitudinal studies are conducted in the real world.",
      "Cross-sectional studies rely on observational data, while longitudinal studies rely on self-reported data.",
    ],
  },
  {
    question:
      "Which of the following is an example of a negative reinforcement?",
    options: [
      "Taking away a toy when a child misbehaves.",
      "Giving a treat to a dog when it sits on command.",
      "Receiving praise from a boss for good work.",
      "Stopping a loud noise by putting on noise-cancelling headphones.",
    ],
  },
  {
    question: "What is the definition of the bystander effect?",
    options: [
      "The tendency for people to comply with social norms, even when those norms go against their beliefs or values.",
      "The tendency for people to be more likely to help when they are the only person present than when others are also present.",
      "The tendency for people to reduce effort when working in a group compared to when working alone.",
      "The tendency for people to be less likely to help when others are present and able to help.",
    ],
  },
  {
    question: "What is the definition of the encoding specificity principle?",
    options: [
      "The idea that retrieval is most effective when it occurs in the same context as encoding.",
      "The idea that memories are influenced by the context in which they are formed.",
      "The idea that memories are more likely to be formed if the information is rehearsed repeatedly.",
      "The idea that memories are more likely to be formed if the information is emotionally significant.",
    ],
  },
  {
    question: "What is the definition of the availability heuristic?",
    options: [
      "The tendency to overestimate the likelihood of events based on their ease of recall.",
      "The tendency to give more weight to recent events than to past events.",
      "The tendency to judge the likelihood of an event based on how well it fits with a prototype.",
      "The tendency to seek out information that confirms one's pre-existing beliefs.",
    ],
  },
  {
    question: "What is the definition of the mere-exposure effect?",
    options: [
      "The tendency for people to like things more as they are exposed to them more often.",
      "The tendency for people to conform to the expectations of a group, even when those expectations go against their own beliefs or values.",
      "The tendency for people to attribute their own behavior to external factors and others' behavior to internal factors.",
      "The tendency for people to rely on mental shortcuts when making decisions.",
    ],
  },
  {
    question: "What is the definition of the James-Lange theory of emotion?",
    options: [
      "Emotions are the result of physiological arousal.",
      "Physiological arousal and emotional experience occur simultaneously.",
      "Emotions are a cognitive interpretation of physiological arousal.",
      "Emotions are learned through classical conditioning.",
    ],
  },
  {
    question:
      "What is the primary difference between a primary reinforcer and a secondary reinforcer?",
    options: [
      "Primary reinforcers are biologically important, while secondary reinforcers are learned.",
      "Primary reinforcers are more effective than secondary reinforcers.",
      "Primary reinforcers are associated with positive feelings, while secondary reinforcers are associated with negative feelings.",
      "Primary reinforcers are tangible objects, while secondary reinforcers are abstract concepts.",
    ],
  },
  {
    question: "What is the definition of the hindsight bias?",
    options: [
      "The tendency to overestimate the accuracy of one's beliefs and judgments based on how well they fit with pre-existing beliefs and expectations.",
      "The tendency to give more weight to recent events than to past events.",
      "The tendency for people to judge the likelihood of an event based on how well it fits with a prototype.",
      "The tendency for people to remember events as having been more predictable than they actually were.",
    ],
  },
  {
    question: "What is the definition of the just-world hypothesis?",
    options: [
      "The belief that people get what they deserve in life.",
      "The belief that mental disorders are caused by unresolved unconscious conflicts.",
      "The belief that people are naturally inclined to imitate the behaviors of others.",
      "The belief that people's behavior is shaped by the reinforcement and punishment they receive.",
    ],
  },
  {
    question: "What is the definition of the overjustification effect?",
    options: [
      "The tendency for people to perform better when they are being observed.",
      "The tendency for people to underestimate the extent to which others share their opinions, beliefs, and values.",
      "The tendency for people to become less motivated to do something when they are rewarded for it.",
      "The tendency for people to become more motivated to do something when they are rewarded for it.",
    ],
  },
  {
    question: "What is the definition of the self-serving bias?",
    options: [
      "The tendency to judge the likelihood of an event based on how well it fits with a prototype.",
      "The tendency for people to give more weight to recent events than to past events.",
      "The tendency for people to attribute their own successes to internal factors and their failures to external factors.",
      "The tendency for people to overestimate their ability to control events.",
    ],
  },
  {
    question: "What is the definition of a schema?",
    options: [
      "A mental framework or structure that helps organize and interpret information.",
      "A measure of intelligence that compares a person's mental age to their chronological age.",
      "The ability to generate new ideas or solutions to problems.",
      "The process of learning through observation and imitation of others' behaviors.",
    ],
  },
  {
    question: "What is the definition of cognitive dissonance?",
    options: [
      "The tendency for people to conform to the expectations of a group, even when those expectations go against their own beliefs or values.",
      "The feeling of discomfort that arises when there is a discrepancy between two beliefs or between a belief and a behavior.",
      "The tendency for people to be less likely to offer help in an emergency situation when other people are present.",
      "The tendency for people to overestimate the extent to which others share their opinions, beliefs, and values.",
    ],
  },
  {
    question: "What is the definition of social facilitation?",
    options: [
      "The tendency for people to perform better when they are being observed.",
      "The process of learning to avoid or escape from an unpleasant stimulus by making a particular response.",
      "The process of learning associations between behaviors and their consequences.",
      "The process of learning through observation and imitation of others' behaviors.",
    ],
  },
  {
    question: "What is the definition of the Flynn effect?",
    options: [
      "The tendency for people to underestimate the extent to which others share their opinions, beliefs, and values.",
      "The tendency for people's scores on intelligence tests to increase over time.",
      "The tendency for people to become less motivated to do something when they are rewarded for it.",
      "The tendency for people to become more motivated to do something when they are rewarded for it.",
    ],
  },
  {
    question: "What is the definition of operant conditioning?",
    options: [
      "The process of learning through observation and imitation of others' behaviors.",
      "The process of learning to associate a neutral stimulus with a meaningful stimulus.",
      "The process of learning to avoid or escape from an unpleasant stimulus by making a particular response.",
      "The process of learning associations between behaviors and their consequences.",
    ],
  },
  {
    question: "What is the definition of a heuristic?",
    options: [
      "A mental framework or structure that helps organize and interpret information.",
      "A simple thinking strategy that helps solve problems efficiently.",
      "The tendency to judge the likelihood of an event based on how well it fits with a prototype.",
      "The process of learning through observation and imitation of others' behaviors.",
    ],
  },
  {
    question: "What is the definition of the mere exposure effect?",
    options: [
      "The tendency for people to perform better when they are being observed.",
      "The process of learning to avoid or escape from an unpleasant stimulus by making a particular response.",
      "The process of learning associations between behaviors and their consequences.",
      "The tendency for people to develop a preference for things that are familiar to them.",
    ],
  },
  {
    question: "What is the definition of group polarization?",
    options: [
      "The tendency for people to conform to the expectations of a group, even when those expectations go against their own beliefs or values.",
      "The process of learning associations between behaviors and their consequences.",
      "The tendency for people to become more extreme in their beliefs or attitudes after discussing them with a like-minded group.",
      "The tendency for people to become less extreme in their beliefs or attitudes after discussing them with a like-minded group.",
    ],
  },
  {
    question: "What is the definition of the placebo effect?",
    options: [
      "The tendency for people to perform better when they are being observed.",
      "The tendency for people to be more likely to comply with a request if it is preceded by a smaller request.",
      "The tendency for people to experience improvement in symptoms or conditions due to their beliefs or expectations, rather than any specific treatment.",
      "The tendency for people to remember information better if it is presented first in a list.",
    ],
  },
  {
    question: "What is the definition of the serial position effect?",
    options: [
      "The tendency for people to perform better when they are being observed.",
      "The tendency for people to remember information better if it is presented first or last in a list.",
      "The tendency for people to overestimate the extent to which others share their opinions, beliefs, and values.",
      "The tendency for people to become more likely to comply with a request if it is preceded by a smaller request.",
    ],
  },
  {
    question: "What is the definition of the confirmation bias?",
    options: [
      "The tendency for people to become less motivated to do something when they are rewarded for it.",
      "The tendency for people to overestimate the extent to which others share their opinions, beliefs, and values.",
      "The tendency for people to remember information better if it is presented first in a list.",
      "The tendency for people to search for and interpret information in a way that confirms their preexisting beliefs.",
    ],
  },
  {
    question: "What is the definition of the just-world phenomenon?",
    options: [
      "The tendency for people to conform to the expectations of a group, even when those expectations go against their own beliefs or values.",
      "The tendency for people to develop a preference for things that are familiar to them.",
      "The tendency for people to believe that the world is a fair and just place, where people generally get what they deserve.",
      "The tendency for people to overestimate the extent to which others share their opinions, beliefs, and values.",
    ],
  },
  {
    question: "What is the definition of the independent variable?",
    options: [
      "The variable that is manipulated or changed by the experimenter.",
      "The variable that is measured in an experiment.",
      "The experimental group that receives the treatment.",
      "The control group that does not receive the treatment.",
    ],
  },
  {
    question: "What is the definition of the social desirability bias?",
    options: [
      "The tendency for people to develop a preference for things that are familiar to them.",
      "The tendency for people to conform to the expectations of a group, even when those expectations go against their own beliefs or values.",
      "The tendency for people to give socially desirable answers to questions, even if those answers are not accurate.",
      "The tendency for people to remember information better if it is presented last in a list.",
    ],
  },
];
export const apPsycho2 = [
  {
    question: "What is the definition of the fundamental attribution error?",
    options: [
      "The tendency for people to overestimate the extent to which others share their opinions, beliefs, and values.",
      "The tendency for people to attribute their own successes to internal factors and their failures to external factors.",
      "The tendency for people to underestimate the situational factors that influence other people's behavior.",
      "The tendency for people to overemphasize dispositional (internal) explanations for other people's behavior and underemphasize situational (external) explanations.",
    ],
  },
  {
    question: "What is the definition of the conditioned response?",
    options: [
      "The response that is naturally elicited by the unconditioned stimulus.",
      "The stimulus that elicits the unconditioned response naturally and automatically.",
      "The response that is learned through classical conditioning and is elicited by the conditioned stimulus.",
      "The stimulus that is paired with the unconditioned stimulus and eventually elicits the conditioned response.",
    ],
  },
  {
    question: "What is the definition of the dependent variable?",
    options: [
      "The variable that is manipulated or changed by the experimenter.",
      "The variable that is measured in an experiment.",
      "The experimental group that receives the treatment.",
      "The control group that does not receive the treatment.",
    ],
  },
  {
    question: "What is the definition of the cognitive dissonance theory?",
    options: [
      "The theory that people are motivated to seek consistency between their beliefs, attitudes, and behaviors.",
      "The theory that people are more likely to comply with a request if it is preceded by a smaller request.",
      "The theory that people's behavior is influenced by the rewards and punishments that follow it.",
      "The theory that people experience discomfort or psychological tension when they hold two or more conflicting beliefs or attitudes.",
    ],
  },
  {
    question:
      "What is the definition of the cognitive-behavioral therapy (CBT)?",
    options: [
      "A type of therapy that focuses on changing people's irrational or dysfunctional thoughts and beliefs to improve their emotional and behavioral functioning.",
      "A type of therapy that focuses on understanding unconscious conflicts and repressed memories to treat psychological disorders.",
      "A type of therapy that emphasizes the importance of self-actualization and personal growth.",
      "A type of therapy that emphasizes the role of the therapist as a caring and supportive listener.",
    ],
  },
  {
    question: "What is the definition of the social learning theory?",
    options: [
      "The theory that people learn by observing and imitating the behavior of others.",
      "The theory that people's behavior is determined by the rewards and punishments that follow it.",
      "The theory that people are motivated to seek consistency between their beliefs, attitudes, and behaviors.",
      "The theory that people's behavior is influenced by the situational factors that surround it.",
    ],
  },
  {
    question: "What is the definition of the trait theory of personality?",
    options: [
      "The theory that personality consists of a set of unique, stable, and enduring traits or characteristics.",
      "The theory that personality is shaped by unconscious conflicts and early childhood experiences.",
      "The theory that personality is the result of the interaction between the individual and the environment.",
      "The theory that personality is the result of the rewards and punishments that follow behavior.",
    ],
  },
  {
    question: "What is the definition of the Yerkes-Dodson law?",
    options: [
      "The principle that performance increases with arousal up to an optimal point, after which it decreases with increasing arousal.",
      "The principle that the level of motivation needed to achieve a task depends on the difficulty and complexity of the task.",
      "The principle that behavior is determined by the cognitive and affective interpretations of a situation.",
      "The principle that the way people think about and interpret the events in their lives determines their emotional reactions to them.",
    ],
  },
  {
    question: "What is the definition of the social identity theory?",
    options: [
      "The theory that people's behavior is shaped by the rewards and punishments that follow it.",
      "The theory that people are motivated to seek consistency between their beliefs, attitudes, and behaviors.",
      "The theory that people's behavior is influenced by the situational factors that surround it.",
      "The theory that people define themselves in terms of their group memberships and strive for positive distinctiveness and self-esteem.",
    ],
  },
  {
    question: "What is the definition of the circadian rhythm?",
    options: [
      "The natural 24-hour cycle of physical and behavioral processes that governs our sleep-wake cycle, body temperature, and hormone secretion.",
      "The body's automatic response to stress, involving increased heart rate, blood pressure, and adrenaline secretion.",
      "The state of deep relaxation and focused attention achieved through the practice of meditation or hypnosis.",
      "The technique of learning to control bodily functions such as heart rate and blood pressure through the use of feedback from electronic instruments.",
    ],
  },
  {
    question:
      "Which of the following neurotransmitters is responsible for the control of voluntary movements?",
    options: ["Dopamine", "Serotonin", "Acetylcholine", "GABA"],
  },
  {
    question: "What is the definition of the Hawthorne effect?",
    options: [
      "The tendency for people to conform to the group's norms and beliefs even when they do not agree with them.",
      "The tendency for people to behave differently when they know they are being observed.",
      "The tendency for people to attribute their own behavior to situational factors and other people's behavior to internal factors.",
      "The tendency for people to rely on mental shortcuts or rules of thumb to make judgments and decisions.",
    ],
  },
  {
    question: "What is the definition of the Cannon-Bard theory of emotion?",
    options: [
      "The theory that emotional experience is the result of the cognitive interpretation of bodily reactions to stimuli.",
      "The theory that emotional experience is the result of the simultaneous activation of the physiological response and the emotional experience.",
      "The theory that emotional experience is the result of the appraisal of the relevance and significance of a stimulus to one's goals and well-being.",
      "The theory that emotional experience is the result of the interaction between the autonomic nervous system and the somatic nervous system.",
    ],
  },
  {
    question: "Which of the following statements is true about sleepwalking?",
    options: [
      "It occurs during stage 1 sleep.",
      "It occurs during stage 2 sleep.",
      "It occurs during REM sleep.",
      "It occurs during deep sleep.",
    ],
  },
  {
    question:
      "Which of the following is an example of a fixed-ratio schedule of reinforcement?",
    options: [
      "A slot machine paying out after every five pulls.",
      "A boss giving an employee a bonus after a certain number of tasks are completed.",
      "A restaurant offering a free meal after every ten purchases.",
      "A child receiving a sticker after a random number of good behaviors.",
    ],
  },
  {
    question: "Which of the following is an example of a longitudinal study?",
    options: [
      "A study that compares two groups of participants at the same time.",
      "A study that observes and records behavior in a natural setting.",
      "A study that uses brain imaging to measure brain activity in response to a stimulus.",
      "A study that follows the same group of participants over a period of years.",
    ],
  },
  {
    question:
      "Which of the following is a symptom of major depressive disorder?",
    options: [
      "Hallucinations",
      "Delusions",
      "Mania",
      "Feelings of hopelessness and worthlessness",
    ],
  },
  {
    question:
      "What is the definition of self-actualization according to Maslow's hierarchy of needs?",
    options: [
      "The need for love, belonging, and intimacy",
      "The need for safety and security",
      "The need for esteem and recognition",
      "The need to reach one's full potential and achieve personal growth",
    ],
  },
  {
    question: "Which of the following is an example of a primary reinforcer?",
    options: ["Money", "Praise", "Food", "A good grade"],
  },
  {
    question: "What is the definition of retroactive interference?",
    options: [
      "When old memories interfere with the formation of new memories",
      "When new memories interfere with the retrieval of old memories",
      "When we forget information because we never encoded it in the first place",
      "When we forget information because it has been replaced with new information",
    ],
  },
  {
    question: "What is the definition of an agonist?",
    options: [
      "A drug that blocks the effects of neurotransmitters",
      "A drug that enhances the effects of neurotransmitters",
      "A drug that produces its own neurotransmitters",
      "A drug that inhibits the reuptake of neurotransmitters",
    ],
  },
  {
    question: "Which of the following is a characteristic of REM sleep?",
    options: [
      "Increased heart rate and blood pressure",
      "Slowed brain activity and muscle tone",
      "Vivid and often bizarre dreams",
      "A state of deep relaxation and meditation",
    ],
  },
  {
    question: "Which of the following is a basic taste?",
    options: ["Sour", "Bitter", "Umami", "All of the above"],
  },
  {
    question: "What is the definition of confirmation bias?",
    options: [
      "The tendency to seek out information that supports our pre-existing beliefs",
      "The tendency to ignore information that contradicts our pre-existing beliefs",
      "The tendency to rely too heavily on the first piece of information we receive",
      "The tendency to overestimate the accuracy of our own knowledge",
    ],
  },
  {
    question: "What is the definition of a conditioned response?",
    options: [
      "An automatic, involuntary response to a stimulus",
      "A response that is learned through classical conditioning",
      "A response that is learned through operant conditioning",
      "A response that is innate and does not need to be learned",
    ],
  },
  {
    question: "What is the definition of the absolute threshold?",
    options: [
      "The minimum amount of stimulation needed to detect a stimulus 50% of the time",
      "The minimum amount of stimulation needed to detect a stimulus 100% of the time",
      "The maximum amount of stimulation that can be detected by a sensory system",
      "The amount of time it takes for a sensory system to adapt to a constant stimulus",
    ],
  },
  {
    question:
      'What is the definition of the concept of the "self-fulfilling prophecy"?',
    options: [
      "A belief or expectation that influences our behavior in such a way that it causes the belief to come true",
      "The tendency to attribute our own successes to internal factors and our failures to external factors",
      "The tendency to conform to the majority opinion, even if it goes against our own beliefs",
      "The tendency to exert less effort in a group than when individually accountable",
    ],
  },
  {
    question: 'What is the definition of the concept of "confirmation bias"?',
    options: [
      "The tendency to seek out information that supports our pre-existing beliefs",
      "The tendency to ignore information that contradicts our pre-existing beliefs",
      "The tendency to make judgments based on information that comes to mind most easily",
      "The tendency to attribute our own successes to internal factors and our failures to external factors",
    ],
  },
  {
    question: 'What is the definition of "operant conditioning"?',
    options: [
      "The process by which we learn to associate a neutral stimulus with a meaningful one",
      "The process by which we learn to respond to a stimulus that is consistently paired with a specific outcome",
      "The process by which we learn to associate a behavior with its consequences",
      "The process by which we learn to respond to a previously extinguished stimulus",
    ],
  },
  {
    question:
      'What is the definition of the "reliability" of a psychological test?',
    options: [
      "The extent to which a test measures what it is intended to measure",
      "The extent to which a test produces consistent results over time",
      "The extent to which different raters agree on the scoring of a test",
      "The extent to which a test can predict future outcomes",
    ],
  },
  {
    question: 'What is the definition of "convergent thinking"?',
    options: [
      "The ability to generate multiple solutions to a problem",
      "The ability to use logic and reasoning to solve problems",
      "The ability to think creatively and outside of the box",
      "The ability to identify the single best solution to a problem",
    ],
  },
  {
    question: 'What is the definition of the "primacy effect" in memory?',
    options: [
      "The tendency to better remember information presented at the beginning of a list",
      "The tendency to better remember information presented in the middle of a list",
      "The tendency to better remember information presented at the end of a list",
      "The tendency to better remember emotional or significant events",
    ],
  },
];
export const apPsycho3 = [
  {
    question: 'What is the definition of "amygdala" in the brain?',
    options: [
      "The area of the brain responsible for voluntary movement and coordination",
      "The area of the brain responsible for processing visual information",
      "The area of the brain responsible for processing auditory information",
      "The area of the brain responsible for processing emotions and fear responses",
    ],
  },
  {
    question: 'What is the definition of "neurotransmitter"?',
    options: [
      "A type of nerve cell in the brain",
      "A chemical substance that transmits signals between neurons",
      "A specialized cell in the body that produces hormones",
      "A structure in the brain that receives signals from other neurons",
    ],
  },
  {
    question: 'What is the definition of "schemas" in cognitive psychology?',
    options: [
      "Mental structures that organize our knowledge about the world",
      "The set of beliefs and attitudes that we have about a particular group of people",
      "The ability to generate multiple solutions to a problem",
      "The process of breaking down complex information into smaller, more manageable pieces",
    ],
  },
  {
    question: 'What is the definition of "hypothesis" in scientific research?',
    options: [
      "A statement that describes the relationship between two variables",
      "The group in an experiment that does not receive the treatment",
      "The group in an experiment that receives the treatment",
      "The process of randomly assigning participants to different groups in an experiment",
    ],
  },
  {
    question: 'What is the definition of "operant conditioning" in psychology?',
    options: [
      "The process of learning through rewards and punishments",
      "The process of learning through observation and imitation",
      "The process of learning through classical conditioning",
      "The process of learning through repetition and practice",
    ],
  },
  {
    question:
      'What is the definition of "repression" in psychoanalytic theory?',
    options: [
      "The conscious process of forgetting information",
      "The unconscious process of forgetting information",
      "The process of transforming unacceptable impulses into socially acceptable actions",
      "The process of projecting one's own unacceptable feelings or thoughts onto someone else",
    ],
  },
  {
    question: 'What is the definition of "peripheral nervous system"?',
    options: [
      "The part of the nervous system responsible for voluntary movement and coordination",
      "The part of the nervous system responsible for processing and interpreting sensory information",
      "The part of the nervous system responsible for processing emotions and regulating bodily functions",
      "The part of the nervous system outside the brain and spinal cord, including nerves and ganglia",
    ],
  },
  {
    question: 'What is the definition of "conformity" in social psychology?',
    options: [
      "The tendency to change one's behavior or attitudes to match the group norm",
      "The tendency to conform to authority figures and obey their orders",
      "The tendency to form relationships with people who are similar to oneself",
      "The tendency to attribute one's own behavior to external factors and others' behavior to internal factors",
    ],
  },
  {
    question:
      'What is the definition of "procedural memory" in long-term memory?',
    options: [
      "The memory of specific events or episodes in one's life",
      "The memory of general knowledge and facts",
      "The memory of how to perform tasks or skills",
      "The memory of one's own skills and abilities",
    ],
  },
  {
    question: 'What is the definition of "proactive interference" in memory?',
    options: [
      "When old information interferes with new information",
      "When new information interferes with old information",
      "When information is forgotten over time",
      "When information is encoded into long-term memory",
    ],
  },
  {
    question: 'What is the definition of "prejudice" in social psychology?',
    options: [
      "A negative attitude or stereotype towards a group of people",
      "A behavior that is intended to harm or injure someone",
      "A psychological disorder characterized by obsessions and compulsions",
      "An abnormal increase in activity in the brain",
    ],
  },
  {
    question:
      'What is the definition of "transduction" in sensation and perception?',
    options: [
      "The process of transforming physical stimuli into neural signals",
      "The process of interpreting sensory information in the brain",
      "The process of adapting to sensory input over time",
      "The process of focusing on a specific aspect of sensory information",
    ],
  },
  {
    question: 'What is the definition of "validity" in psychological research?',
    options: [
      "The degree to which a measure assesses what it is intended to measure",
      "The consistency or stability of a measure over time",
      "The degree to which a study can be generalized to other populations or situations",
      "The ability of a study to show cause-and-effect relationships",
    ],
  },
  {
    question: 'What is the definition of "dopamine" in the brain?',
    options: [
      "A neurotransmitter that is involved in movement and reward",
      "A hormone that is involved in stress and arousal",
      "A neurotransmitter that is involved in learning and memory",
      "A hormone that is involved in sleep and wakefulness",
    ],
  },
  {
    question: 'What is the definition of "confabulation" in memory?',
    options: [
      "The unintentional creation of false memories",
      "The intentional manipulation of memories",
      "The process of consolidating memories into long-term storage",
      "The process of recalling sensory information",
    ],
  },
  {
    question: 'What is the definition of "classical conditioning" in learning?',
    options: [
      "A process in which a neutral stimulus becomes associated with a naturally occurring stimulus",
      "A process in which behaviors are shaped by their consequences",
      "A process in which new behaviors are learned by watching others",
      "A process in which existing behaviors are strengthened or weakened by their consequences",
    ],
  },
  {
    question:
      'What is the definition of "observational learning" in social cognitive theory?',
    options: [
      "Learning that occurs by watching others and imitating their behaviors",
      "Learning that occurs by trial-and-error experimentation",
      "Learning that occurs by being directly rewarded or punished for a behavior",
      "Learning that occurs through the pairing of a conditioned stimulus and an unconditioned stimulus",
    ],
  },
  {
    question:
      'What is the definition of "mood disorder" in abnormal psychology?',
    options: [
      "A mental disorder characterized by fluctuations in mood",
      "A mental disorder characterized by delusions and hallucinations",
      "A mental disorder characterized by compulsions and obsessions",
      "A mental disorder characterized by anxiety and fear",
    ],
  },
  {
    question:
      'What is the definition of "pluralistic ignorance" in social psychology?',
    options: [
      "A situation in which individuals privately reject a norm but go along with it publicly",
      "A phenomenon in which individuals conform to a group's norms to gain acceptance or approval",
      "A bias in decision making that occurs when a group seeks consensus instead of considering all options",
      "A phenomenon in which individuals exert less effort when working in a group than when working alone",
    ],
  },
  {
    question:
      'What is the definition of "reinforcement" in operant conditioning?',
    options: [
      "The process of strengthening a behavior by adding a desirable stimulus",
      "The process of weakening a behavior by adding an undesirable stimulus",
      "The process of strengthening a behavior by removing an undesirable stimulus",
      "The process of weakening a behavior by removing a desirable stimulus",
    ],
  },
  {
    question: 'What is the definition of "iconic memory" in sensory memory?',
    options: [
      "A brief visual memory that lasts for a few tenths of a second",
      "A brief auditory memory that lasts for a few seconds",
      "A memory of smells and odors that is highly detailed",
      "A memory of physical sensations that is highly vivid",
    ],
  },
  {
    question: 'What is the definition of "personality" in psychology?',
    options: [
      "A person's unique and enduring pattern of thoughts, feelings, and behaviors",
      "A person's social roles and responsibilities within a group",
      "A person's inherited traits and biological characteristics",
      "A person's cognitive processes and problem-solving strategies",
    ],
  },
  {
    question:
      'What is the definition of "self-efficacy" in social cognitive theory?',
    options: [
      "A person's belief in their ability to succeed in a particular situation",
      "A person's tendency to attribute their successes to internal factors and failures to external factors",
      "A person's tendency to conform to the expectations of a group",
      "A person's level of emotional stability and psychological resilience",
    ],
  },
  {
    question: 'What is the definition of "motivation" in psychology?',
    options: [
      "The set of factors that activate, direct, and sustain behavior toward a goal",
      "The set of physiological responses that occur during an emotional experience",
      "The process of learning by observing others' behavior and the consequences of that behavior",
      "The process of interpreting and making sense of sensory information",
    ],
  },
  {
    question:
      'What is the definition of "cognitive dissonance" in social psychology?',
    options: [
      "A state of discomfort that arises when a person holds two or more conflicting beliefs or values",
      "A type of memory error in which items are remembered out of order",
      "A phenomenon in which new information interferes with old information",
      "A type of learning in which a neutral stimulus becomes associated with a meaningful stimulus",
    ],
  },
  {
    question: 'What is the definition of "intrinsic motivation" in psychology?',
    options: [
      "Motivation that comes from within a person and is driven by internal factors",
      "Motivation that comes from outside a person and is driven by external factors",
      "The process of learning by observing others' behavior and the consequences of that behavior",
      "The process of interpreting and making sense of sensory information",
    ],
  },
  {
    question:
      'What is the definition of "confirmation bias" in cognitive psychology?',
    options: [
      "The tendency to look for and interpret information in a way that confirms one's preexisting beliefs or hypotheses",
      "The tendency to overestimate the frequency of rare events",
      "The tendency to remember items at the beginning of a list more easily than items in the middle or at the end",
      "The tendency to rely too heavily on the first piece of information encountered when making decisions",
    ],
  },
  {
    question:
      'What is the definition of "scapegoat theory" in social psychology?',
    options: [
      "The tendency to blame an out-group for one's own problems or misfortunes",
      "The tendency to conform to the expectations of a group",
      "A state of discomfort that arises when a person holds two or more conflicting beliefs or values",
      "The set of factors that activate, direct, and sustain behavior toward a goal",
    ],
  },
  {
    question:
      'What is the definition of "proactive interference" in memory psychology?',
    options: [
      "When old information interferes with the ability to remember new information",
      "When new information interferes with the ability to remember old information",
      "The tendency to forget information that has not been rehearsed or encoded",
      "The tendency to remember information that was learned first more easily than information that was learned later",
    ],
  },
  {
    question:
      'What is the definition of "sensory adaptation" in sensation and perception psychology?',
    options: [
      "The tendency for sensory receptors to become less responsive to a constant stimulus over time",
      "The process by which the eyes adjust to low levels of light",
      "The tendency for two stimuli that are similar in size and shape to be perceived as belonging together",
      "The process of organizing and interpreting sensory information",
    ],
  },
  {
    question:
      'What is the definition of "classical conditioning" in behavioral psychology?',
    options: [
      "A type of learning in which a neutral stimulus becomes associated with a meaningful stimulus",
      "A type of learning in which behavior is shaped by reinforcement or punishment",
      "The process of learning by observing others' behavior and the consequences of that behavior",
      "The process of forming mental associations between two or more pieces of information",
    ],
  },
  {
    question:
      'What is the definition of "central route processing" in persuasion psychology?',
    options: [
      "The process of evaluating a message based on its content and logic",
      "The process of evaluating a message based on the credibility of the source",
      "The tendency to conform to the expectations of a group",
      "The process of learning by observing others' behavior and the consequences of that behavior",
    ],
  },
];
