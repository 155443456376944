import { useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import topicService from "../services/topicService";
import AppContext from "../context/app-context";
import { Button } from "react-bootstrap";
import { getTimeSinceDate } from "../utils/helperFunctions";
import { isUserLogined } from "../context/useAuth";

export default function TopicDetail() {
  const { topicId } = useParams("topicId");
  const [topicDetails, setTopicDetails] = useState({});
  const [topicComments, setTopicComments] = useState([]);
  const { userProfile, setLoading } = useContext(AppContext);
  const [newComment, setNewComment] = useState("");
  useEffect(() => {
    topicService.getTopicDetails({
      topicId,
      callback: ({ topic, comments }) => {
        setTopicDetails(topic);
        if (comments) {
          setTopicComments(comments);
        }
      },
    });
  }, []);
  const { title = "", description = "", createdBy } = topicDetails;

  return (
    <div>
      {" "}
      <Form>
        <Form.Group className="mb-3" controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" value={title} disabled={true} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={description}
            disabled={true}
          />
        </Form.Group>{" "}
        <div style={{ textAlign: "right" }}>
          <Form.Label>Author: {createdBy}</Form.Label>
        </div>
      </Form>
      <div>
        <Form>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Enter your comment</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
          </Form.Group>{" "}
          <Button
            onClick={() => {
              if (!userProfile) {
                alert(
                  "Sorry, you have to login first before leave a comment, if you already login, please refresh the page."
                );
                return;
              }
              const { userId } = userProfile;
              console.log({ userId, topicId, newComment });
              setLoading(true);
              topicService.createComment({
                topicId,
                comment: newComment,
                createdBy: userId,
                callback: ({ comment }) => {
                  setTopicComments([...topicComments, comment]);
                  setNewComment("");
                  setLoading(false);
                },
              });
            }}
          >
            Post
          </Button>
        </Form>
        <div
          style={{
            margin: "20px 0",
          }}
        >
          <h3>Previous comments</h3>
          <div>
            {topicComments.map((item) => {
              const { comment, createdBy, createdDate } = item;
              const fromNow = getTimeSinceDate(new Date(createdDate));
              return (
                <div key={createdBy + createdDate}>
                  <div>
                    {createdBy}: {comment}
                  </div>
                  <div>{fromNow}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
