export const apushexam4 = [
  {
    question: "What was the primary weakness of the Articles of Confederation?",
    options: [
      "No executive branch to enforce laws",
      "Inability to regulate interstate commerce",
      "Lack of taxation authority",
      "All of the above",
    ],
  },
  {
    question: "What principle was established by the Supreme Court in *Marbury v. Madison* (1803)?",
    options: ["Judicial review", "Separate but equal", "Federal supremacy", "Popular sovereignty"],
  },
  {
    question: "What was the main goal of the Monroe Doctrine (1823)?",
    options: [
      "Prevent European colonization in the Americas",
      "Promote U.S. expansion into Asia",
      "Support Latin American revolutions",
      "Establish trade alliances with Europe",
    ],
  },
  {
    question: "What was the primary cause of the Mexican-American War (1846–1848)?",
    options: [
      "Dispute over the Texas border",
      "U.S. desire for California",
      "Mexican debt to American settlers",
      "British interference in Mexico",
    ],
  },
  {
    question: "What did the Missouri Compromise (1820) achieve?",
    options: [
      "Admitted Missouri as a slave state and Maine as a free state",
      "Banned slavery in all new territories",
      "Repealed the Fugitive Slave Act",
      "Ended the domestic slave trade",
    ],
  },
  {
    question: "What was the main purpose of the Seneca Falls Convention (1848)?",
    options: [
      "Advocate for women's suffrage",
      "Oppose the Mexican-American War",
      "Promote temperance reforms",
      "Support abolitionism",
    ],
  },
  {
    question: "What was the primary effect of the Homestead Act (1862)?",
    options: [
      "Encouraged westward migration",
      "Provided land for railroads",
      "Freed enslaved people in border states",
      "Funded public education",
    ],
  },
  {
    question: "What was a key goal of the Progressive Era (1890–1920)?",
    options: [
      "Combat corruption in government",
      "Expand laissez-faire capitalism",
      "Promote isolationism",
      "Reduce immigration quotas",
    ],
  },
  {
    question: "What triggered the United States' entry into World War I (1917)?",
    options: [
      "Unrestricted submarine warfare by Germany",
      "The Zimmerman Telegram",
      "Sinking of the Lusitania",
      "All of the above",
    ],
  },
  {
    question: "What was the Harlem Renaissance?",
    options: [
      "A cultural movement celebrating African American art and literature",
      "A political campaign for racial integration",
      "An economic boom in Northern cities",
      "A religious revival in the South",
    ],
  },
  {
    question: "What was the main goal of the Dawes Plan (1924)?",
    options: [
      "Stabilize Germany's economy after WWI",
      "Promote Native American assimilation",
      "Limit immigration from Southern Europe",
      "Fund public works projects",
    ],
  },
  {
    question: "What was the significance of the Scopes Trial (1925)?",
    options: [
      "Debated evolution vs. creationism in schools",
      "Challenged Prohibition laws",
      "Exposed political corruption",
      "Tested free speech during wartime",
    ],
  },
  {
    question: "What was the primary purpose of the GI Bill (1944)?",
    options: [
      "Provide benefits to WWII veterans",
      "Fund military infrastructure",
      "Expand nuclear weapons research",
      "Support war bond sales",
    ],
  },
  {
    question: "What was the main goal of the Marshall Plan (1948)?",
    options: [
      "Rebuild Western Europe's economy to counter communism",
      "Contain Soviet expansion in Asia",
      "Establish NATO alliances",
      "Fund the United Nations",
    ],
  },
  {
    question: "What was the outcome of the Korean War (1950–1953)?",
    options: [
      "Stalemate at the 38th parallel",
      "Unification of Korea under democracy",
      "Chinese withdrawal from Korea",
      "Soviet control of South Korea",
    ],
  },
  {
    question: "What was the significance of the *Brown v. Board of Education* decision (1954)?",
    options: [
      "Declared racial segregation in schools unconstitutional",
      "Upheld 'separate but equal' doctrine",
      "Banned interracial marriage",
      "Expanded voting rights",
    ],
  },
  {
    question: "What was the main purpose of the Freedom Rides (1961)?",
    options: [
      "Challenge segregation in interstate buses",
      "Register African American voters",
      "Protest the Vietnam War",
      "Promote urban housing reforms",
    ],
  },
  {
    question: "What was the Tet Offensive (1968) known for?",
    options: [
      "Undermining U.S. public support for the Vietnam War",
      "Securing a South Vietnamese victory",
      "Expanding U.S. military involvement",
      "Triggering the Gulf of Tonkin Resolution",
    ],
  },
  {
    question: "What was the main goal of Reaganomics in the 1980s?",
    options: [
      "Reduce taxes and government regulation",
      "Expand social welfare programs",
      "Increase federal spending on education",
      "Promote environmental protections",
    ],
  },
  {
    question: "What was the Iran-Contra Affair (1985–1987) primarily about?",
    options: [
      "Illegal arms sales to Iran to fund Contra rebels",
      "A diplomatic crisis over oil embargos",
      "Election interference by foreign governments",
      "Nuclear weapons negotiations",
    ],
  },
  {
    question: "What was the main focus of Bill Clinton's domestic policy?",
    options: [
      "Economic growth and deficit reduction",
      "Universal healthcare reform",
      "Expanding the military-industrial complex",
      "Environmental deregulation",
    ],
  },
  {
    question: "What triggered the U.S. invasion of Afghanistan in 2001?",
    options: [
      "The 9/11 terrorist attacks",
      "Soviet expansion into the Middle East",
      "Afghanistan's nuclear program",
      "Oil disputes in the Persian Gulf",
    ],
  },
  {
    question: "What was the significance of the 2010 *Citizens United v. FEC* Supreme Court decision?",
    options: [
      "Allowed unlimited corporate spending in elections",
      "Banned campaign contributions from PACs",
      "Mandated voter ID laws",
      "Overturned Roe v. Wade",
    ],
  },
  {
    question: "What was the primary focus of the #MeToo movement (2017)?",
    options: [
      "Addressing sexual harassment and assault",
      "Promoting LGBTQ+ rights",
      "Fighting climate change",
      "Advocating for gun control",
    ],
  },
  {
    question: "What was a major consequence of the 2020 presidential election?",
    options: [
      "Historic voter turnout",
      "Impeachment of Donald Trump",
      "Expansion of the Electoral College",
      "Dissolution of political parties",
    ],
  },
  {
    question: "What was the main impact of the COVID-19 pandemic on the 2020 U.S. election?",
    options: [
      "Expanded use of mail-in voting",
      "Delayed inauguration ceremonies",
      "Canceled presidential debates",
      "Suspended voter registration",
    ],
  },
  {
    question: "What was the main purpose of the Federalist Papers?",
    options: [
      "Promote ratification of the U.S. Constitution",
      "Oppose British taxation policies",
      "Support states' rights over federal power",
      "Advocate for abolitionism",
    ],
  },
  {
    question: "What was the main effect of the Indian Removal Act (1830)?",
    options: [
      "Forced relocation of Native American tribes",
      "Granted citizenship to Native Americans",
      "Protected tribal lands from settlers",
      "Established reservations in the East",
    ],
  },
  {
    question: "What was the primary goal of the Populist Party in the 1890s?",
    options: [
      "Advocate for farmers and laborers",
      "Promote industrial monopolies",
      "Expand immigration restrictions",
      "Support gold standard policies",
    ],
  },
  {
    question: "What was the main purpose of the Truman Doctrine (1947)?",
    options: [
      "Contain the spread of communism",
      "Rebuild post-war Japan",
      "Establish the United Nations",
      "Promote decolonization in Africa",
    ],
  },
];
