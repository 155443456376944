export const APHumanGeo1 = [
  {
    question: "Which of the following is NOT a type of political boundary?",
    options: [
      "Natural boundary",
      "Geometric boundary",
      "Antecedent boundary",
      "Religious boundary",
    ],
  },
  {
    question:
      "Which of the following countries is NOT a member of the European Union?",
    options: ["Germany", "Switzerland", "Spain", "France"],
  },
  {
    question: "Which of the following is NOT a major world religion?",
    options: ["Islam", "Hinduism", "Buddhism", "Judaism", "Confucianism"],
  },
  {
    question:
      "What term describes the movement of people from rural areas to urban areas?",
    options: ["Urbanization", "Migration", "Emigration", "Immigration"],
  },
  {
    question: "Which of the following is an example of a cultural landscape?",
    options: [
      "The Great Wall of China",
      "The Eiffel Tower",
      "Mount Everest",
      "The Grand Canyon",
    ],
  },
  {
    question: "Which of the following is NOT a type of diffusion?",
    options: [
      "Contagious diffusion",
      "Hierarchical diffusion",
      "Cognitive diffusion",
      "Stimulus diffusion",
    ],
  },
  {
    question:
      "What is the term for the process of one culture adopting elements of another culture?",
    options: [
      "Cultural convergence",
      "Cultural divergence",
      "Cultural assimilation",
      "Cultural homogenization",
    ],
  },
  {
    question:
      "Which of the following is an example of a pull factor in migration?",
    options: [
      "Poor economic opportunities",
      "Political persecution",
      "Family ties",
      "Lack of educational opportunities",
    ],
  },
  {
    question:
      "Which of the following regions is NOT a major center of population growth in the world?",
    options: ["North America", "Sub-Saharan Africa", "South Asia", "Europe"],
  },
  {
    question:
      "What is the term for a group of people who share a common language, culture, and history?",
    options: ["Nation", "State", "Nation-state", "Multinational state"],
  },
  {
    question:
      "What is the term for the process of one culture influencing another culture without direct contact?",
    options: [
      "Cultural convergence",
      "Cultural divergence",
      "Cultural appropriation",
      "Cultural diffusion",
    ],
  },
  {
    question:
      "What is the term for the study of the relationship between the physical environment and human activities?",
    options: [
      "Environmental geography",
      "Physical geography",
      "Human geography",
      "Cultural geography",
    ],
  },
  {
    question: "Which of the following is an example of a folk culture?",
    options: ["Jazz music", "Bollywood films", "Blue jeans", "Folk songs"],
  },
  {
    question:
      "What is the term for the spread of a feature or trend from one key person or node of authority to other persons or places?",
    options: [
      "Contagious diffusion",
      "Hierarchical diffusion",
      "Stimulus diffusion",
      "Relocation diffusion",
    ],
  },
  {
    question:
      "Which of the following is NOT a factor that affects population growth?",
    options: [
      "Birth rate",
      "Death rate",
      "Migration",
      "Gross domestic product (GDP)",
    ],
  },
  {
    question:
      "What is the term for the spread of an idea or innovation through a population in a way that the number of those influenced grows continuously larger?",
    options: [
      "Contagious diffusion",
      "Stimulus diffusion",
      "Relocation diffusion",
      "Expansion diffusion",
    ],
  },
  {
    question: "Which of the following is an example of a nation-state?",
    options: ["China", "Russia", "Japan", "United States"],
  },
  {
    question:
      "What is the term for a location where goods are transferred from one type of carrier to another?",
    options: ["Port", "Terminal", "Station", "Depot"],
  },
  {
    question:
      "What is the term for the legal restrictions on immigration, especially in the United States?",
    options: ["Emigration", "Migration", "Alienation", "Immigration laws"],
  },
  {
    question:
      "What is the term for a system of beliefs and practices that attempts to order life in terms of culturally perceived ultimate priorities?",
    options: ["Folk culture", "Popular culture", "Religion", "Language"],
  },
  {
    question:
      "What is the term for the area surrounding a service from which customers are attracted?",
    options: ["Threshold", "Hinterland", "Range", "Market area"],
  },
  {
    question:
      "Which of the following is NOT one of the four major population clusters in the world?",
    options: ["East Asia", "South Asia", "Southeast Asia", "Middle East"],
  },
  {
    question:
      "What is the term for the movement of people, goods, or information between different regions?",
    options: [
      "Interdependence",
      "International trade",
      "Interaction",
      "Interconnection",
    ],
  },
  {
    question:
      "Which of the following is an example of a pull factor for migration?",
    options: ["Poverty", "Famine", "War", "Job opportunities"],
  },
  {
    question:
      "What is the term for the change in a society that follows the introduction of a new idea or technology?",
    options: [
      "Modernization",
      "Globalization",
      "Urbanization",
      "Industrialization",
    ],
  },
  {
    question:
      "Which of the following best describes the concept of cultural relativism?",
    options: [
      "The belief that one's own culture is superior to others",
      "The practice of evaluating another culture based on one's own culture",
      "The recognition that different cultures have different values and beliefs",
      "The imposition of one's culture on another",
    ],
  },
  {
    question:
      "What is the term for the difference in elevation between two points in a landscape?",
    options: ["Topography", "Relief", "Elevation", "Gradient"],
  },
  {
    question:
      "What is the term for the physical, human-made, or cultural characteristics of a place?",
    options: ["Landscape", "Culture", "Terrain", "Environment"],
  },
  {
    question: "Which of the following is an example of a renewable resource?",
    options: ["Oil", "Coal", "Natural gas", "Solar energy"],
  },
  {
    question:
      "What is the term for a type of diffusion that occurs when people who leave their home country adopt some of the cultural traits of their new location?",
    options: [
      "Contagious diffusion",
      "Relocation diffusion",
      "Stimulus diffusion",
      "Hierarchical diffusion",
    ],
  },
  {
    question:
      "What is the term for a system of government in which power is held by a small group of people?",
    options: ["Monarchy", "Oligarchy", "Democracy", "Republic"],
  },
  {
    question:
      "What is the term for the process by which a minority group gradually adopts the customs and attitudes of the dominant culture?",
    options: ["Assimilation", "Multiculturalism", "Pluralism", "Segregation"],
  },
  {
    question:
      "What is the term for the spread of a feature or trend from one place to another through a snowballing process?",
    options: [
      "Contagious diffusion",
      "Hierarchical diffusion",
      "Stimulus diffusion",
      "Relocation diffusion",
    ],
  },
];
export const APHumanGeo2 = [
  {
    question:
      "What is the term for the demographic transition stage in which birth rates begin to decline but death rates remain high?",
    options: ["Stage 1", "Stage 2", "Stage 3", "Stage 4"],
  },
  {
    question:
      "What is the term for the process of changes in settlement patterns and urbanization that result from increasing population, transportation, and other socioeconomic developments?",
    options: [
      "Urbanization",
      "Suburbanization",
      "Rural flight",
      "Counterurbanization",
    ],
  },
  {
    question:
      "What is the term for the process by which a group loses its distinct cultural characteristics through contact with another culture?",
    options: [
      "Acculturation",
      "Assimilation",
      "Cultural diffusion",
      "Ethnic cleansing",
    ],
  },
  {
    question:
      "What is the term for the movement of people from rural areas to urban areas?",
    options: [
      "Urbanization",
      "Suburbanization",
      "Rural flight",
      "Counterurbanization",
    ],
  },
  {
    question: "Which of the following is a characteristic of a primate city?",
    options: [
      "It has the highest population and economic activity in a country",
      "It is a medium-sized city",
      "It is the capital city of a country",
      "It has a low level of economic development",
    ],
  },
  {
    question:
      "What is the term for the movement of people from urban areas to rural areas?",
    options: [
      "Urbanization",
      "Suburbanization",
      "Rural flight",
      "Counterurbanization",
    ],
  },
  {
    question:
      "What is the term for the physical separation of different groups of people in a society?",
    options: ["Assimilation", "Multiculturalism", "Pluralism", "Segregation"],
  },
  {
    question:
      "What is the term for the process by which water, wind, or ice moves soil and rock fragments from one place to another?",
    options: ["Erosion", "Deposition", "Weathering", "Mass wasting"],
  },
  {
    question:
      "What is the term for the spread of a feature or trend from one key person or node of authority or power to other persons or places?",
    options: [
      "Contagious diffusion",
      "Hierarchical diffusion",
      "Stimulus diffusion",
      "Relocation diffusion",
    ],
  },
  {
    question:
      "What is the term for the change in density of a population over a geographic area?",
    options: [
      "Population distribution",
      "Population density",
      "Population growth",
      "Population composition",
    ],
  },
  {
    question:
      "What is the term for the boundary line between two different language regions?",
    options: [
      "Linguistic barrier",
      "Language family",
      "Language frontier",
      "Linguistic transition zone",
    ],
  },
  {
    question:
      "What is the term for the process by which people come to understand the place they live in, the region they reside in, and the larger world?",
    options: [
      "Mental mapping",
      "Spatial cognition",
      "Environmental perception",
      "Territorial awareness",
    ],
  },
  {
    question:
      "What is the term for the spread of an idea or innovation through a population in such a way that the number of those influenced grows continuously larger, resulting in an expanding area of dissemination?",
    options: [
      "Contagious diffusion",
      "Hierarchical diffusion",
      "Stimulus diffusion",
      "Expansion diffusion",
    ],
  },
  {
    question:
      "What is the term for a country that has both a relatively high level of economic development and a relatively high standard of living?",
    options: [
      "Developing country",
      "Least developed country",
      "More developed country",
      "Newly industrializing country",
    ],
  },
  {
    question:
      "What is the term for the development and adoption of a homogeneous culture?",
    options: [
      "Cultural assimilation",
      "Cultural convergence",
      "Cultural divergence",
      "Cultural pluralism",
    ],
  },
  {
    question:
      "What is the term for the boundary that separates two different language groups where a language change is noticeable but not dramatic?",
    options: ["Isogloss", "Dialect", "Creole", "Pidgin"],
  },
  {
    question:
      "What is the term for the division of the world into the more developed countries (MDCs) and the less developed countries (LDCs)?",
    options: [
      "Core-periphery model",
      "Demographic transition model",
      "Rostow's modernization model",
      "Spatial interaction model",
    ],
  },
  {
    question:
      "What is the term for a measure of the number of people living in an area?",
    options: [
      "Population density",
      "Population distribution",
      "Population growth rate",
      "Population composition",
    ],
  },
  {
    question: "Which of the following is an example of a cultural trait?",
    options: ["Christianity", "Buddhism", "English language", "Baseball"],
  },
  {
    question:
      "What is the term for the growth of cities and the migration of people into them?",
    options: ["Urbanization", "Suburbanization", "Gentrification", "Sprawl"],
  },
  {
    question:
      "What is the term for the process by which a language becomes a dead language because it is no longer spoken by any living people?",
    options: [
      "Language family",
      "Language extinction",
      "Language diffusion",
      "Language convergence",
    ],
  },
  {
    question:
      "What is the term for the spread of a feature or trend from one place to another over time?",
    options: [
      "Migration",
      "Relocation diffusion",
      "Diffusion",
      "Hierarchical diffusion",
    ],
  },
  {
    question:
      "What is the term for the number of live births per 1,000 people in a given year?",
    options: [
      "Mortality rate",
      "Infant mortality rate",
      "Fertility rate",
      "Birth rate",
    ],
  },
  {
    question: "Which of the following is an example of a cultural hearth?",
    options: ["Mexico City", "New York City", "Los Angeles", "Mesopotamia"],
  },
  {
    question:
      "What is the term for the deliberate killing of a group of people, especially those of a particular ethnic group or nation?",
    options: ["Genocide", "Ethnic cleansing", "Apartheid", "Segregation"],
  },
  {
    question:
      "Which of the following is NOT a characteristic of a state, according to the Montevideo Convention?",
    options: ["Territory", "Government", "Population", "Culture"],
  },
  {
    question:
      "What is the term for a type of diffusion in which the spread of an idea or innovation occurs through a fixed population, usually along established communication and transportation routes?",
    options: [
      "Contagious diffusion",
      "Stimulus diffusion",
      "Relocation diffusion",
      "Hierarchical diffusion",
    ],
  },
  {
    question:
      "What is the term for the process by which a language becomes simplified over time, often resulting in the loss of grammatical complexity and vocabulary?",
    options: [
      "Language convergence",
      "Language divergence",
      "Language assimilation",
      "Language simplification",
    ],
  },
  {
    question:
      "Which of the following is an example of a push factor in migration?",
    options: [
      "Better job opportunities",
      "Higher wages",
      "Access to education",
      "Political instability",
    ],
  },
  {
    question:
      "What is the term for the phenomenon in which different people perceive the same place in different ways, often based on their own experiences and values?",
    options: [
      "Perception of place",
      "Sense of place",
      "Regional identity",
      "Placelessness",
    ],
  },
  {
    question:
      "What is the term for the process by which a dominant culture completely absorbs a less dominant culture?",
    options: [
      "Acculturation",
      "Assimilation",
      "Cultural appropriation",
      "Multiculturalism",
    ],
  },
  {
    question:
      "What is the term for the physical, visible, and tangible expression of culture, such as buildings, monuments, and art?",
    options: [
      "Cultural landscape",
      "Built environment",
      "Material culture",
      "Nonmaterial culture",
    ],
  },
  {
    question:
      "What is the term for the study of the spatial and temporal patterns of the human population, including their distribution, density, and growth?",
    options: [
      "Demography",
      "Population ecology",
      "Population geography",
      "Human ecology",
    ],
  },
];
export const APHumanGeo3 = [
  {
    question:
      "What is the term for a society characterized by the dominance of urban centers, the rise of commerce and industry, and the shift away from agricultural-based economies?",
    options: [
      "Industrial society",
      "Agricultural society",
      "Postindustrial society",
      "Urban society",
    ],
  },
  {
    question:
      "What is the term for a political boundary that follows a natural feature, such as a river or mountain range?",
    options: [
      "Ethnographic boundary",
      "Geometric boundary",
      "Natural boundary",
      "Superimposed boundary",
    ],
  },
  {
    question:
      "What is the term for the amount of people that can be supported by a given area of land, taking into account available resources and technology?",
    options: [
      "Carrying capacity",
      "Population density",
      "Demographic transition",
      "Food security",
    ],
  },
  {
    question: "Which of the following is an example of a formal region?",
    options: [
      "The Great Plains of the United States",
      "The American Southwest",
      "The Sunni Triangle in Iraq",
      "Latin America",
    ],
  },
  {
    question:
      "What is the term for the set of beliefs, values, and norms that guide how people behave in a society?",
    options: ["Culture", "Society", "Ethnicity", "Race"],
  },
  {
    question:
      "What is the term for the process by which a region's traditional economic activities are replaced by new activities, often in a different sector or industry?",
    options: [
      "Deindustrialization",
      "Modernization",
      "Economic restructuring",
      "Creative destruction",
    ],
  },
  {
    question:
      "What is the term for the process by which different cultures blend to form a new hybrid culture?",
    options: [
      "Acculturation",
      "Assimilation",
      "Cultural convergence",
      "Syncretism",
    ],
  },
  {
    question:
      "What is the term for the area of the earth's surface that is directly affected by a particular set of cultural traits?",
    options: [
      "Cultural hearth",
      "Culture region",
      "Cultural realm",
      "Cultural complex",
    ],
  },
  {
    question:
      "What is the term for the process by which plants and animals are domesticated and selectively bred for specific traits?",
    options: [
      "Horticulture",
      "Agriculture",
      "Agribusiness",
      "Neolithic revolution",
    ],
  },
  {
    question:
      "Which of the following is an example of a centripetal force in a country?",
    options: [
      "Regional economic inequality",
      "Language diversity",
      "Ethnic conflict",
      "National anthem",
    ],
  },
  {
    question:
      "What is the term for the process by which a language spreads and becomes more widely used?",
    options: [
      "Language convergence",
      "Language divergence",
      "Language shift",
      "Language revitalization",
    ],
  },
  {
    question:
      "What is the term for the process by which a culture's customs and beliefs are transmitted from one generation to the next?",
    options: [
      "Cultural diffusion",
      "Cultural adaptation",
      "Cultural transmission",
      "Cultural integration",
    ],
  },
  {
    question: "Which of the following is an example of a functional region?",
    options: [
      "The state of California",
      "The Rocky Mountains",
      "The Great Lakes region",
      "The Amazon rainforest",
    ],
  },
  {
    question:
      "What is the term for the deliberate killing of a group of people based on their ethnicity, religion, or other characteristic?",
    options: ["Genocide", "Ethnic cleansing", "Holocaust", "Apartheid"],
  },
  {
    question:
      "Which of the following is NOT a characteristic of a developed country?",
    options: [
      "High life expectancy",
      "High GDP per capita",
      "High infant mortality rate",
      "High literacy rate",
    ],
  },
  {
    question:
      "What is the term for the spread of a cultural trait from one culture to another?",
    options: [
      "Cultural diffusion",
      "Cultural adaptation",
      "Cultural transmission",
      "Cultural convergence",
    ],
  },
  {
    question:
      "What is the term for a culture's distinctive way of life, including its beliefs, values, and practices?",
    options: [
      "Folk culture",
      "Popular culture",
      "Subculture",
      "Counter culture",
    ],
  },
  {
    question: "Which of the following is an example of a perceptual region?",
    options: [
      "The Sahara Desert",
      "The Rust Belt",
      "The Amazon rainforest",
      "The Rocky Mountains",
    ],
  },
  {
    question:
      "What is the term for the process by which a cultural trait becomes a part of the mainstream culture?",
    options: [
      "Cultural divergence",
      "Cultural convergence",
      "Cultural assimilation",
      "Cultural revitalization",
    ],
  },
  {
    question:
      "What is the term for the study of human populations, including their size, composition, and distribution?",
    options: ["Demography", "Ethnography", "Sociology", "Anthropology"],
  },
  {
    question:
      "What is the term for a group of people with a shared culture or identity, who feel they belong together, often based on a common language or religion?",
    options: [
      "Ethnic group",
      "Racial group",
      "Cultural group",
      "National group",
    ],
  },
  {
    question:
      "What is the term for the deliberate killing of a large group of people, especially those of a particular ethnic group or nation?",
    options: ["Genocide", "Ethnic cleansing", "Civil war", "Terrorism"],
  },
  {
    question:
      "Which of the following is an example of a push factor that might cause someone to emigrate from their home country?",
    options: [
      "Better economic opportunities",
      "Higher standard of living",
      "Family reunification",
      "Political persecution",
    ],
  },
  {
    question:
      "What is the term for the division of a city into different regions or zones according to certain characteristics, such as social class or race?",
    options: ["Gentrification", "Urban renewal", "Redlining", "Zoning"],
  },
  {
    question:
      "What is the term for a group of countries that have joined together for a common purpose, such as economic or political cooperation?",
    options: ["Alliance", "Federation", "Union", "Association"],
  },
  {
    question:
      "Which of the following is an example of a primary economic activity?",
    options: ["Manufacturing", "Retail trade", "Mining", "Finance"],
  },
  {
    question:
      "What is the term for the physical separation of different races into different geographic areas, typically as a result of social or legal discrimination?",
    options: ["Segregation", "Discrimination", "Prejudice", "Stereotyping"],
  },
  {
    question:
      "What is the term for the process of dividing a larger area into smaller voting districts in such a way as to give an advantage to one political party?",
    options: [
      "Gerrymandering",
      "Redistricting",
      "Districting",
      "Reapportionment",
    ],
  },
  {
    question:
      "What is the term for the average number of people per unit of land in a given area?",
    options: [
      "Population density",
      "Population growth rate",
      "Population pyramid",
      "Demographic transition",
    ],
  },
  {
    question: "Which of the following is a major cause of desertification?",
    options: ["Deforestation", "Irrigation", "Urbanization", "Mining"],
  },
  {
    question:
      "What is the term for the use of multiple languages in a society or country?",
    options: [
      "Multilingualism",
      "Bilingualism",
      "Plurilingualism",
      "Monolingualism",
    ],
  },
  {
    question:
      "What is the term for the movement of people from one place to another within the same country?",
    options: [
      "Immigration",
      "Emigration",
      "Internal migration",
      "International migration",
    ],
  },
  {
    question:
      "What is the term for a society's beliefs, values, customs, behaviors, and artifacts that make up a way of life?",
    options: ["Culture", "Ethnicity", "Race", "Language"],
  },
];
export const APHumanGeo4 = [
  {
    question:
      "Which of the following is a characteristic of a command economy?",
    options: [
      "Private property rights are protected",
      "Prices are determined by supply and demand",
      "The government makes all economic decisions",
      "Individuals have freedom to start businesses",
    ],
  },
  {
    question: "Which of the following is a push factor for migration?",
    options: [
      "Job opportunities",
      "Political stability",
      "High quality of life",
      "Natural disasters",
    ],
  },
  {
    question:
      "Which of the following is a type of map that shows the location and boundaries of countries and their subdivisions, such as states or provinces?",
    options: [
      "Physical map",
      "Political map",
      "Thematic map",
      "Topographic map",
    ],
  },
  {
    question:
      "What is the term for a settlement that has grown to incorporate multiple formerly separate towns or cities?",
    options: ["Metropolis", "Megacity", "Conurbation", "Megalopolis"],
  },
  {
    question:
      "Which of the following is a factor that contributes to the development of a country?",
    options: [
      "High levels of corruption",
      "Low levels of education",
      "Political instability",
      "Access to natural resources",
    ],
  },
  {
    question:
      "Which of the following is a demographic factor that can impact a country's economic growth?",
    options: [
      "Age structure",
      "Gender identity",
      "Religious affiliation",
      "Ethnicity",
    ],
  },
  {
    question: "Which of the following is an example of a vernacular region?",
    options: [
      "The United States",
      "The European Union",
      "The Middle East",
      "The American South",
    ],
  },
  {
    question:
      "Which of the following is an example of a push factor for migration?",
    options: [
      "Access to better healthcare",
      "Increased job opportunities",
      "Political stability",
      "Natural disasters",
    ],
  },
  {
    question:
      "Which of the following best describes the concept of cultural diffusion?",
    options: [
      "The process by which a culture is transformed by interactions with another culture",
      "The process by which a culture spreads from one area to another",
      "The process by which a culture is eradicated by another culture",
      "The process by which a culture becomes self-sufficient",
    ],
  },
  {
    question:
      "Which of the following is an example of a nonrenewable resource?",
    options: ["Wind", "Water", "Natural gas", "Solar energy"],
  },
  {
    question:
      "Which of the following is an example of a centripetal force that unifies a country?",
    options: [
      "Political division",
      "Religious diversity",
      "Language differences",
      "National identity",
    ],
  },
  {
    question: "Which of the following is an example of a stateless nation?",
    options: [
      "The Kurds",
      "The United States",
      "The United Kingdom",
      "The Maori",
    ],
  },
  {
    question:
      "Which of the following best describes the demographic transition model?",
    options: [
      "A model that explains the process of urbanization in developing countries",
      "A model that explains the changing patterns of food consumption in different parts of the world",
      "A model that explains the changes in birth and death rates over time in a country",
      "A model that explains the spread of diseases across continents",
    ],
  },
  {
    question: "Which of the following is a push factor of migration?",
    options: [
      "Job opportunities",
      "Access to education",
      "Political stability",
      "Natural disasters",
    ],
  },
  {
    question: "Which of the following is an example of a megacity?",
    options: ["Toronto", "Shanghai", "Copenhagen", "Brisbane"],
  },
  {
    question:
      "Which of the following best describes the concept of environmental determinism?",
    options: [
      "The physical environment determines human behavior and culture",
      "Human culture determines the physical environment",
      "Human behavior and culture are independent of the physical environment",
      "The physical environment has no effect on human behavior or culture",
    ],
  },
  {
    question:
      "Which of the following is an example of a transnational corporation?",
    options: ["Amazon", "Walmart", "Coca-Cola", "All of the above"],
  },
  {
    question: "Which of the following is an example of an ethnic religion?",
    options: ["Christianity", "Hinduism", "Buddhism", "Islam"],
  },
  {
    question:
      "What is the term used to describe the process by which a cultural trait spreads from one place to another?",
    options: ["Acculturation", "Assimilation", "Diffusion", "Syncretism"],
  },
  {
    question:
      "Which of the following is an example of a country with a command economy?",
    options: ["United States", "Canada", "China", "France"],
  },
  {
    question:
      "Which of the following is a way that governments can promote economic development?",
    options: [
      "Providing tax breaks to large corporations",
      "Reducing spending on infrastructure",
      "Increasing trade barriers",
      "Investing in education and healthcare",
    ],
  },
  {
    question:
      "Which of the following factors contribute to a country's Human Development Index (HDI)?",
    options: [
      "Per capita income, life expectancy, and education",
      "Population, land area, and natural resources",
      "GDP, inflation rate, and interest rates",
      "Political stability, military strength, and foreign policy",
    ],
  },
  {
    question:
      "Which of the following is an example of a stage 4 country according to the Demographic Transition Model?",
    options: ["Nigeria", "China", "Mexico", "Sweden"],
  },
  {
    question: "Which of the following is a push factor that causes migration?",
    options: [
      "Employment opportunities",
      "Better healthcare",
      "Political stability",
      "Natural disasters",
    ],
  },
  {
    question:
      "Which of the following is an example of a secondary economic activity?",
    options: ["Mining", "Teaching", "Farming", "Fishing"],
  },
  {
    question: "What is the main difference between a nation and a state?",
    options: [
      "A nation is a group of people with shared cultural identity, while a state is a political entity with sovereignty over a defined territory",
      "A nation is a political entity with sovereignty over a defined territory, while a state is a group of people with shared cultural identity",
      "A nation is a geographic region with defined borders, while a state is a group of people with shared cultural identity",
      "A nation is a group of people with shared religious beliefs, while a state is a political entity with sovereignty over a defined territory",
    ],
  },
  {
    question:
      "Which of the following is a type of boundary that is established after a cultural landscape has developed?",
    options: [
      "Antecedent boundary",
      "Superimposed boundary",
      "Relict boundary",
      "Subsequent boundary",
    ],
  },
  {
    question:
      "Which of the following is a factor that can influence the location of economic activities?",
    options: [
      "Climate and topography",
      "Political stability and foreign policy",
      "Religious beliefs and cultural practices",
      "Population density and migration patterns",
    ],
  },
  {
    question: "Which of the following is NOT a characteristic of a state?",
    options: [
      { option: "Sovereignty" },
      { option: "Territory" },
      { option: "Population" },
      { option: "Political party system" },
      { option: "Government" },
    ],
  },
  {
    question: "Which of the following is NOT a type of migration?",
    options: [
      { option: "Internal migration" },
      { option: "Voluntary migration" },
      { option: "Forced migration" },
      { option: "Involuntary migration" },
      { option: "Political migration" },
    ],
  },
  {
    question:
      "Which of the following countries has the highest population density?",
    options: [
      { option: "United States" },
      { option: "Canada" },
      { option: "Russia" },
      { option: "China" },
      { option: "Bangladesh" },
    ],
  },
  {
    question:
      "Which of the following is a reason for the decline of traditional agriculture?",
    options: [
      { option: "Technology" },
      { option: "Climate change" },
      { option: "Environmental concerns" },
      { option: "All of the above" },
      { option: "None of the above" },
    ],
  },
  {
    question:
      "What is the term for a society that is based on the production and consumption of services and information?",
    options: [
      { option: "Post-industrial society" },
      { option: "Industrial society" },
      { option: "Agricultural society" },
      { option: "Hunting and gathering society" },
      { option: "Feudal society" },
    ],
  },
];
