import React, { useState } from "react";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { useSearchParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useContext } from "react";
import AppContext from "../context/app-context";
import topicService from "../services/topicService";
import { TOPIC_CATEGORY } from "../constants";

export default function CreateTopicModal(props) {
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
  const { showModal, onClose } = props;
  const { userProfile, setLoading } = useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, titleSet] = useState("");
  const [description, descriptionSet] = useState("");
  const [category, categorySet] = useState(searchParams.get("category") || "");
  const [attchements, setAttchements] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const filesUploadCallback = ({ id, value }) => {
    console.log(`${id} uploaded ${value}%`);
    uploadStatus[id] = value;
    console.log(uploadStatus);
    setUploadStatus({ ...uploadStatus });
  };
  return (
    <Modal show={showModal}>
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>{"Create a discussion"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => titleSet(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => descriptionSet(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Select
              value={category}
              onChange={(e) => categorySet(e.target.value)}
            >
              {TOPIC_CATEGORY.map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <div>
            {attchements.map((attchement) => {
              const { name, type } = attchement;
              const status = uploadStatus[name];
              return (
                <div key={name}>
                  <label>{`fileName: ${name}`}</label>
                  <br />
                  {status ? (
                    <ProgressBar now={status} label={`${status}%`} />
                  ) : (
                    "Ready to upload"
                  )}
                </div>
              );
            })}
          </div>
          <div style={{ margin: "10px" }} />
          {/* <FileUploader
            handleChange={(files) => {
              // const { name, type } = files[0];
              setAttchements(Object.keys(files).map((i) => files[i]));
            }}
            multiple={true}
            hoverTitle={"Drop the videos here"}
            types={fileTypes}
          /> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="dark"
          onClick={() => {
            if (!userProfile) {
              alert("Please login first!!");
              return;
            }
            const { userId } = userProfile;
            setLoading(true);
            console.log({ title, description, attchements, createdBy: userId });
            topicService.createDiscusionTopic({
              title,
              description,
              files: attchements,
              createdBy: userId,
              category,
              filesUploadCallback,
              callback: () => {
                setAttchements([]);
                titleSet("");
                descriptionSet("");
                categorySet("");
                onClose();
                setLoading(false);
              },
            });
          }}
        >
          {"Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
