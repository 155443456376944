import { useState } from "react";
import { useContext } from "react";
import { Button, Form } from "react-bootstrap";
import AppContext from "../context/app-context";
import userProfileService from "../services/userProfileService";

export default function ProfilePage(props) {
  const { userProfile, setLoading } = useContext(AppContext);
  const { displayName = "", emailAddress = "", userId } = userProfile || {};
  const [updateName, setUpdateName] = useState(displayName);
  const [updateProfile, setUpdateProfile] = useState(false);

  return (
    <div>
      <h3>Welcome {displayName}!! </h3>
      {/* <h4>Email: {emailAddress}</h4> */}
      <Form>
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Update profile"
          value={updateProfile}
          onChange={() => {
            setUpdateName(displayName);
            setUpdateProfile(!updateProfile);
          }}
        />
        {updateProfile && (
          <div style={{ margin: "20px" }}>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>update displayName</Form.Label>
              <Form.Control
                type="text"
                value={updateName}
                onChange={(e) => setUpdateName(e.target.value)}
              />
            </Form.Group>
            <Button
              onClick={() => {
                setLoading(true);
                userProfileService.updateUserProfile({
                  displayName: updateName,
                  userId,
                  callback: () => {
                    setLoading(false);
                  },
                });
              }}
            >
              Update
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}
