export const ApPhysics1and21 = [
  {
    question: "What is the difference between mass and weight?",
    options: [
      "Mass is a measure of the amount of matter in an object, while weight is a measure of the force of gravity acting on an object.",
      "Weight is a measure of the amount of matter in an object, while mass is a measure of the force of gravity acting on an object.",
      "Mass and weight are the same thing.",
      "Mass and weight are unrelated.",
      "None of the above.",
    ],
  },
  {
    question: "What is the difference between speed and velocity?",
    options: [
      "Speed is a scalar quantity that measures how fast an object is moving, while velocity is a vector quantity that measures both the object's speed and direction.",
      "Velocity is a scalar quantity that measures how fast an object is moving, while speed is a vector quantity that measures both the object's speed and direction.",
      "Speed and velocity are the same thing.",
      "Speed and velocity are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A ball is thrown straight up from the ground with an initial velocity of 20 m/s. Neglecting air resistance, what is the maximum height the ball reaches?",
    options: ["10 m", "20 m", "40 m", "80 m", "160 m"],
  },
  {
    question:
      "A 500 g cart moving at a velocity of 10 m/s collides with a stationary 500 g cart. What is the final velocity of the carts if they stick together?",
    options: ["2.5 m/s", "5 m/s", "7.5 m/s", "10 m/s", "12.5 m/s"],
  },
  {
    question:
      "A 1 kg object is attached to a string and is swung in a horizontal circle of radius 2 m at a constant speed of 5 m/s. What is the tension in the string?",
    options: ["10 N", "12 N", "14 N", "16 N", "20 N"],
  },
  {
    question:
      "What is the difference between a series circuit and a parallel circuit?",
    options: [
      "In a series circuit, the components are connected end-to-end, so that the current flows through each component in turn, while in a parallel circuit, the components are connected side-by-side, so that the current divides and flows through each component simultaneously.",
      "In a parallel circuit, the components are connected end-to-end, so that the current flows through each component in turn, while in a series circuit, the components are connected side-by-side, so that the current divides and flows through each component simultaneously.",
      "Series and parallel circuits are the same thing.",
      "Series and parallel circuits are unrelated.",
      "None of the above.",
    ],
  },
  {
    question: "What is the difference between work and power?",
    options: [
      "Work is the amount of energy transferred by a force acting through a distance, while power is the rate at which work is done.",
      "Work and power are the same thing.",
      "Work is the rate at which energy is transferred by a force acting through a distance, while power is the amount of work done.",
      "Work and power are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A 10 Ω resistor is connected in series with a 20 Ω resistor and a 30 Ω resistor to a 12 V battery. What is the current flowing through the 10 Ω resistor?",
    options: ["0.4 A", "0.8 A", "1 A", "2 A", "4 A"],
  },
  {
    question:
      "A 5 kg object moving with a velocity of 10 m/s collides with a stationary 2 kg object. After the collision, the 5 kg object moves with a velocity of 6 m/s. What is the velocity of the 2 kg object after the collision?",
    options: ["4 m/s", "6 m/s", "8 m/s", "10 m/s", "12 m/s"],
  },
  {
    question:
      "A 0.1 kg object is moving in a circular path with a radius of 0.2 m at a constant speed of 2 m/s. What is the centripetal force acting on the object?",
    options: ["0.1 N", "0.2 N", "0.4 N", "0.8 N", "1 N"],
  },
  {
    question:
      "A 0.1 kg object attached to a spring with a spring constant of 50 N/m oscillates with an amplitude of 0.2 m. What is the maximum potential energy of the system?",
    options: ["0.002 J", "0.01 J", "0.02 J", "0.04 J", "0.08 J"],
  },
  {
    question: "What is the difference between current and voltage?",
    options: [
      "Current is the flow of electric charge, while voltage is the force that drives the flow of electric charge.",
      "Voltage is the flow of electric charge, while current is the force that drives the flow of electric charge.",
      "Current and voltage are the same thing.",
      "Current and voltage are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "What is the difference between elastic and inelastic collisions?",
    options: [
      "In an elastic collision, kinetic energy is conserved, while in an inelastic collision, kinetic energy is not conserved.",
      "In an inelastic collision, kinetic energy is conserved, while in an elastic collision, kinetic energy is not conserved.",
      "Elastic and inelastic collisions are the same thing.",
      "Elastic and inelastic collisions are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A 10 Ω resistor is connected in series with a 20 Ω resistor and a 30 Ω resistor to a 12 V battery. What is the total resistance of the circuit?",
    options: ["5 Ω", "10 Ω", "20 Ω", "30 Ω", "60 Ω"],
  },
  {
    question:
      "A 10 kg object is moving with a velocity of 20 m/s. What is its kinetic energy?",
    options: ["200 J", "400 J", "800 J", "1600 J", "3200 J"],
  },
  {
    question:
      "A 1000 kg car traveling at a speed of 20 m/s skids to a stop on a road with a coefficient of friction of 0.5. How far does the car travel before coming to a stop?",
    options: ["20 m", "40 m", "60 m", "80 m", "100 m"],
  },
  {
    question: "What is the difference between series and parallel circuits?",
    options: [
      "In a series circuit, the components are connected one after the other, while in a parallel circuit, the components are connected side by side.",
      "In a parallel circuit, the components are connected one after the other, while in a series circuit, the components are connected side by side.",
      "Series and parallel circuits are the same thing.",
      "Series and parallel circuits are unrelated.",
      "None of the above.",
    ],
  },
  {
    question: "What is the Law of Conservation of Energy?",
    options: [
      "Energy cannot be created or destroyed, only transferred or converted from one form to another.",
      "The total energy of a closed system remains constant over time.",
      "Energy is proportional to mass and the square of the speed of light.",
      "None of the above.",
      "All of the above.",
    ],
  },
  {
    question: "What is the difference between velocity and speed?",
    options: [
      "Velocity is a vector quantity that takes into account the direction of motion, while speed is a scalar quantity that only takes into account the magnitude of motion.",
      "Speed is a vector quantity that takes into account the direction of motion, while velocity is a scalar quantity that only takes into account the magnitude of motion.",
      "Velocity and speed are the same thing.",
      "Velocity and speed are unrelated.",
      "None of the above.",
    ],
  },
  {
    question: "What is the difference between a wave and a particle?",
    options: [
      "A wave is a disturbance that travels through a medium, while a particle is a discrete unit of matter.",
      "A particle is a disturbance that travels through a medium, while a wave is a discrete unit of matter.",
      "A wave and a particle are the same thing.",
      "A wave and a particle are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "What is the difference between a convex lens and a concave lens?",
    options: [
      "A convex lens is thicker in the middle than at the edges and converges light, while a concave lens is thinner in the middle than at the edges and diverges light.",
      "A concave lens is thicker in the middle than at the edges and converges light, while a convex lens is thinner in the middle than at the edges and diverges light.",
      "Convex and concave lenses are the same thing.",
      "Convex and concave lenses are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A 50 g ball is thrown horizontally with a speed of 20 m/s. How far will the ball travel before hitting the ground?",
    options: ["40 m", "80 m", "100 m", "160 m", "200 m"],
  },
  {
    question:
      "A 1 kg object is hanging from a spring with a spring constant of 100 N/m. What is the magnitude of the gravitational force acting on the object?",
    options: ["1 N", "5 N", "10 N", "50 N", "100 N"],
  },
  {
    question:
      "Two point charges, q1 and q2, are separated by a distance r. If the distance between them is doubled, what happens to the electric force between them?",
    options: [
      "It decreases to one-fourth its original value",
      "It decreases to one-half its original value",
      "It stays the same",
      "It doubles",
      "It quadruples",
    ],
  },
  {
    question:
      "A 0.5 kg mass is attached to a spring with a spring constant of 20 N/m. What is the period of the motion of the mass-spring system?",
    options: ["0.08 s", "0.16 s", "0.32 s", "0.64 s", "1.28 s"],
  },
  {
    question: "What is Newton's First Law of Motion?",
    options: [
      "An object at rest will remain at rest, and an object in motion will remain in motion at a constant velocity, unless acted upon by a net external force.",
      "The net force acting on an object is equal to the product of its mass and acceleration.",
      "For every action, there is an equal and opposite reaction.",
      "None of the above.",
      "All of the above.",
    ],
  },
  {
    question:
      "A 20 Ω resistor is connected in series with a 40 Ω resistor and a 60 Ω resistor to a 24 V battery. What is the power dissipated by the 20 Ω resistor?",
    options: ["0.5 W", "1 W", "2 W", "3 W", "4 W"],
  },
  {
    question:
      "A 2 kg object is pushed with a force of 10 N. If the object is initially at rest, what is the speed of the object after 5 seconds?",
    options: ["1 m/s", "2 m/s", "3 m/s", "4 m/s", "5 m/s"],
  },
  {
    question:
      "A circuit consists of a 12 V battery and a 6 Ω resistor. What is the current through the circuit?",
    options: ["0.5 A", "1 A", "2 A", "3 A", "4 A"],
  },
  {
    question:
      "What is the difference between a closed system and an open system?",
    options: [
      "A closed system does not exchange matter with its surroundings, while an open system can exchange matter with its surroundings.",
      "An open system does not exchange matter with its surroundings, while a closed system can exchange matter with its surroundings.",
      "Closed and open systems are the same thing.",
      "Closed and open systems are unrelated.",
      "None of the above.",
    ],
  },
];
export const ApPhysics1and22 = [
  {
    question: "What is Newton's second law of motion?",
    options: [
      "An object at rest tends to stay at rest, and an object in motion tends to stay in motion with a constant velocity, unless acted upon by a net external force.",
      "The acceleration of an object is directly proportional to the net force acting on it and inversely proportional to its mass.",
      "For every action, there is an equal and opposite reaction.",
      "The force of gravity between two objects is directly proportional to the product of their masses and inversely proportional to the square of the distance between them.",
      "None of the above.",
    ],
  },
  {
    question: "What is the difference between a scalar and a vector quantity?",
    options: [
      "A scalar quantity has only magnitude, while a vector quantity has both magnitude and direction.",
      "A scalar quantity has only direction, while a vector quantity has both magnitude and direction.",
      "A scalar quantity has both magnitude and direction, while a vector quantity has only magnitude.",
      "A scalar quantity and a vector quantity are the same thing.",
      "None of the above.",
    ],
  },
  {
    question:
      "A 1 kg object is thrown straight upward with an initial velocity of 20 m/s. What is the maximum height reached by the object?",
    options: ["20 m", "40 m", "80 m", "100 m", "200 m"],
  },
  {
    question:
      "A 1000 kg car traveling at a speed of 20 m/s collides with a stationary 2000 kg car. If the two cars stick together after the collision, what is the final velocity of the cars?",
    options: ["5 m/s", "6.7 m/s", "10 m/s", "15 m/s", "20 m/s"],
  },
  {
    question:
      "What is the difference between a scalar quantity and a vector quantity?",
    options: [
      "A scalar quantity is a physical quantity that has only magnitude, while a vector quantity is a physical quantity that has both magnitude and direction.",
      "A vector quantity is a physical quantity that has only magnitude, while a scalar quantity is a physical quantity that has both magnitude and direction.",
      "Scalar and vector quantities are the same thing.",
      "Scalar and vector quantities are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "What is the difference between centripetal and centrifugal force?",
    options: [
      "Centripetal force is the force that keeps an object moving in a circular path, while centrifugal force is a fictitious force that appears to push an object out of its circular path.",
      "Centrifugal force is the force that keeps an object moving in a circular path, while centripetal force is a fictitious force that appears to push an object out of its circular path.",
      "Centripetal and centrifugal forces are the same thing.",
      "Centripetal and centrifugal forces are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A 2 kg object is moving with a velocity of 10 m/s. What is the magnitude of the object's momentum?",
    options: ["2 kg m/s", "5 kg m/s", "10 kg m/s", "20 kg m/s", "40 kg m/s"],
  },
  {
    question:
      "A 1000 W motor is used to lift a 500 N object vertically at a constant speed. What is the power output of the motor?",
    options: ["500 W", "1000 W", "2000 W", "2500 W", "5000 W"],
  },
  {
    question:
      "A 2 kg block slides down a frictionless plane inclined at an angle of 30 degrees. What is the acceleration of the block?",
    options: ["1 m/s^2", "2 m/s^2", "3 m/s^2", "4 m/s^2", "5 m/s^2"],
  },
  {
    question:
      "A 0.5 kg mass is attached to a spring with a spring constant of 10 N/m and is undergoing simple harmonic motion. What is the maximum speed of the mass?",
    options: ["0.5 m/s", "1 m/s", "2 m/s", "4 m/s", "8 m/s"],
  },
  {
    question:
      "What is the difference between kinetic energy and potential energy?",
    options: [
      "Kinetic energy is the energy of motion, while potential energy is the energy of position.",
      "Potential energy is the energy of motion, while kinetic energy is the energy of position.",
      "Kinetic energy and potential energy are the same thing.",
      "Kinetic energy and potential energy are unrelated.",
      "None of the above.",
    ],
  },
  {
    question: "What is the difference between static and kinetic friction?",
    options: [
      "Static friction is the force that opposes the motion of an object that is not moving, while kinetic friction is the force that opposes the motion of an object that is already moving.",
      "Static friction is the force that opposes the motion of an object that is already moving, while kinetic friction is the force that opposes the motion of an object that is not moving.",
      "Static and kinetic friction are the same thing.",
      "Static and kinetic friction are unrelated.",
      "None of the above.",
    ],
  },
  {
    question: "What is the difference between scalar and vector quantities?",
    options: [
      "Scalar quantities have only magnitude, while vector quantities have both magnitude and direction.",
      "Vector quantities have only magnitude, while scalar quantities have both magnitude and direction.",
      "Scalar and vector quantities are the same thing.",
      "Scalar and vector quantities are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A block of mass 2 kg is pushed with a force of 10 N against a wall. The coefficient of static friction between the block and the wall is 0.5. What is the maximum force that can be applied horizontally to the block without it moving?",
    options: ["5 N", "10 N", "15 N", "20 N", "25 N"],
  },
  {
    question:
      "A car of mass 1200 kg traveling at 25 m/s collides with a stationary truck of mass 2400 kg. After the collision, the car and truck stick together and move with a velocity of 10 m/s. What is the change in kinetic energy during the collision?",
    options: ["300 kJ", "600 kJ", "1200 kJ", "2400 kJ", "3600 kJ"],
  },
  {
    question:
      "A 10 N force is applied to a 2 kg object at an angle of 30° above the horizontal. What is the horizontal component of the force?",
    options: ["5 N", "7.5 N", "8.7 N", "9.8 N", "10 N"],
  },
  {
    question:
      "A 2 kg object is attached to a string and is swung in a vertical circle of radius 2 m at a constant speed of 5 m/s. What is the tension in the string when the object is at the top of the circle?",
    options: ["10 N", "12 N", "14 N", "16 N", "20 N"],
  },
  {
    question: "What is the difference between open and closed systems?",
    options: [
      "In an open system, matter and energy can be exchanged with the surroundings, while in a closed system, matter and energy cannot be exchanged with the surroundings.",
      "In a closed system, matter and energy can be exchanged with the surroundings, while in an open system, matter and energy cannot be exchanged with the surroundings.",
      "Open and closed systems are the same thing.",
      "Open and closed systems are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A 500 g cart moves on a level, frictionless track with a velocity of 10 m/s. What is the kinetic energy of the cart?",
    options: ["25 J", "50 J", "100 J", "125 J", "250 J"],
  },
  {
    question: "What is the difference between weight and mass?",
    options: [
      "Weight is the force exerted on an object due to gravity, while mass is the amount of matter in an object.",
      "Mass is the force exerted on an object due to gravity, while weight is the amount of matter in an object.",
      "Weight and mass are the same thing.",
      "Weight and mass are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A 1 kg object is thrown horizontally from a height of 10 m with an initial velocity of 20 m/s. What is the time it takes for the object to hit the ground?",
    options: ["1 s", "2 s", "3 s", "4 s", "5 s"],
  },
  {
    question: "What is the difference between acceleration and velocity?",
    options: [
      "Acceleration is the rate at which an object's velocity changes, while velocity is the object's speed and direction of motion.",
      "Velocity is the rate at which an object's acceleration changes, while acceleration is the object's speed and direction of motion.",
      "Acceleration and velocity are the same thing.",
      "Acceleration and velocity are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A 100 Ω resistor and a 200 Ω resistor are connected in parallel to a 6 V battery. What is the total current through the circuit?",
    options: ["10 mA", "15 mA", "20 mA", "25 mA", "30 mA"],
  },
  {
    question:
      "A 2 kg object is attached to a spring with a spring constant of 100 N/m and is undergoing simple harmonic motion. What is the amplitude of the motion?",
    options: ["0.02 m", "0.04 m", "0.2 m", "0.4 m", "2 m"],
  },
  {
    question: "What is the difference between thermal energy and temperature?",
    options: [
      "Thermal energy is the total kinetic and potential energy of all the particles in a substance, while temperature is a measure of the average kinetic energy of the particles.",
      "Temperature is the total kinetic and potential energy of all the particles in a substance, while thermal energy is a measure of the average kinetic energy of the particles.",
      "Thermal energy and temperature are the same thing.",
      "Thermal energy and temperature are unrelated.",
      "None of the above.",
    ],
  },
  {
    question: "What is the difference between a force and a torque?",
    options: [
      "A force is a push or pull on an object, while a torque is a force that causes rotation.",
      "A torque is a push or pull on an object, while a force is a force that causes rotation.",
      "Force and torque are the same thing.",
      "Force and torque are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A spring with a spring constant of 500 N/m is compressed by 0.2 m. What is the potential energy stored in the spring?",
    options: ["2 J", "5 J", "10 J", "20 J", "25 J"],
  },
  {
    question:
      "What is the difference between static friction and kinetic friction?",
    options: [
      "Static friction is the force that resists the initiation of motion, while kinetic friction is the force that resists motion that is already occurring.",
      "Kinetic friction is the force that resists the initiation of motion, while static friction is the force that resists motion that is already occurring.",
      "Static friction and kinetic friction are the same thing.",
      "Static friction and kinetic friction are unrelated.",
      "None of the above.",
    ],
  },
  {
    question:
      "A ball is thrown horizontally from the top of a 10 m cliff with an initial speed of 5 m/s. What is the time it takes for the ball to hit the ground?",
    options: ["1 s", "1.5 s", "2 s", "2.5 s", "3 s"],
  },
  {
    question:
      "What is the difference between potential energy and kinetic energy?",
    options: [
      "Potential energy is energy that an object possesses due to its position, shape, or condition, while kinetic energy is energy that an object possesses due to its motion.",
      "Potential energy is energy that an object possesses due to its motion, while kinetic energy is energy that an object possesses due to its position, shape, or condition.",
      "Potential and kinetic energy are the same thing.",
      "Potential and kinetic energy are unrelated.",
      "None of the above.",
    ],
  },
];
