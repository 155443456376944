export const apchem2 = [
  {
    question: "What is the chemical formula for water?",
    options: ["H2O", "C6H12O6", "CO2", "NaCl"],
  },
  {
    question:
      "What type of bond involves the sharing of electrons between two atoms?",
    options: [
      "Ionic bond",
      "Covalent bond",
      "Hydrogen bond",
      "Polar covalent bond",
    ],
  },
  {
    question: "What is the mole?",
    options: [
      "A unit of volume used in the metric system",
      "A unit of mass used in the metric system",
      "A unit of amount used in the metric system",
      "A unit of time used in the metric system",
    ],
  },
  {
    question: "What is the energy released during a chemical reaction called?",
    options: ["Enthalpy", "Gibbs free energy", "Entropy", "Heat energy"],
  },
  {
    question:
      "What is the process by which a solid is converted into a gas called?",
    options: ["Condensation", "Melting", "Sublimation", "Freezing"],
  },
  {
    question: "What is the energy required to break bonds called?",
    options: ["Enthalpy", "Bond energy", "Activation energy", "Free energy"],
  },
  {
    question:
      "What is the process by which a gas is converted into a liquid called?",
    options: ["Condensation", "Melting", "Sublimation", "Freezing"],
  },
  {
    question:
      "What type of reaction results in the formation of a precipitate?",
    options: [
      "Acid-base reaction",
      "Oxidation-reduction reaction",
      "Precipitation reaction",
      "Gas-forming reaction",
    ],
  },
  {
    question: "What is the chemical formula for carbon dioxide?",
    options: ["H2O", "C6H12O6", "CO2", "NaCl"],
  },
  {
    question:
      "What type of reaction involves the transfer of electrons from one reactant to another?",
    options: [
      "Acid-base reaction",
      "Oxidation-reduction reaction",
      "Precipitation reaction",
      "Gas-forming reaction",
    ],
  },
  {
    question: "Which type of bond is the strongest bond between two elements?",
    options: ["Ionic bond", "Covalent bond", "Hydrogen bond", "Metallic bond"],
  },
  {
    question:
      "What is the process of reducing the number of moles of a gas at constant pressure called?",
    options: [
      "Compression",
      "Expansion",
      "Adiabatic compression",
      "Isothermal compression",
    ],
  },
  {
    question:
      "What is the relationship between the enthalpy of a reaction and its spontaneity?",
    options: [
      "The enthalpy of a reaction has no effect on its spontaneity",
      "The more negative the enthalpy of a reaction, the more spontaneous it is",
      "The more positive the enthalpy of a reaction, the more spontaneous it is",
      "The enthalpy of a reaction has a direct relationship with its spontaneity",
    ],
  },
  {
    question:
      "What type of bond is formed when two atoms share electrons equally?",
    options: [
      "Ionic bond",
      "Nonpolar covalent bond",
      "Polar covalent bond",
      "Metallic bond",
    ],
  },
  {
    question: "What is the formula for the reaction rate?",
    options: ["Δ[A]/Δt", "ΔH/Δt", "ΔP/ΔV", "ΔG/Δt"],
  },
  {
    question:
      "What is the relationship between the entropy of a system and the spontaneity of a reaction?",
    options: [
      "The entropy of a system has no effect on the spontaneity of a reaction",
      "The more negative the entropy of a system, the more spontaneous the reaction",
      "The more positive the entropy of a system, the more spontaneous the reaction",
      "The entropy of a system has a direct relationship with the spontaneity of a reaction",
    ],
  },
  {
    question:
      "What type of reaction involves the exchange of ions between reactants to form new compounds?",
    options: [
      "Acid-base reaction",
      "Oxidation-reduction reaction",
      "Precipitation reaction",
      "Gas-forming reaction",
    ],
  },
  {
    question: "What is the formula for the ideal gas law?",
    options: ["PV = nRT", "E = mc^2", "F = ma", "Ohm's Law"],
  },
  {
    question: "What is the mole fraction?",
    options: [
      "The ratio of the number of moles of a component to the total number of moles of all components in a solution",
      "The ratio of the number of grams of a component to the total number of grams of all components in a solution",
      "The ratio of the number of moles of solute to the number of moles of solvent in a solution",
      "The ratio of the number of grams of solute to the number of grams of solvent in a solution",
    ],
  },
  {
    question: "What is the definition of an electrolyte?",
    options: [
      "A substance that conducts electricity only when it is in the molten state",
      "A substance that conducts electricity only when it is in the gaseous state",
      "A substance that does not conduct electricity in any state",
      "A substance that conducts electricity when it is in an aqueous or molten state",
    ],
  },
  {
    question: "What is the formula for the oxidation number of an element?",
    options: [
      "ΔG = ΔH - TΔS",
      "Oxidation number = charge on the ion - total number of electrons",
      "E = hf",
      "ΔG = ΔH + TΔS",
    ],
  },
  {
    question: "What is the unit of rate constant in the rate law equation?",
    options: [
      "Moles per liter per second",
      "Meters per second",
      "Moles per liter",
      "Per second",
    ],
  },
  {
    question: "What type of reaction is the reverse of an exothermic reaction?",
    options: [
      "Endothermic reaction",
      "Exothermic reaction",
      "Redox reaction",
      "Acid-base reaction",
    ],
  },
  {
    question:
      "What type of reaction is characterized by the release of energy in the form of light or heat?",
    options: [
      "Exothermic reaction",
      "Endothermic reaction",
      "Redox reaction",
      "Acid-base reaction",
    ],
  },
  {
    question:
      "What is the product of the reaction between a strong acid and a strong base?",
    options: [
      "Salt and water",
      "An acid and a base",
      "An ester and a salt",
      "An alcohol and a carboxylic acid",
    ],
  },
  {
    question:
      "What type of reaction is characterized by the transfer of a proton from one reactant to another?",
    options: [
      "Acid-base reaction",
      "Oxidation-reduction reaction",
      "Precipitation reaction",
      "Gas-forming reaction",
    ],
  },
  {
    question: "What is the oxidation state of chlorine in HClO4?",
    options: ["+1", "+5", "+7", "+3"],
  },
  {
    question:
      "What is the balanced chemical equation for the reaction between acetic acid and isopropyl alcohol?",
    options: [
      "C2H4O2 + C3H7OH → C6H14O2",
      "C2H4O2 + C3H7OH → C5H12O2 + H2O",
      "C2H4O2 + C3H7OH → C3H8O2 + H2O",
      "C2H4O2 + C3H7OH → C3H6O2 + H2O2",
    ],
  },
  {
    question: "What is the oxidation state of sulfur in H2SO4?",
    options: ["+2", "+4", "+6", "+4"],
  },
  {
    question:
      "What is the mole ratio of nitric acid to sulfuric acid in the reaction between sulfuric acid and nitric acid?",
    options: ["1:1", "2:1", "1:2", "3:1"],
  },
];
